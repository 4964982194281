




// !======================= USER FEED PAGE START CSS ====================
//
//?-------------------------- HERO SECTION START -----------------
.user-search-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-feed-page {
      padding: 13.4rem 0 0;
      width: 100%;
      background-color: $white;

     
  }
  .filter-trend {
      border: 0.1rem solid $primary;
      color: $black-242;
      border-radius: 0.8rem;
      font-size: 1.4rem;
      line-height: 2rem;
  }
  //?-------------------------- HERO SECTION END  --------------------
  //
  //
  // ?---------------------------- CURATED CONTENT CSS START ----------------------
  .curated-content-wrapper {
      .curated-sub-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
              text-decoration: underline;
              cursor: pointer;
          }

      }
      .courated-slider-srapper {
          padding: 3.2rem 0;
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 2.5rem;
          .curated-card {
              position: relative;
              width: 29vw;
              max-width: 37rem;
              img {
                  width: 100%;
              }
              .caourated-hover-text {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 1;
                  padding: 1.4rem 2.3rem;
                  border-radius: 8px;
                  opacity: 0;
                  background: $black-5;
                  p {
                      display: -webkit-box;
                      -webkit-line-clamp: 6;
                      /* Number of lines to display */
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                  }
              }
              &:hover {
                  cursor: pointer;
                  .caourated-hover-text {
                      opacity: 1;
                      transition: all 0.6s;
                  }
              }

          }
      }
  }
  // ?---------------------------- CURATED CONTENT CSS END ----------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // !======================= USER FEED PAGE START END ====================
  //
  //



@media (max-width:1100px) {
     //
    // !======================= USER FEED PAGE START CSS ====================
    //
    //?-------------------------- HERO SECTION START -----------------
    .user-feed-page {
        padding: 13.4rem 0 0;
        .user-search-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    //?-------------------------- HERO SECTION END  --------------------
    //
    //
     // !======================= USER FEED PAGE START CSS ====================

}

@media (max-width:820px) {

    //
    // !======================= USER FEED PAGE START CSS ====================
    //

    //?-------------------------- HERO SECTION START -----------------
 

    .user-feed-page {
        padding: 9.4rem 0 0;
        .user-search-wrapper {}
       
    }
    .filter-trend {
        border: none;
        color: $primary;
        font-size:1.4rem;
        line-height: 1.6rem;
    }
    //?-------------------------- HERO SECTION END  --------------------

    //    ? ------------------------ CURATED CONTENT SECTION START ----------------------
    .curated-content-wrapper {
        .courated-slider-srapper {
            padding: 3.2rem 0;
            gap: 2rem;
            overflow-x: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                appearance: none;
                display: none;
            }
            .curated-card {
                min-width: 15rem;

                img {
                    width: 100%;
                }
                .caourated-hover-text {
                    padding: 0.5rem;
                    p {
                        font-size: 1.2rem;
                        line-height: 1.9rem;
                    }
                }

            }
        }
    }

    //    ? ------------------------ CURATED CONTENT SECTION END  ----------------------
    //

    //
    //
    //
    //
    // !======================= USER FEED PAGE START END ====================
    //
    //
    //
    //
}