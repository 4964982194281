.contact-us-section {
    background-color: $white;
    padding-top: 6.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left-wrapper {
    width: 50%;
    display: flex;

    justify-content: end;
}
.contact-us-wrapper {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;

    .contact-form-frame {
        width: 100%;
        max-width: 60rem;
        padding: 8.5rem 0;
        p {
            margin: 4rem 0 4.8rem;
        }
        .contact-submit-btn {
            margin-top: 2.4rem;

        }

    }
    .contact-info-frame {
        width: 50%;
        // max-width: 60rem;
        background-color: $primary-01;
        .conteact-info-wrapper {
            display: flex;
            max-width: 59rem;
            flex-direction: column;
            gap: 2rem;
            padding: 10.9rem 1rem 0 10rem;
            list-style: none;
            li{
                display: flex;
                align-items: start;
                gap: 1.2rem;
                img{
                    display: block;
                }
            }
        }
    }
}

.contact-input-frame {
    border-radius: 8px;
    border: 1px solid $black-242-05;
    background: $white;
    width: 100%;
    // height: 3.8rem;
    max-width: 37rem;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 1.2rem 0 0.2rem;
    margin-bottom: 2rem;
    .input-box {
        border: none;
        outline: none;
        width: 100%;
        max-width: 100%;
    }
}
.contact-text-area {
    border-radius: 8px;
    border: 1px solid $black-242-05;
    background: $white;
    width: 100%;
    max-width: 37rem;
    padding: 1.2rem 0.8rem;
    outline: none;
}
@media (max-width:820px) {
    .contact-us-wrapper {
        flex-wrap: wrap;
        .left-wrapper {
            width: 100%;
            padding: 0 5%;
            .contact-form-frame {
                padding-top: 1rem;
                p {
                    margin: 1rem 0 5rem;
                    padding: 0;
                }
            }
        }
        .contact-info-frame {
            width: 100%;
            padding: 3.4rem 5%;
            .conteact-info-wrapper {
                padding: 0;
                li{
                    display: flex;
                    align-items: start;
                    gap: 1.2rem;
                    img{
                        display: block;
                    }
                }
            }
        }
    }
}
