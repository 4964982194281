.about-hero-section {
    background: linear-gradient(to right, $white 60%, $blue-DED 40%);
    max-height: 77.4rem;
    height: 100vh;
}

.mw-570 {
    max-width: 57rem;
}

.about-hero-frame {
    padding: 9rem 0 7rem 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .about-contetn {
        width: 50%;

        .about-below-line {
            width: 3.6rem;
            height: 1px;
            background-color: $primary;
            margin-top: 1.6rem;
        }

        h6 {
            margin: 3rem 0;
        }

    }

    .about-image {
        width: 50%;
        display: flex;
        align-items: center;
        padding-left: 7%;

        img {
            width: 100%;
            max-width: 47rem;
            object-fit: auto;
        }
    }

}


.about-story-section {
    padding: 8rem 0;
    background-color: $grey-11;
}

.about-over-story {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
        &:first-child {
            max-width: 30%;
        }

        &:last-child {
            max-width: 70%;
        }
    }
}

.about-over-purpost {
    margin-top: 8rem;
}


.about-achieve-section {
    padding: 8rem 0;
}


.founder-frame {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0;

    .founder-image {
        width: 40%;

        img {
            width: 100%;
            position: relative;
            z-index: -1;
            max-width: 40rem;
        }
    }

    .founder-text {
        width: 60%;
        padding-left: clamp(2rem, 7%, 6rem);

        .founder-name-head {
            // position: relative;
            z-index: 2;
            padding: 2rem 2rem 2rem 0;

            &::before {
                content: "";
                position: absolute;
                right: 0;
                z-index: -1;
                top: clamp(10px, 8vw, 117px);
                width: 67%;
                height: 114px;
                opacity: .5;
                background-color: $green-A5F;
                padding: 1.5rem;
            }
        }

        .description {
            margin: 3rem 0;
        }

        .lets-connect {
            display: flex;
            align-items: center;
            gap: 1rem;

            .get-in-touch {
                border-left: 1px solid $grey-151;
                padding: 0 1rem;
            }
        }
    }
}


.image-gallery {
    display: flex;
    width: 100%;
    height: 52rem;
    padding: 4rem 0;
    gap: 1rem;

    .image-container {
        background-size: cover;
        background-position: center;
        flex-grow: 1;
        border-radius: .8rem;
        overflow: hidden;
        transition: flex-grow 0.3s ease;
        position: relative;
        .bottom-text{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1rem;
            display: none;
            transition: all .6s;
        }

        &:hover {
            flex-grow: 2;
        }
    }

    .active {
        flex-grow:2 ;
        .bottom-text{
            display: block;
            transition: all .6s;
        }
    }

    .image-container:not(.active) {
        flex-grow: 1;
    }
}
.about-contactus-section{
    padding: 4rem 5%;
    background-color: $green-A5F-5;
}
@media (max-width:820px) {
    .mb-wrap {
        flex-wrap: wrap;
    }

    .about-hero-section {
        background: linear-gradient(to right, $white 100%, $blue-DED 0%);
        max-height: 100%;
        height: auto;
    }

    .about-hero-frame {
        padding: 0;
        height: auto;
        flex-wrap: wrap-reverse;
        align-items: start;

        .about-contetn {
            width: 100%;
            padding: 2rem 5% 1rem 5%;

            .about-below-line {
                width: 3.6rem;
                height: 1px;
                background-color: $primary;
                margin-top: 1.6rem;
            }

            h6 {
                margin: 1.6rem 0;
                word-spacing: .8rem;
            }

        }

        .about-image {
            width: 100%;
            padding-left: 0%;

            .desk-image {
                display: none;
            }

            img {
                width: 100%;
                max-width: 100%;
                object-fit: cover;
            }
        }

    }

    .about-story-section {
        padding: 4rem 0;
    }

    .about-over-story {
        flex-wrap: wrap;

        p {
            &:first-child {
                max-width: 100%;
                margin-bottom: 2rem;
            }

            &:last-child {
                max-width: 100%;
            }
        }
    }

    .about-over-purpost {
        margin-top: 4rem;
    }

    .about-achieve-section {
        padding: 4rem 0;
    }

    .founder-frame {
        flex-wrap: wrap;
        padding: 3rem 0 0 0;

        .founder-image {
            width: 100%;

            img {
                width: 100%;
                max-width: 40rem;
            }
        }

        .founder-text {
            width: 100%;
            padding-left: 0;
            padding-top: 3rem;

            .founder-name-head {
                // position: relative;
                z-index: 2;
                padding: 2rem;
                background-color: $green-A5F;


                &::before {
                    display: none;
                }
            }

            .description {
                margin: 2rem 0;
            }

            .lets-connect {
                display: flex;
                align-items: center;
                gap: 1rem;

                .get-in-touch {
                    border-left: 1px solid $grey-151;
                    padding: 0 1rem;
                }
            }
        }
    }


    .image-gallery {
        display: flex;
        width: 100%;
        padding: 2rem 5% 5rem 5%;
        overflow: auto;
        gap: 1rem;
        height: auto;
    
        .image-container {
           
            min-width: 24.8rem;
            height: 31.5rem;
            .bottom-text{
                display: block;
            }
            &:hover {
                flex-grow: 1;
            }
        }
    
        .active {
            flex-grow:1 ;
        }
    
        .image-container:not(.active) {
            flex-grow: 1;
        }
    }
    

}