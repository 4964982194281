// ----------------------- canvas main component ---------------------- //

.cobrand-canvas-container {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .canvas-tooltip {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: fit-content;
    display: flex;
    gap: 1.5rem;

    img {
      cursor: pointer;
    }
  }

  .main-container {
    flex: 1;
    margin: 0rem 2rem 10rem 10rem;

    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      margin: 0 1rem 10rem 1rem;
    }
    .canvas-dimensions {
      display: flex;
      padding: 1rem;
      background-color: white;
      gap: 1rem;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      font-size: 1.5rem;
      border-radius: 0.5rem;
      color: rgba(0, 0, 0, 0.702);
      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
      img{
        width: 1rem;
        height: 1rem;
      }
    }
    .canvas-zoom {
      display: flex;
      padding: 1rem;
      background-color: white;
      gap: 1rem;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      font-size: 1.5rem;
      border-radius: 0.5rem;
      color: rgba(0, 0, 0, 0.702);
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        img{
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      img {
        cursor: pointer;
      }
    }
    .playground-wrapper {
      position: relative;
      flex: 1;
      .playground {
        overflow: scroll;
        height: 100%;
        border-radius: 0.5rem;
        padding: 2rem;
        background-color: $grey-D9D;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media screen and (max-width: 768px) {
          justify-content: flex-start;
          padding: 1rem
        }
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        .canvas-main {
          &:hover {
            #canvas {
              border-width: 1.5px;
            }
          }
        }
      }
      .canvas-sidebar {
        position: absolute;
        top: 1rem;
        left: -5.5rem;
        padding: 1rem;
        background-color: white;

        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        border-radius: 0.5rem;
        gap: 1.5rem;
        @media screen and (max-width: 768px) {
          display: none;
        }
        img {
          cursor: pointer;
        }
      }
      .canvas-bottombar {
        position: absolute;
        bottom: -2rem;
        width: 100%;
        overflow-x: auto;
        overflow-y: visible !important;
        border-radius: 0.5rem;
        gap: 1.5rem;
        &::-webkit-scrollbar {
          width: 0;
          height: 0.2rem;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #7baef6;
          border-radius: 1rem;
        }
        .canvas-sidebar {
          all: unset;
          flex-direction: row;
          display: none;
          gap: 0.8rem;
          @media screen and (max-width: 768px) {
            display: flex;
          }
        }

        img {
          cursor: pointer;
        }
        p {
          padding: 0.5rem 0;
          border-radius: 1rem;
          &.active {
            background-color: #7baef659;
            border: 1px solid #7baef6;
          }
        }
        .main {
          background-color: white;
          width: fit-content;
          margin: auto;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
        #dropdown-basic {
          background-color: transparent;
          color: black;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 1rem;
          min-width: 14rem;
          text-align: left;
          height: 3.5rem;
          font-size: 1.6rem;
          &::after {
            display: none;
          }
          &:focus,
          &:hover {
            outline: none;
            box-shadow: none;
          }
        }
        .font-size-main {
          position: relative;
          #font-size-input {
            height: 3.5rem;
            width: 9rem;
            padding: 0 3rem;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 1rem;
            font-size: 1.6rem;
            -moz-appearance: textfield;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            &:focus,
            &:hover {
              outline: none;
            }
          }
          .before {
            position: absolute;
            top: 30%;
            left: 6px;
            cursor: pointer;
          }
          .after {
            position: absolute;
            top: 30%;
            right: 6px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
#canvas {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.377);
}

.show-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.show-desktop {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.layers-open {
  display: flex;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  gap: 1rem;
  margin-left: 2rem;
  img {
    cursor: pointer;
  }
}
.circle-picker {
  span {
    div {
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      margin-right: 0.7rem !important;
      margin-bottom: 0.7rem !important;
    }
  }
}
.chrome-picker {
  box-shadow: none !important;
}
// ----------------------- canvas main component ---------------------- //

// ----------------------- video co-brand component ---------------------- //
.video-cobrand-container {
  display: flex;
  background-color: #ececec;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .sidebar {
    background-color: $grey-D9D;
    flex-shrink: 0;
    overflow: auto;
    border-radius: 0.4rem;
    margin: 1.2rem;
    padding: 1rem;
    max-width: 17rem;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
    .slides {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      @media screen and (max-width: 768px) {
        flex-direction: row;
        gap: 0.5rem;
        margin: 0;
      }
      .sidebar-item {
        padding: 1rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 768px) {
          margin: 0;
        }
        .thumbnail {
          width: 12rem;
          height: 6.8rem;
          border: 1px solid #a9a9a9;
          background-color: white;
          cursor: pointer;
          &:hover {
            border: 1px solid #007bff;
          }
          &.active {
            border: 2px solid #270089;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .duration {
          background-color: $grey-DDD;
          padding: 0.4rem 0.8rem;
          border-radius: 1rem;
        }
      }
    }
  }
  .right-container {
    flex: 1;
  }
}
.placeholder{
  background: url("../../images/canvas/placeholder.png") no-repeat center center;
  background-size:100% 100%;
}