.amc-details-heading-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    .amc-details-profile-image {
        display: flex;
        align-items: center;
        justify-content: start;
        .amc-detail-profile {
            width: 7rem;
            height: 7rem;
            margin-right: 1.6rem;
            .profile-Image {
                width: 7rem;
                height: 7rem;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
}
.download-share-icon {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.2rem;
    .primary-btn{
        border-radius: .8rem;
    }
    .icon-fram {
        padding: 0.6rem 1.6rem;
        border-radius: .8rem;
        border: 1px solid $grey-757;
        cursor: pointer;
        .share-icon{
            width: 2rem;
        }
    }
}
.content-type-data{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: .8rem;
    width: 100%;
    margin: 0.5rem 0 0rem 0;
}
.virtical-strip{
    width: .15rem;
    height: 2.4rem;
    background-color: $grey-ECE;
}
.new-amc-details{
    margin: 2rem 0;
    border-radius: 2rem ;
    overflow: hidden;
}


@media (max-width:820px) {
    .content-type-data{
        margin-bottom: 1.6rem;
        flex-wrap: wrap;
    }
}