.language-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 3rem;
    padding-bottom: 4rem;

}
.language-box {
    border-radius: 1.2rem;
    opacity: 0.5;
    background: $grey-2;
    width: 27rem;
    height: 27rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid black;
}
.based-layout {
    margin: 44px 0 24px;
}
.amc-box-wrapper {
    width: 370px;
    border-radius: 12px;
    border: 1px solid $grey-ECE;
    background: $white;
    .amc-img-box {
        width: 100%;
        height: 17rem;
    }
    .amc-content-wrapper{
        margin: 1rem 1.6rem 0 1.6rem;
    }
    .amc-count-frame{
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            padding:1.2rem 1.8rem;
            margin: 0rem 1.6rem;
        }
    }

}
@media (max-width:820px) {
    .language-box-wrapper {
        gap: 2rem;

    }
    .language-box {
        width: 15rem;
        height: 15rem;
    }
}
