

//
// ?------------------------------- FOOTER CSS START --------------------------
.footer-section {
    padding: 3.5rem 0 3.1rem;
    background: $black-242;
    .footer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .footer-box {
            width: 33%;
            a{
              color: inherit;
              &:hover{
                color: inherit;
              }
            }
            .icons-wrapper {
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 0.8rem;
            }
        }

    }
    .copy-right {
        margin-top: 5.3rem;
    }
}
// ?------------------------------- FOOTER CSS END --------------------------
//
//
//



@media (max-width:1100px) {

}

@media (max-width:820px) {
      //
    // ?------------------------------- FOOTER CSS START --------------------------
    .footer-section {
        padding: 3.7rem 0 1.6rem;
        background: $black-242;
        .footer-wrapper {
            gap: 2.9rem;
            .footer-box {
                width: 100%;
            }
        }
        .copy-right {
            margin-top: 3.5rem;
        }
    }
    // ?------------------------------- FOOTER CSS END --------------------------
    //
}