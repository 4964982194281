.save-collection-custom-model {
    .modal-content {
        border-radius: 8px;
        overflow: hidden;
        max-width: 57rem;
        margin: 10% auto auto;
        .save-collection-model-header {
            padding: 1.7rem 6.7rem !important;
            background: $green-5DF !important;
            .modal-title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

        }
        .save-collection-model-body {
            padding: 2.4rem 6.1rem ;
            padding-top: 2.4rem !important;
            padding-bottom: 2.4rem !important;
            .input-box-search-collection {
                padding: 0.75rem 1.1rem;
                border-radius: 0.5rem;
                border: 1px solid $black-4;
                opacity: 0.8;
                outline: none;
            }
         .add-colloectin-btn{
            padding:.3rem 1rem;
            position: absolute;
            right: 1rem;
            top: 50%;
            cursor: pointer;
            transform: translateY(-50%);
            border-radius: .4rem;
            // border: .1rem solid $black-4;
            background-color: $green-A5F;
            box-shadow:0 0 .5rem $grey-ECE;
            color: $black-2;
         }
            .cancel-field {
                right: clamp(1.5rem, 11%, 5rem);
                opacity: 0.4;
            }

            .search-input-field-icon {
                right: clamp(1rem,5%,2rem);
            }
            .saveto-collection-list {
                list-style: none;
                padding: 0 0 1.6rem;
                border-radius: 0.8rem;
                border: 0.1rem solid $grey-919;
                margin-top: 1.2rem;
                max-height: 26.7rem;
                overflow: auto;
                scroll-behavior: smooth;
                li {
                    padding: 1.2rem 2rem;
                    letter-spacing: 0.15px;
                    background-color: $white;
                    border-top: 2px solid $white;
                    border-bottom: 2px solid $white;
                    &:first-child {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: sticky;
                        top: 0;
                        &:hover {
                            background-color: white;
                        }
                        .add-new-collection {
                            text-decoration: underline;
                            letter-spacing: 0.25px;
                            opacity: 0.9;
                        }
                    }
                    &:hover {
                        background-color: $grey-EEE;
                    }
                    &:active {
                        background-color: $green-5DF;
                    }
                }
            }
        }
    }
}
.selected-item{
    background-color: $green-5DF !important;
}
.px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}
.pt-21 {
    padding-top: 2.1rem;
}
.disabled-btn{
    background: $grey-3 !important;
    border: .1rem solid transparent !important;
    cursor:not-allowed !important;
}
// =================== CONFORM POP UP MODEL START ============================

    .save-confirm-collection-custom-model{

        .modal-content{
        width: 100%;
        max-width: 50rem;
        border-radius: 1rem;
        overflow: hidden;
        background-color: $white;
        margin:10% auto auto auto;
       .save-confirm-collection-model-body{
        position: relative;
        padding: 8rem 4.1rem 7.2rem 4.1rem !important;
        h4{
            margin-bottom: 4.2rem !important;
            margin-top: 1rem !important;
        }
        .add-collection-input-box{
            margin-bottom: 4.2rem !important;
            padding: 0.75rem 1.1rem;
            border-radius: 0.5rem;
            border: 1px solid $black-4;
            opacity: 0.8;
            outline: none;


        }
        .close-btn{
            position: absolute;
            right: 1rem;
            top: .8rem;
        }
       }}
    }

// =================== CONFORM POP UP MODEL END ============================
@media (max-width:920px) {
    .save-collection-custom-model {

        .modal-content {
            margin : 0 0 0 !important;
            h4{
                margin-top: 1rem !important;
            }

            .save-collection-model-header {
                padding: 1.7rem 10% !important;
                background: $green-5DF !important;
            }
        }
    }

    .save-confirm-collection-custom-model{


        .modal-content{
          margin : 0 0 0 !important;
       .save-confirm-collection-model-body{

        .add-collection-input-box{
          margin-top: 3rem;


        }
    }
}
    }

}
