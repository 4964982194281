// INCLUDE FONT FILE SOURCE SERIF FONT
@include font-face(Lato-Regular, "../../fonts/lato/Lato-Regular", 400, normal, ttf);
@include font-face(Lato-Bold, "../../fonts/lato/Lato-Bold", 700, normal, ttf);
@include font-face(Lato-Medium, "../../fonts/lato/Lato-Medium", 500, normal, ttf);
@include font-face(Lato-Black, "../../fonts/lato/Lato-Black", 900, normal, ttf);
@include font-face(Lato-SemiBold, "../../fonts/lato/Lato-Semibold", 600, normal, ttf);
@include font-face(Lato-Light, "../../fonts/lato/Lato-Light", 300, normal, ttf);

// SET THE BASE HTML REM, MAKING 1REM=10PX

html {
  font-size: 10px;
  // background-color: $secondary;
  scroll-behavior: smooth;
}

#root {
  // background-color: $secondary;
}

.container,
.container-mb {
  width: 100% !important;
  max-width: 144rem !important;
  padding: 0 9.8% !important;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

a:focus,
a:hover {
  text-decoration: none;
}

.icon-fixe-width {
  width: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;

  img {
    margin: 0;
  }
}

.pointer {
  cursor: pointer !important;
}

.main-content-wrapper {
  margin-top: 4.5rem;
  padding-top: 3rem;
  min-height: 68vh;
}

//Fonts

.lato-light {
  @include fontWeight("Lato-light", 300, normal);
}

.lato-regular {
  @include fontWeight("Lato-Regular", 400, normal);
}

.lato-medium {
  @include fontWeight("Lato-Medium", 500, normal);
}

.lato-semibold {
  @include fontWeight("Lato-SemiBold", 600, normal);
}

.lato-bold {
  @include fontWeight("Lato-Bold", 700, normal);
}

.lato-black {
  @include fontWeight("Inter-Black", 900, normal);
}

// Font Size
@for $i from 1 through 100 {
  .f-s-#{$i} {
    font-size: $i + px;
  }
}

// Line Height
@for $x from 1 through 100 {
  .lh-#{$x} {
    line-height: $x + px;
  }
}

// Opacity
@for $y from 0 through 10 {
  .op-#{$y} {
    opacity: $y / 10;
  }
}

.br-10 {
  border-radius: 1rem;
}

.br-8 {
  border-radius: 0.8rem;
}

.br-4 {
  border-radius: 0.4rem;
}

.br-20 {
  border-radius: 2rem;
}

.br-50 {
  border-radius: 50%;
}

// =================== COMON CSS START ==================

.primary {
  color: $primary;
}

.blue-0C5 {
  color: $blue-0C5;
}
.grey-1D1 {
  color: $grey-1D1;
}
.black-242 {
  color: $black-242;
}

.black-707 {
  color: $black-707;
}

.black-495 {
  color: $black-495;
}
.black-12 {
  color: $black-12;
}
.black-1D1 {
  color: $black-1D1;
}
.green-5DF {
  color: $green-5DF;
}
.red-D72 {
  color: $red-D72;
}

.bg-ECE {
  background: $grey-ECE;
}

.bg-primary {
  background-color: $primary !important;
}

.grey-151 {
  color: $grey-151;
}
.primary {
  color: $primary;
}

.red {
  color: $red;
}

.grey-919 {
  color: $grey-919;
}

.black-242 {
  color: $black-242;
}

.red-FF3 {
  color: $red-FF3;
}

.black-4 {
  color: $black-4;
}

.black-252 {
  color: $black-252;
}

.mb-inline-block {
  display: none;
}

// ========= FONT SICE RESPONSIVE -----------------------
.fs-48-32 {
  font-size: 4.8rem;
  line-height: 5.2rem;
}

.fs-45-28 {
  font-size: 4.5rem;
  line-height: 5rem;
}

.fs-32-18,
.fs-32-28 {
  font-size: 3.2rem;
  line-height: 4rem;
}

.fs-32-24 {
  font-size: 3.2rem;
  line-height: 4rem;
}
.fs-32-20 {
  font-size: 3.2rem;
  line-height: 4rem;
}


.fs-36-24 {
  font-size: 3.6rem;
  line-height: 4.4rem;
}

.fx-18 {
  font-size: 1.8rem;
}
.fx-20 {
  font-size: 2rem;
}
.fx-22 {
  font-size: 2.2rem;
}

.fs-18-12,
.fs-18-14,
.fs-18-16,
.fs-18-9 {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.fs-57-28,
.fs-57-32,
.ft-57-32 {
  font-size: 5.7rem;
  line-height: 6.4rem;
}

.fs-75-41 {
  font-size: 7.5rem;
  line-height: 8.4rem;
}

.fs-20-16 {
  font-size: 2rem;
  line-height: 2.6rem;
}

.fs-22-13,
.fs-22-14,
.fs-22-16,
.fs-22-18,
.fs-22-12 {
  font-size: 2.2rem;
  line-height: 2.8rem;
}

.fs-24-12 {
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.fs-26-12 {
  font-size: 2.6rem;
  line-height: 2.9rem;
}

.fs-14-11,
.fs-14-13,
.fs-14-9 {
  font-size: 1.4rem;
  line-height: 2rem;
}

.fs-28-18,
.fs-28-24,
.ft-28-18 {
  font-size: 2.8rem;
  line-height: 3.6rem;
}

.fs-14-12,
.fx-14 {
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.fs-14-10 {
  font-size: 1.4rem;
  line-height: 1.9rem;
}
.fs-12-8 {
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.fs-12-9 {
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.fs-24-14,
.fs-24-16,
.fs-24-18,
.fx-24 {
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.fs-16-12,
.fs-16-13,
.fs-16-14,
.fx-16 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.fs-16-11 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.fx-12 {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.mw-151,
.mw-151-1 {
  max-width: 15.1rem !important;
  width: 100%;
}

.premium-image {
  width: 1.4rem;
  margin-right: 0.5rem;
  margin-top: -2px;
}

.mw-200 {
  max-width: 20rem;
  width: 100%;
}

.mw-101 {
  max-width: 10rem;
  width: 100%;
}

.mh-400 {
  min-height: 40rem !important;
  height: 100%;
}

.lh-70 {
  line-height: 7rem !important;
}

// ========= FONT SICE RESPONSIVE -----------------------
.desk-show {
}
.slick-dots {
  bottom: -4.7rem;
}
.cancel-menu,
.mb-show,
.menu-bar,
.tab-show {
  display: none;
}

.mb-flex {
  display: none !important;
}

.search-border {
  border: 0.1rem solid $black-4;
  max-width: 100% !important;
}

.pointer {
  cursor: pointer;
}

.mw-90 {
  width: 9rem;
}

.mw-698 {
  max-width: 69.8rem;
  width: 100%;
}

.pe-0 {
  padding-right: 0 !important;
}

.right-0 {
  right: 0;
}
.gap-30 {
  gap: 3rem;
}

.gap-27 {
  gap: 2.7rem;
}
.gap-10 {
  gap: 1rem;
}
.gap-5 {
  gap: 0.5rem;
}

.modal{
  backdrop-filter: blur(1px);
background-color: #00000070;
}

.folder-clip {
  clip-path: polygon(
    0 0,
    0% 20%,
    0 49%,
    0% 80%,
    0 98%,
    42% 100%,
    80% 100%,
    100% 100%,
    100% 59%,
    100% 19%,
    51% 19%,
    45% 0
  );
}

.pre-text {
  white-space: pre-line;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  /* word-wrap: break-word; */
}

.desk-circle {
  width: 0.8rem;
}

.mw-900 {
  max-width: 90rem;
  width: 100%;
}

.input-box {
  border-radius: 0.8rem;
  border: 1px solid $primary;
  background: $white;
  color: $black-242;
  width: 47%;
  max-width: 27rem;
  padding: 0.6rem 1rem;
  outline: none;

  &::placeholder {
    color: $black-242;
    opacity: 0.5;
  }

  &:disabled {
    // background-color: #e9ecef;
    cursor: not-allowed;
    color: $grey-2;
  }
}

.amc-page-padding {
  padding: 9rem 0 0;
}

.higheLight {
  border: 1px solid $black-242;
  color: $black-242;
}

// .primary-btn {
//   border-radius: 0.8rem;
//   padding: 1rem 2.4rem;
//   color: $white;
//   border-radius: 0.8rem;
//   border: 1.7px solid var(--$white, $white);
//   background: var(--f-inblue, $primary);
//   font-family: Lato;
//   font-size: 1.4rem;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 2rem;
//   text-align: center;
//   outline: none;
//   box-shadow: none;
//   cursor: pointer;
// }
// .secondary-btn {
//   padding: 0.5rem 1.6rem;
//   border-radius: 0.8rem;
//   border: 0.1rem solid $white;
//   background-color: $white;
//   cursor: pointer;
//   outline: none;
//   box-shadow: none;
//   font-size: 1.4rem;
//   line-height: 1.9rem;
//   // @include font($primary, "Inter-Regular", 1.4rem, 500, 2rem);
// }
// COLOR
.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.grey-757 {
  color: $grey-757;
}

.grey-D9D {
  color: $grey-D9D;
}

//Button - BG - COLOR

.bg-green-4CA {
  background-color: $green-4CA;
}

.bg-red-D43 {
  background-color: $red-D43;
}

// MARGIN - PADDING
.mb-10 {
  margin-bottom: 1rem;
}

.h-40 {
  height: 3.92rem !important;
}

.py-10 {
  padding: 7.8px 14px !important;
}

.pb-10 {
  padding-bottom: 1rem;
}

.pb-20 {
  padding-bottom: 2rem;
}

.cursor-notallowed {
  cursor: not-allowed !important;
  pointer-events: none;
}

.disable-btn {
  background: $grey-ECE !important;
  color: $grey-2 !important;
  border-color: $grey-2 !important;
  cursor: not-allowed !important;
}

// COMMON BUTTON
.primary-btn {
  @include font($white, "Lato-Medium", 1.8rem, 500, 24px);
  height: auto;
  border-radius: 4px;
  padding: 1rem 1.4rem;
  background-color: $primary;
  border: 1px solid $primary;
  cursor: pointer;

  &:active,
  &:active:focus,
  &:focus,
  &:hover {
    background-color: $primary;
    border: 1px solid $primary;
    outline: none;
    box-shadow: none;
    color: $white;
  }

  &:not(.disabled),
  &:not(.disabled):active,
  &:not(:disabled) {
    background-color: $primary;
    border: 1px solid $primary;
    cursor: pointer;
  }
  &:disabled {
    background-color: $grey-15;
    border: 0px solid $grey-15;
    cursor: not-allowed;
    color: $grey-919;
  }
}

.mw-102 {
  max-width: 10.2rem;
  width: 100%;
}

.secondary-btn {
  @include font($primary, "Lato-Medium", 1.8rem, 500, 24px);
  height: auto;
  border-radius: 4px;
  padding: 0.7rem 1.4rem;
  background-color: $white;
  border: 1px solid $primary;
  cursor: pointer;

  &:active,
  &:active:focus,
  &:focus,
  &:hover {
    color: $primary !important;
    background-color: $white !important;
    border: 1px solid $primary !important;
    outline: none;
    box-shadow: none !important;
  }

  &:not(.disabled),
  &:not(.disabled):active,
  &:not(:disabled) {
    // color: $primary !important;
    // background-color: $white !important;
    border: 1px solid $primary !important;
    box-shadow: none !important;
    cursor: auto;
  }
}

.ghost-btn {
  @include font($white, "Lato-Medium", 1.8rem, 500, 24px);
  height: auto;
  border-radius: 4px;
  padding: 1rem 1.4rem;
  background-color: transparent;
  border: 1px solid $white;
  cursor: pointer;

  &:active,
  &:active:focus,
  &:focus,
  &:hover {
    background-color: transparent;
    border: 1px solid $white;
    outline: none;
    box-shadow: none;
  }

  &:not(.disabled),
  &:not(.disabled):active,
  &:not(:disabled) {
    background-color: transparent;
    border: 1px solid $white;
    cursor: auto;
  }
}

.scheduled-plan-btn {
  @include font($black, "Lato-Medium", 1.4rem, 500, 24px);
  height: auto;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  background-color: $green-5DF;
  // border: 1px solid $green-5DF !important;
  cursor: pointer;

  &:active,
  &:active:focus,
  &:focus,
  &:hover {
    background-color: $green-5DF;
    // border: 1px solid $green-5DF !important;
    outline: none;
    box-shadow: none;
    color: $black;
  }

  &:not(.disabled),
  &:not(.disabled):active,
  &:not(:disabled) {
    background-color: $green-5DF !important;
    // border: 1px solid $green-5DF !important;
    cursor: pointer;
    color: $black;
  }
  &:disabled {
    // background-color: $grey-15 !important;
    border: 0px solid $grey-15;
    cursor: not-allowed;
    color: $grey-5;
  }
}

.ellipsis-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-image-wrapper {
  label {
    span {
      display: inline-block;
      padding: 0.4rem 1.9rem 1rem;
      border: 1px solid $grey-CCC;
      font-size: 3rem;
      background-color: $white;
      color: $grey-CCC;
      cursor: pointer;
    }
  }
}

.pos-rel {
  position: relative;

  .password-hide {
    top: 0.7rem;
    right: 1.1rem;
  }
}

.pos-abs {
  position: absolute;
}

.disable-item {
  position: relative;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $grey-ECE;
    opacity: 0.5;
    content: "";
    left: 0;
    top: 0;
  }
}

// UPLOADED IMAGE STYLE
.img-preview-wrapper {
  .uploaded-img-wrapper {
    width: 12rem;
    height: 14rem;
    position: relative;
    margin-right: 2rem;
    display: inline-block;

    img {
      object-fit: cover;
      height: inherit;
      width: 100%;
    }

    .option-card-extension {
      font-size: 2.2rem;
      color: $grey-1;
      border: 4px solid $grey-DDD;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
    }

    // .glyphicon {
    //   position: absolute;
    //   top: -7px;
    //   right: -10px;
    //   /* color: #fff; */
    //   /* z-index: 2; */
    //   background-color: #fff;
    //   font-size: 3rem;
    //   border-radius: 50%;
    //   cursor: pointer;
    // }
    .remove-circle {
      position: absolute;
      width: 2rem;
      height: 2rem;
      right: -1rem;
      top: -1rem;
      cursor: pointer;
    }
  }

  .progress {
    width: 12rem;
    margin-top: 1rem;
    height: 1rem;
  }
}

.page-padding {
  padding: 7.4rem 0 0;
  background-color: $white;
}

.page-padding-filter {
  padding: 7.4rem 0 0;
  background-color: $white;
}

// FORM STYLING
form {
  label {
    max-width: 100%;
    margin-bottom: 1rem;
    @include font($grey-757, "Lato-Bold", 1.6rem, bold, 24px);
  }

  .form-group {
    margin-bottom: 3rem;
    position: relative; //For tooltip

    .form-control,
    // .form-control::placeholder,

    .custom-select__control {
      @include font($black, "Lato-Regular", 1.6rem, normal, 24px);
      padding: 7px 12px;
      height: auto;
      border-radius: 8px;

      &.custom-select__control--is-focused {
        box-shadow: none;
        border-color: hsl(0, 0%, 80%);
      }
    }

    .form-control:focus {
      box-shadow: none;
    }

    .is-invalid,
    .is-valid {
      background-image: none;
      border-color: $grey-CED;
    }

    .custom-select__menu {
      color: initial;

      .custom-select__menu-list {
        .custom-select__option {
          @include font($black, "Lato-Regular", 1.6rem, normal, 24px);
        }
      }
    }

    .custom-select__control {
      padding: 0;
      height: 4.2rem;

      .custom-select__value-container {
        .custom-select__placeholder {
          padding: 0;
        }
      }
    }

    .form-text {
      display: block;
      margin-top: 5px;
      margin-bottom: 10px;
      color: #737373;
      font-size: 10px;
    }
    .input-border{
      .custom-select__control {

        border: 1px solid #79747e ;

      }
    }

    .has-error {
      text-align: left;
      @include font($red-FF3, "Lato-Regular", 1.3rem, normal, 18px);


      .custom-select__control {
        // CUSTOM ERROR STYLE ADDED WITH ERROR ICON FOR REACT-SELECT
        border: 1px solid $red-FF3;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 4rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }


      .custom-select__menu {
        color: initial;
      }
    }

    .react-datepicker-wrapper {
      display: block;
    }

    .react-datepicker {
      // font-size: 1em;
      transform: scale(1.5) translate(3.5rem, 2.5rem);

      .react-datepicker__month-select,
      .react-datepicker__year-select {
        option {
          font-size: 1.2rem;
        }
      }
    }
  }

  .change-button {
    @include font($primary, "Lato-Medium", 1.4rem, 500, 21px);
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 4.3rem;

    &.email-change {
      // top: 4.3rem;
      right: 2.5rem;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .date-input-field {
    .is-valid {
      background: none;
    }
  }

  .input-group {
    .input-group-prepend {
      padding: 6px 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      color: #555;
      text-align: center;
      background-color: $grey-EEE;
      border: 1px solid $grey-CCC;
      border-radius: 4px;
      // to make vertical align center
      display: flex;
      align-items: center;
    }

    .input-group-prepend:first-child {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .input-group-prepend:last-child {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .radio-wrapper {
    .radio-control {
      display: flex;
      margin-bottom: 1rem;

      input {
        margin-top: 0.1rem;
      }

      span {
        font-family: "Lato-Regular", Arial, Helvetica, sans-serif;
        font-size: 1.4rem;
        font-weight: normal;
        color: #333333;
        line-height: normal;
        padding-left: 1rem;
      }
    }

    &.inline {
      display: flex;
      flex-wrap: wrap;

      .radio-control {
        margin-right: 2rem;
      }
    }
  }
}

// DATE PICKER CONTROL

.form-control.date-picker-control {
  background-color: $white;
  position: relative;

}

.custom-date-range-picker {

  .react-datepicker-popper[data-placement^="bottom"],
  .react-datepicker-popper[data-placement^="top"] {
    .react-datepicker__triangle {
      left: -34px !important;
    }
  }

  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 88px;
  }
}

.date-icon {
  position: absolute;
  right: 2rem;
  bottom: 0.9rem;
}

.date-picker-control-modal {
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-modal {
  &.date-picker.control-modal {
    .modal-content {
      padding: 0;
    }
  }
}

.table-wrapper {
  .form-group {
    width: 8rem;
    margin-left: 3rem;
    // left: 100%;
    // transform: translateX(-100%);
  }

  .ReactVirtualized__Table__headerRow {
    background-color: $grey-D9D;
    text-transform: none;

    .ReactVirtualized__Table__headerColumn {
      @include font($black, "Inter-SemiBold", 1.4rem, 600, normal);
    }
  }

  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 25px;
  }

  .ReactVirtualized__Table__Grid {
    // z-index: -1;
    .ReactVirtualized__Table__row {
      border-bottom: 1px solid rgba(0, 0, 0, 0.17);
      background: $white;

      .ReactVirtualized__Table__rowColumn {
        @include font($black, "Inter-Regular", 1.4rem, normal, normal);

        .primary-btn {
          padding: 0.5rem 1.2rem;
          font-size: 1.4rem;
        }
      }
    }
  }

  .header-navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pagination {
      margin: 1rem 0;
    }
  }
}

.not-found-wrapper {
  text-align: center;
  padding: 3rem 0;

  .regular {
    font-family: "Lato-Regular", Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    font-weight: normal;
    color: #333333;
    line-height: normal;
    padding: 1rem 0;
  }
}

.commom-table-wrapper {
  padding: 25px 0;
}

.checkbox-control {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0 0 10px;
}

.inline {
  display: inline;
}

.horizantal {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.2rem;
  padding-top: 0.5rem;
}

.curated-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    text-decoration: underline;
    cursor: pointer;
  }
}

// .bg-blue-card {
//     background-color: $primary;
//     color: $white;
//     text-align: center;
//     padding: 0 1.5rem;
// }
// .bg-deep-blue-card {
//     background-color: $black-242;
//     color: $white;
//     text-align: center;
//     padding: 0 1.5rem;
// }
// .bg-green-card {
//     background-color: $green-5DF;
//     color: $primary;
//     text-align: center;
//     padding: 0 1.5rem;
// }
.default-image-iffect,
.social-media-card-frame > .slick-list > .slick-track > .slick-slide,
.target-imag-frame {
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(3n) {
    // background-color: $black-242;
    color: $white;
    text-align: center;
    padding: 0 1.5rem;
  }

  &:nth-child(3n + 1) {
    // background-color: $primary;
    color: $white;
    text-align: center;
    padding: 0 1.5rem;
  }

  &:nth-child(3n + 2) {
    // background-color: $green-5DF;
    color: $primary;
    text-align: center;
    padding: 0 1.5rem;
  }
}
.target-imag-frame-t {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  width: 26.5rem;
  text-align: center;

  &:nth-child(3n) {
    background-color: $black-242;
    color: $white;
    text-align: center;
    padding: 0 1.5rem;
  }

  &:nth-child(3n + 1) {
    background-color: $primary;
    color: $white;
    text-align: center !important;
    padding: 0 1.5rem;
    width: 26.5rem;
  }

  &:nth-child(3n + 2) {
    // background-color: $green-5DF;
    color: $primary;
    text-align: center;
    padding: 0 1.5rem;
  }
}
.target-imag-frame-p {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48rem;

  &:nth-child(3n) {
    background-color: $black-242;
    color: $white;
    text-align: center;
    padding: 0 1.5rem;
  }

  &:nth-child(3n + 1) {
    background-color: $primary;
    color: $white;
    text-align: center;
    padding: 0 1.5rem;
  }

  &:nth-child(3n + 2) {
    background-color: $green-5DF;
    color: $primary;
    text-align: center;
    padding: 0 1.5rem;
  }
}

.activity-box,
.common-card-box {
  .activity-image-frame,
  .img-cover,
  .overlay-bg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:nth-child(3n) {
    .activity-image-frame,
    .img-cover,
    .overlay-bg {
      background-color: $black-242;
      color: $white;
      text-align: center;
      padding: 0 1.5rem;
    }
  }

  &:nth-child(3n + 1) {
    .activity-image-frame,
    .img-cover,
    .overlay-bg {
      background-color: $primary;
      color: $white;
      text-align: center;
      padding: 0 1.5rem;
    }
  }

  &:nth-child(3n + 2) {
    .activity-image-frame,
    .img-cover,
    .overlay-bg {
      background-color: $green-5DF;
      color: $primary;
      text-align: center;
      padding: 0 1.5rem;
    }
  }
}

.common-all-card-wrapper {
  padding: 3.2rem 0;
  display: flex;
  align-items: center;
  justify-content: start;
  // overflow-y: visible;
  overflow-x: scroll;
  gap: clamp(10px, 5vw, 2.5rem);
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    appearance: none;
    display: none;
  }

  .common-card-box {
    position: relative;
    width: 26vw;
    // width: calc(33.33% - 20px);
    max-width: 37.6rem;
    min-width: 37rem;
    border: 1px solid $grey-ECE;
    border-radius: 1rem;

    // overflow: hidden;
    &:hover {
      box-shadow: 0 4px 15px -4px $black-11;
    }

    .main-img {
      width: 100%;
      object-fit: cover;
      border-radius: 0.8rem 0.8rem 0 0;


        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        // max-width: 100%; /* Adjust the width as per your requirement */

    }
    .checkbox-container {
      position: absolute;
      right: 0;
      top: 1rem;
      input {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 0.6rem;
        margin-right: 1rem;
      }
    }
    .video-card-height {
      height: 18.7rem;
    }

    .infographic-image-class {
      height: 50.9rem;
    }

    .social-media-card-height {
      height: 48.3rem;
    }

    .podcasts-img-class {
      height: 37rem;
    }

    .premium-icon {
      position: absolute;
      top: 13px;
      left: 12px;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1.5rem;

      img {
        width: 100%;
        max-width: 2.4rem;
      }
    }

    &:hover {
      cursor: pointer;

      .caourated-hover-text {
        opacity: 1;
        transition: all 0.6s;
      }
    }
  }
}

.color-language-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.8rem;
  position: absolute;
  left: 1.2rem;
  bottom: 1.3rem;

  .color-frame,
  .language-frame {
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: transparent;
    padding-right: 0.5rem;
    border-radius: 2rem;
    gap: 0.4rem;
    width: 2rem;
    transition: all 0.3s;

    p {
      // padding-bottom: .1rem !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      width: 100%;
      transition: all 0.6s;
      background-color: $white;
    }
  }
}

.pointer-none {
  cursor: not-allowed !important;
}

.description-box {
  display: flex;
  align-items: center;
  position: relative;
  // justify-content:space-between;
  padding: 0.8rem;
  height: 100%;
  max-height: 9rem;

  .des-profile {
    margin-right: 1rem;
    border-radius: 20rem;
    width: 4rem;
    height: 4rem;
  }

  .des-content-wrappper {
    width: 100%;
    max-width: 26.8rem;

    p {
      display: flex;

      // margin-right: 3.7rem;
      &:first-child {
        // height: 4.9rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span {
        padding: 0.2rem 0.8rem;
        border-right: 1px solid $black-242;
        gap: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 0.8rem;

        .icon {
          // margin-right: 0.8rem;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  .dropdown {
    max-width: 4rem;
    width: 100%;
  }
}

.co-branding-popup-cancle {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  top: -1rem;
  right: 1rem;
  z-index: 9;
}

.option-icon {
  position: absolute;
  top: -3rem;
  right: 0;
  padding: 0.5rem 0 1.6rem 1.6rem;
  cursor: pointer;
  background-color: $white !important;
  border: none !important;
  outline: none;
  box-shadow: none !important;

  &::after {
    display: none;
  }

  &:focus,
  &:hover {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.share-wrapper-btn {
  display: flex;
  align-items: center;
  justify-content: start;
}

.card-options {
  background-color: $white;
  border: none;
  box-shadow: none;
  border-radius: 0.8rem;
  background: $white;
  padding: 1.2rem 0;
  width: 18rem;
  display: flex;
  flex-direction: column;
  // gap: 0.8rem;
  list-style: none;
  position: absolute;
  top: 3.3rem;
  right: 0.4rem;
  z-index: 1;
  box-shadow: none;
  box-shadow: 0 0 0.8rem $grey-2;

  &:focus,
  .dropdown-toggle,
  .focus {
    background-color: $white;
    border: none;
    box-shadow: none;
  }

  a {
    cursor: pointer;
    padding: 0.4rem 1.2rem;

    &:hover {
      background-color: $grey-2;
    }

    .imageIcon {
      // margin-right: 0.8rem;
      font-size: 23px;
    }
  }
}


.form-label-custom {
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #242e3d;
  font-weight: 600;
  margin-bottom: 1rem;
}

.w-17 {
  width: 17px;
}

.w-21 {
  width: 21px;
}

.mb-11 {
  margin-bottom: 1.1;
}

.mb-67 {
  margin-bottom: 3.2rem;
}

.result-wrap {
  overflow-x: unset;
  flex-wrap: wrap;
  justify-content: start;

  .common-card-box {
    width: 45%;
  }
}

//   ?-------- Custom Readio one select input start ------------

.redio-squre-option-label {
  display: flex;
  margin-top: 1.6rem;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: clamp(2rem, 2vw, 3rem);
}

.custom-radio-label {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.8rem;
  cursor: pointer;
  min-width: 15rem;
  // margin-right: 20px;
  max-width: 23rem;
  padding: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  $white-space: nowrap;
  margin-bottom: 0;
}

.custom-radio {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid $primary;
  background: $white;
}

.custom-radio-input {
  display: none;
}

.custom-radio-input:checked + .custom-radio {
  // opacity: 1;
  border: 2px solid $white;
  outline: 2px solid $primary;
  background: $primary;
}

.word-wrap {
  word-wrap: break-word !important;
  overflow: hidden;
}

// ?-------- Custom Readio one select input end ------------
//
// ?======== Custom DropDown color Language start -----------
.main-dropdown-wrapper {
  // max-width: 156px;
  // overflow: hidden;
  position: relative;

  .dropdown-show {
    width: 100%;
    padding: 0.5rem 0;
    z-index: 4;
    // transition: all 0.6s;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 0 6px $black-6;
    // display: none;
    position: absolute;

    // z-index: 1;
    ul {
      list-style: none;
      width: 100%;

      li {
        padding: 0.7rem 1.3rem;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 0.8rem;
        cursor: pointer;

        &:hover {
          background-color: $grey-ECE;
        }
      }
    }
  }

  .drop-down:checked ~ {
    .dropdown-show {
      display: block;
      // transition: all .4s;
    }

    label {
      .drop-down-icon {
        transform: rotate(-180deg);
        // transition: all .4s;
      }
    }
  }

  .color-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    // background-color: red;
  }
}

.custom-dropdown-btn {
  border-radius: 8px;
  border: 1px solid $black-4 !important;
  background: $white !important;
  color: $black-242 !important;
  // width: 100vw;

  min-width: 156px;
  padding: 6px 30px 6px 12px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  // position: absolute;
  // top: -2px;
  // z-index: 2;
  &::after {
    display: none;
  }

  &:disabled {
    //   border: 1px solid var(--deep-blue-10, rgba(36, 46, 61, 0.10));
    //    background: $grey-ECE !important;
    .drop-down-icon {
      mix-blend-mode: darken !important;
    }

    opacity: 1;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.5;
      left: 0;
      top: 0;
      background: $grey-ECE !important;
    }
  }

  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
    box-shadow: none !important;
    background-color: $white;
  }

  .drop-down-icon {
    position: absolute;
    right: 0.6rem;
    top: 0.7rem;
  }
}

.fw-65 {
  font-weight: 600;
}

.pb-60-40 {
  padding-bottom: 6rem;
}

// ?======== Custom DropDown color Language end -----------

// =================== COMON CSS END ==================

// ? ====================== PAGINATION SECTION START ================
.pagination-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 4rem;

  .pagination-ion {
    font-size: 4rem;
  }

  .pagination-count {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;

    .pagination-item {
      border-radius: 3.651px;
      border: 0.73px solid $black-242;
      padding: 0.8rem 1rem;
      width: 5rem;
      text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.termsCondition-style {
  section {
    padding: 1.2rem 0;

    h2 {
      padding-bottom: 1rem;
    }

    ol {
      padding: 0.7rem 0 0.2rem clamp(1rem, 5%, 5rem);

      li {
        margin: 0.9rem 0;
      }
    }
  }
}

// ? ====================== PAGINATION SECTION END ================
//
//
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds-spinner div {
  transform-origin: 10px 10px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1px;
  left: 10px;
  width: 2px;
  height: 4px;
  border-radius: 10%;
  background: $grey-757;
}

.primary-spinner div:after {
  top: 4 !important;
  height: 2 !important;
  background: $primary !important;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.social-icon {
  width: 2.1rem;
}

.w-20 {
  width: 2.1rem;
}

.disabled {
  cursor: not-allowed;
  background-color: grey;
  pointer-events: none;
}
.disable-arrow{
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}
.lazy-load-image-background,
.blur,
.lazy-load-image-loaded {
  width: 100% !important;
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
@media (min-width: 1440px) {
  .container,
  .container-mb {
    padding: 0 130px !important;
  }
}

@media (min-width: 1040px) {
  .responsive-card-width {
    // width: calc(33.33% - 17px) !important;
    width: calc(26.73vw - 17px) !important;
    // min-width: 15rem !important;
    max-width: 37.6rem !important;
    // max-height: 48rem;
    min-height: 19.4rem !important;
    border-radius: 0.8rem !important;
  }
  .responsive-card-target {
    // width: calc(33.33% - 17px) !important;
    width: calc(26.73vw - 17px) !important;
    min-width: 15rem !important;
    max-width: 37.6rem !important;
    // height: 48rem;
    border-radius: 0.8rem !important;
  }

  .responsive-card-width-skleton {
    width: calc(26.73vw - 17px) !important;
    max-width: 37.6rem;
  }
  .social {
    // justify-content: space-evenly;
    .common-card-box,
    .responsive-card-width {
      width: 31% !important;
      min-width: 31%;
      cursor: auto;
      .main-img,
      .description-box {
        cursor: default;
      }
      .checkbox-container {
        input {
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 820px) {
  .target-imag-frame-p {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 19rem !important;
    width: 15.6rem;
    border-radius: 0.8rem;

    &:nth-child(3n) {
      background-color: $black-242;
      color: $white;
      text-align: center;
      padding: 0 1.5rem;
    }

    &:nth-child(3n + 1) {
      background-color: $primary;
      color: $white;
      text-align: center;
      padding: 0 1.5rem;
    }

    &:nth-child(3n + 2) {
      background-color: $green-5DF;
      color: $primary;
      text-align: center;
      padding: 0 1.5rem;
    }
  }
}
@media (max-width: 1100px) {
  .result-wrap {
    overflow-x: unset;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;

    .common-card-box {
      width: 45%;
    }
  }

  .social-media-posts-wrapper {
    justify-content: center !important;
  }

  .container,
  .container-mb {
    padding: 0 5% !important;
  }

  .ft-28-18 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .ft-57-32 {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  // =================== COMON CSS START ==================
  .desk-show {
    display: none !important;
  }

  .tab-show {
    display: block;
  }

  .tb-full {
    width: 100% !important;
  }

  // =================== COMON CSS START ==================
}
@media (max-width: 1300px) {
  .description-box {
    .des-content-wrappper {
      p {
        span {
          &:last-child {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 90px;
            display: block;
            overflow: hidden;
            img {
              margin-right: 0.8rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .result-post {
    overflow-x: unset;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    gap: 1.7rem;

    .common-card-box {
      width: 40%;
    }
  }

  // =================== COMON CSS START ==================
}

@media (max-width: 820px) {
  .container-mb {
    padding: 0;
    width: 100%;
  }

  .mb-gap-5 {
    gap: 0.5rem !important;
  }

  .gap-12-mb {
    gap: 1.2rem;
  }

  .mw-151-1 {
    max-width: 8rem !important;
  }

  .mb-full {
    width: 100%;
    max-width: 100%;
  }

  .mb-wrap {
    flex-wrap: wrap;
  }

  .amc-page-padding {
    padding: 7rem 0 0;
  }

  .amc-logo-mobile {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
  }

  .social-icon {
    width: 1.8rem;
  }

  .w-20 {
    width: 1.8rem;
  }

  // todo: ========= FONT SICE RESPONSIVE -----------------------
  .fs-48-32 {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  .fs-57-32 {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  .fs-75-41 {
    font-size: 4.1rem;
    line-height: 5.4rem;
  }

  .fs-16-14,
  .fs-18-14,
  .fs-22-14,
  .fs-24-14 {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .fs-16-11 {
    font-size: 1.1rem;
    line-height: 2rem;
  }

  .fs-16-13 {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  .fs-28-24,
  .fs-36-24 {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .fs-14-9,
  .fs-18-9 {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }

  .fs-14-10 {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .fs-32-28,
  .fs-57-28,
  .fs-45-28 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .fs-32-24 {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .fs-32-20 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .fs-22-13 {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  .fs-22-18,
  .fs-24-18,
  .fs-28-18,
  .fs-32-18 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .fs-14-13 {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  .fs-18-16,
  .fs-22-16,
  .fs-24-16,
  .fs-20-16 {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  .fs-14-12,
  .fs-16-12,
  .fs-18-12,
  .fs-24-12,
  .fs-26-12,
  .fs-22-12 {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .fs-14-11 {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
  .fs-13-11 {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  .fs-12-10 {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  // ========= FONT SICE RESPONSIVE -----------------------
  .container {
    margin: 0;
    padding: 0 5% !important;
  }

  .page-padding {
    padding: 7rem 0 0;
  }

  .page-padding-filter {
    padding: 7rem 0 0;
  }

  // =================== COMON CSS START ==================
  .dt-show {
    display: none;
  }

  .mb-inline-block {
    display: inline;
  }

  .pb-60-40 {
    padding-bottom: 4rem;
  }

  .mb-show {
    display: block !important;
  }

  .mb-border-none {
    border: none !important;
  }

  .mb-none {
    display: none !important;
  }

  .mb-flex {
    display: flex !important;
  }

  .mb-67 {
    margin-bottom: 0.3rem;
  }

  .fw-65 {
    font-weight: 500;
  }

  .mb-py-20 {
    padding: 2rem 0;
  }

  .min-100 {
    min-width: none !important;
  }

  .mb-wrap {
    flex-wrap: wrap;
  }
  .op-1 {
    opacity: 100%;
  }
  // =================== COMON CSS END ==================
  .main-content-wrapper {
    padding-top: 0;
    // margin-top: 7.5rem;
  }

  .common-all-card-wrapper {
    padding: 3.2rem 0;
    display: flex;
    align-items: center;
    justify-content: start;
    overflow-x: scroll;
    gap: 3.3rem !important;

    .common-card-box {
      position: relative;
      width: 29vw;
      max-width: 37.6rem;
      min-width: 15.2rem;
      border: 1px solid $grey-ECE;
      border-radius: 0.8rem;

      // overflow: hidden;
      .main-img {
        width: 100%;
        object-fit: cover;
      }

      .video-card-height {
        height: 10.8rem; //this chsnge
      }

      .infographic-image-class {
        //this added
        height: 16.5rem !important;
        object-fit: fill;
      }

      .podcasts-img-class {
        height: 15rem !important;
        object-fit: fill;
      }

      .premium-icon {
        position: absolute;
        top: 0.9rem;
        left: unset;
        right: 0.9rem;
        z-index: 0;
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        justify-content: end;
        transform: scale(0.8);
      }

      &:hover {
        cursor: pointer;

        .caourated-hover-text {
          opacity: 1;
          transition: all 0.6s;
        }
      }
    }
  }

  .option-icon {
    top: unset;
    bottom: -4rem;
    padding: 0.1rem 0 1.6rem 1rem;
  }

  .share-wrapper-btn {
    gap: 0.8rem;
  }

  .custom-radio {
    width: 18px;
    height: 18px;
  }

  .custom-radio-label {
    min-width: auto;
  }

  .description-box {
    .des-content-wrappper {
      p {
        &:first-child {
          height: 4.1rem;
          // margin-right: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          padding: 0.2rem 0.7rem 0.2rem 0.6rem;
          gap: 0.4rem;
        }
      }
    }
  }

  .card-options {
    a {
      .imageIcon {
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 375px) {
  .common-all-card-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem !important;
    .common-card-box {
      // margin-bottom: 3rem;
      min-width: 15rem !important;
    }
  }
}
