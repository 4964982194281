.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .modal-content {
      padding: 4rem 6rem;
      .modal-header {
        border-bottom: 1px solid $grey-EBE;
        padding:2.2rem 1.4rem;
        background-color:$grey-F1F;

        align-items: center;
        .close-icon {
          cursor: pointer;
          position: absolute;
          // top: 3rem;
          right: 3rem;
          width: 2rem;
          height: 2rem;
        }
      }
      .modal-body {
        padding: 1.4rem;
        .modal-wrapper {
        //   padding: 3.9rem 8.6rem 2.9rem;
        }
        .btn {
          font-family: "Inter-Bold", Arial, Helvetica, sans-serif;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 24px;
        }
        .submit-wrapper {
          margin-top: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid $grey-EBE;
          padding: 1.4rem 3rem;
          .btn {
            padding: 0.8rem 2rem;
          }
        }
      }
    }
    &.forgot-password-modal{
      max-width: 75rem !important;
      height: -webkit-fill-available;
      .modal-content{
        padding: 6rem 10rem;
        .modal-body{
          padding: 0;
          text-align: center;
          h3{
            padding: 2rem 0 3rem;
          }
          .forgot-image{
            width: 30rem;
          }
          .form-group{
            width: 37rem;
            margin: 3.2rem auto 3.2rem;
          }
          .password-hide{
            right: 10rem;
  bottom: 0.8rem;
  top: 4.3rem;
          }
          .back-to-login{
            padding-top: 2.6rem;
          }
        }
      }
    }
    &.change-password {
        .modal-content{
           .modal-body{
            padding:3rem 2.2rem;
           }
        }
      &.change-status{
        .modal-content{
          .modal-header{
            padding: 2rem 3rem;
          }
        }
      }

      .modal-content {
        // padding: 4rem 6rem;
        padding: 0;
        .modal-header {
          // border-bottom: 0;
          // padding: 0 0 4rem 0;
          .close-icon {
            cursor: pointer;
            position: absolute;
            top: 3rem;
            right: 3rem;
          }
        }
        .modal-body {
          // padding: 0;
          .radio-input{
            margin: 0 0 1.8rem 0;
          }
          .form-group {
            .input-group {
              .form-control {
                // position: relative;
                border-right: 0;
              }
              .input-group-prepend {
                background: transparent;
                .eye-icon {
                  // position: absolute;
                  // right: 1.5rem;
                  // top: 3.7rem;
                  cursor: pointer;
                }
              }
            }
          }
          .submit-wrapper {
            justify-content: center;
            border-top: 0;
            padding: 2rem 0 0 0;
            .black-btn {
              padding: 1.1rem 4rem;
            }
          }
          .text-btn{
            justify-content: center;
            display: flex;
            padding: 2rem 0 0 0;
            .btn{
              padding: 0;
              background-color: $white;
              border-color: $white;
            }
          }

        }
      }
    }
    &.information-modal{
      .modal-content{
        padding: 0;
        .modal-body{
            padding: 4rem 2.3rem 2.3rem 2.3rem;
            .information-modal-wrapper{
              .submit-wrapper{
                padding: 3.5rem 0 0 0;
                justify-content: end;
                gap: 2rem;
                border-top: none;
              }
            }
          }
      }


    }
    &.alerts-modal{

      .modal-content{
        padding: 0;
        max-width: 55.6rem;
        border-radius: 0.8rem !important;
        padding: 2rem 2.3rem 2.3rem 2.3rem;

        .modal-header{
          background-color: $white !important;
          .close-icon{
            background-color: $white !important;
          }
        }
        .modal-body{
          padding: 1.4rem 0;
}

      }


    }

    &.planner-modal{
      max-width: 75rem !important;
      .modal-content{
        padding: 2rem 3rem;
       border-radius: 0.8rem !important;

        .modal-header{
        .close-icon {
          cursor: pointer;
          position: absolute;
          height: 5rem;
           width: 5rem;

         }
        }
        .modal-body{
          padding: 0;
          .primary-btn{
            margin-top: 5rem;
            border-radius: 0.8rem;
          }
          .secondary-btn{
            margin-top: 5rem;
            margin-right: 0.8rem;
            border-radius: 0.8rem;
            padding: 1rem 2.4rem !important;

          }
        }
      }
    }
    &.discontinue-modal{
      max-width: 75rem !important;
      .modal-content{
        padding: 2rem 3rem;
       border-radius: 0.8rem !important;

        .modal-header{
        .close-icon {
          cursor: pointer;
          position: absolute;
          height: 5rem;
           width: 5rem;

         }
        }
        .modal-body{
          padding: 0;
          .primary-btn{
            margin-top: 5rem;
            border-radius: 0.8rem;
          }
          .secondary-btn{
            margin-top: 5rem;
            margin-right: 0.8rem;
            border-radius: 0.8rem;
            padding: 1rem 2.4rem !important;

          }
        }
      }
    }
    &.planner-heads-modal{
      max-width: 75rem !important;
      .modal-content{
        padding: 2rem 3rem;
       border-radius: 0.8rem !important;

        .modal-header{
        .close-icon {
          cursor: pointer;
          position: absolute;
          height: 5rem;
           width: 5rem;

         }
        }
        .modal-body{
          padding: 0;
          .primary-btn{
            margin-top: 2rem;
            border-radius: 1rem ;
          }

        }
      }
    }
    &.unused-heads-modal{
      max-width: 48.4rem !important;
      .modal-content{
        padding: 1.6rem 2rem;
       border-radius: 0.8rem !important;

        .modal-header{
        .close-icon {
          cursor: pointer;
          position: absolute;
          height: 5rem;
           width: 5rem;

         }
        }
        .modal-body{
          padding: 0;
          .unused-text{
            padding-right: 5rem;
          }
          .detail-ul {
            margin-top: 2rem;
            list-style: none;
            // padding: 4rem 0 0;
            // max-width: 34.6rem;
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1rem;
            }
          }


        }
      }
    }
    &.compliance-modal{
      max-width: 55.6rem !important;
      .modal-content{
        padding: 1.4rem 2rem !important;
       border-radius: 0.8rem !important;
   .modal-body{
    padding: 0 !important;


   }
        .modal-header{
        .close-icon {
          cursor: pointer;
          position: absolute;
          height: 5rem;
           width: 5rem;

         }
        }

      }
    }
    &.disconnect-modal{
      max-width: 55.6rem !important;
      .modal-content{
        padding: 1.4rem 2rem !important;
       border-radius: 0.8rem !important;
   .modal-body{
    padding: 0 !important;
    .disconnect-btns{
      margin-top: 3rem;
      .secondary-btn{
        margin-right: 0.8rem;
        border-radius: 0.8rem;
        padding: 1rem 2.4rem;
      }
      .primary-btn{

        border-radius: 0.8rem;
        padding: 1rem 1.6rem;
      }
    }


   }
        .modal-header{
        .close-icon {
          cursor: pointer;
          position: absolute;
          height: 5rem;
           width: 5rem;

         }
        }

      }
    }
  }

  @media (max-width:820px) {
    .custom-modal{
      margin: 0;
      .modal-content{
        .modal-header{
          .close-icon{
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      &.forgot-password-modal{
        .modal-content{
          padding: 4rem 0;
          .modal-body{
            .form-group{
              width: 100%;
            }
            .password-hide{
              right: 1.1rem;
            }
          }
        }
      }
      &.planner-modal{
        padding-right: 0 !important;

        .modal-content{
          padding: 2rem 0 !important;

        }

      }
      &.discontinue-modal{
        padding-right: 0 !important;
        max-width: 34rem !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        height: 100vh !important;
        margin: 0 auto;

         .modal-content{
          padding: 2rem 0 !important;
          .modal-body{
            .primary-btn{
              margin-top: 2.4rem !important;
              padding: 1rem 3rem !important;
              font-size: 1.4rem !important;
            }
            .secondary-btn{
              margin-top: 2.4rem !important;
              padding: 1rem 4.7rem !important;
              font-size: 1.4rem !important;

            }
          }
        }

      }
      &.planner-heads-modal{
        padding-right: 0 !important;
        .modal-content{
          padding: 2rem 2rem !important;
        }
        .modal-dialog.modal-body{
          padding: 0 !important;


        }

      }
      &.unused-heads-modal{
        padding-right: 0 !important;
        .modal-content{
          padding: 1rem 1rem !important;
        }
        .modal-dialog.modal-body{
          padding: 0 !important;



        }
        .modal-body{
        .unused-text{
          padding-right: 0 !important;
        }
      }

      }
      &.alerts-modal{
        padding-right: 0 !important;
        .modal-body{
          margin-bottom: 1rem;
          padding: 1rem 0 !important;
        }
      }
      &.complaince-modal{
        padding-right: 0 !important;


      }
      &.disconnect-modal{
        padding-right: 0 !important;


      }
    }
  }


