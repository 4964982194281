
.amc-all-card-frame {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(2rem,2.5vw,2.5rem);
    flex-wrap: wrap;
}

// ================ CARD CSS START==============
.object-fit-none{
    object-fit: none !important;
}
.amc-card-frame {
    width: 45%;
    min-width: 28rem;
    max-width: 370rem;
    height: 100vw;
    max-height: 26.1rem;
    border-radius: 1.2rem;
    border: 0.1rem solid $grey-ECE;
    background: $white;
    overflow: hidden;
    .amc-imag-frame {
        height: calc(100vh + 2.7rem);
        max-height: 17.2rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
            width: 100%;
            max-height: 17.2rem;
            object-fit: contain;
            padding: 2rem;
        }
    }
    .amc-description-box {
        padding: 1rem 1.6rem;
        p {
            margin-bottom: 1.2rem;
        }
        .amc-count-content-type {
            display: flex;
            align-items: center;
            justify-content: center;
            // flex-wrap: wrap;
            overflow-x: auto;
            gap: 1.5rem;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                appearance: none;
                display: none;
                height: 0.3rem;
                background-color: $grey-ECE;

            }
            &::-webkit-scrollbar-thumb {
                background-color: $grey-ECE;
                border-radius: 0.8rem;
            }
            .amc-type {
                text-align: center;
                padding: 0.1rem 0.5rem;
                white-space: nowrap;
                p {
                    margin-bottom: 0.2rem;
                    opacity: 0.7;
                }
            }
            .vertical-strip {
                width: 0.15rem;
                height: 2.4rem;
                background-color: $grey-ECE;
            }

        }
    }
}
.amc-listening-filter{
    padding:3px clamp(1rem,5%,3rem);
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
}
.amc-rambo-effect {
    &:nth-child(3n) {
        .amc-imag-frame,
        .img-cover,
        .target-imag-frame {
            background-color: $black-242;
            color: $white;
            text-align: center;

        }
    }
    &:nth-child(3n+1) {
        .amc-imag-frame,
        .img-cover,
        .target-imag-frame {
            background-color: $primary;
            color: $white;
            text-align: center;

        }
    }
    &:nth-child(3n+2) {
        .amc-imag-frame,
        .img-cover,
        .target-imag-frame {
            background-color: $green-5DF;
            color: $primary;
            text-align: center;

        }
    }
}
.amc-card-type-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 1.2rem !important;
    margin: 0 1rem !important;
}
.l-s-129 {
    letter-spacing: 0.129px;
}
.amc-details-main-image {
    width: 100%;
    height: auto;
    object-fit: fill;
    max-height: 30rem;
    margin: 1.6rem 0 2.5rem;
    border-radius: 0.8rem;
}
// ================ CARD CSS END==============

.search-amc-wrapper {
    width: 100%;
    border: 0.1rem solid rgba(36, 46, 61, 0.5);
    padding: 1.6rem 3rem;
    background-color: white;
    position: relative;
    border-radius: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 3rem;

    .search-input-field-icon {
        right: 3rem;
    }
    .cancel-field {
        right: 6.5rem;
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
@media (max-width:720px) {
    .mb-wrap{
        flex-wrap: wrap;
    }
    // ================ CARD CSS START==============
    .amc-card-frame {
        width: 100%;
        max-width: 336px;
        min-width: 28rem;
        max-height: 27.1rem;
        border-radius: 0.8rem;
        .amc-imag-frame {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        .amc-description-box {
            padding: 0.9rem 1.2rem;
            p {
                margin-bottom: 1.2rem;
            }
            .amc-count-content-type {
                gap: 0.8rem;
                .vertical-strip {
                    display: none;
                }
                .amc-type {

                    p {
                        margin-bottom: 0;
                        margin-top: 1.2rem;
                    }
                }

            }
        }
    }
    .search-amc-wrapper {

        border-radius: 0.4rem;
        padding: 1.1rem 0.9rem 1.1rem 1.3rem;
        .search-input-field-icon {
            right: 2rem;
        }
    }
    .amc-details-main-image {
        // height: 18rem;
        border-radius: 0.8rem;
        margin-top: 1.2rem;
        object-fit: contain !important;
    }
    .amc-listening-filter{
        order: 1;
        width: 100%;
        padding: 1rem 0;
    }
}
