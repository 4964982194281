.search-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 0 1rem;


    .not-found-img {
        text-align: center;
        width: 100%;

        img {
            width: 100%;
            max-width:84rem !important;

           object-fit: contain;

            }
    }
    .not-found-coll{
        text-align: center;
        width: 100%;
        img {
            width: 100%;
            max-width:68rem !important;
           }
    }
    .search-trand-wraper {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }
}
.not-found-trend {
    border-radius: 8px;
    border: 1px solid $primary;
}

@media (min-width: 1900px) {
    .search-not-found {

     .not-found-img {
            text-align: center;
            width: 100%;

            img {
                width: 100%;
                max-width:96rem !important;
               object-fit: contain;

                }
        }
        .not-found-coll{
            text-align: center;
            width: 100%;
            img {
                width: 100%;
                max-width:78rem !important;
               }
        }
    }
}
