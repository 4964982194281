.information-wrapper {
  // padding: 0 13.5rem;
  // padding-top: 6rem;
  h2 {
    @include font($black-242, "Lato-Regular", 2.8rem, 400, 36px);
  }
  .form-area {
    margin-top: 4rem;
    display: flex;
    // height: 100vh;
    // margin-bottom: 8rem;
    margin-bottom: 4rem;
    .form-details {
      flex: 60;
      // background-color: yellow;
      .verification-status {
        @include font($yello-EDC, "Lato-Medium", 1.2rem, 500, 19px);
        position: absolute;
        right: 0;
        top: 0.2rem;
        .icons {
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.1rem;
          vertical-align: middle;
        }
        &.email {
          right: 1.5rem;
          // z-index: 1;
        }
        &.verified {
          color: $green-9AB;
        }
      }
      .form-group {
        margin-bottom: 4rem;
        input {
          border-radius: 0.8rem;
          border: 1px solid $grey-CDC;


        }
        .is-invalid{
          border-color: $red-FF3 !important;
        }

        .form-label {
          font-family: "Lato", sans-serif;
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: $black-242;
          font-weight: 600;
        }
        .form-control {
          .is-invalid{
            border-color: $red-FF3 !important;
          }
          &.verify {
            padding-right: 6rem;
          }
        }

        .form-control:disabled {
          cursor: not-allowed;
        }
        &.custom-group {
          margin-bottom: 0;
        }
      }
      .verification-wrapper {
        background-color: $secondary;
        border-radius: 8px;
        display: flex;
        align-items: flex-start;
        padding: 1.6rem;
        margin-top: 1.6rem;
        min-height: 14.3rem;
        .icons {
          margin-right: 1.2rem;
        }
        .right {
          .otp-wrapper {
            display: flex;
            padding-top: 1.3rem;
            .primary-btn {
              margin: 0 0 0 1rem;
              &.disable {
                background-color: $grey-DCD;
                border: 1px solid $grey-DCD;
                color: $grey-1D1;
                cursor: not-allowed;
                &:active,
                &:active:focus,
                &:focus,
                &:hover {
                  background-color: $grey-DCD;
                  border: 1px solid $grey-DCD;
                  color: $grey-1D1;
                  box-shadow: none;
                }
              }
              &:active,
              &:active:focus,
              &:focus,
              &:hover {
                background-color: $primary;
                border: 1px solid $primary;
                // color: $grey-1D1;
                box-shadow: none;
              }
            }
          }
          span {
            float: right;
            padding: 0.5rem 0.5rem 0 0;
          }
        }
        &.email {
          button {
            margin-bottom: 0;
            font-size: 1.2rem;
            line-height: 19px;
          }
          .secondary-btn {
            margin-top: 1.2rem;
            background-color: $secondary;
            border-color: $black-11 !important;
            &:hover,
            &:active,
            &:focus {
              background-color: $secondary !important;
            }
          }
        }
      }
    }
    .profile-image {
      flex: 40;
      // background-color: blue;
      display: flex;
      justify-content: end;

      .profile-section {
        width: 170px;
        height: 170px;
        background-color: $grey-5;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 0.8rem;
        position: relative;
        .edit {
          display: flex;
          justify-content: end;
          width: 100%;
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        h3,
        .image-requirment {
          position: absolute;
          z-index: 3;
          bottom: -2rem;
        }
        .image-requirment {
          bottom: -6rem;
          text-align: center;
        }
      }
    }
  }
  button {
    margin-bottom: 8rem;
  }
}
.detail-button-download {
  width: fit-content;
  background: $grey-9;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.56rem;
  padding: 1rem 1.4rem;
  border-radius: 0.8rem;
  gap: 0.8rem;
  border: 0.1rem solid $grey-2;
  color: $grey-3;
  cursor: not-allowed;
  margin-bottom: 8rem;
}
// =========================PROFILE DETAILS COMPONENT CSS END ==========================

// ========================= PROFILE STATUS COMMON COMPONENT CSS START ==========================
.profile-status-wrapper {
  .modal-body {
    padding: 0 10rem;
    .profile-status-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .heading {
        margin-top: 2.7rem;
        margin-bottom: 0.8rem;
      }
      .complete {
        margin-top: 2.4rem;
        margin-bottom: 1.2rem;
      }
      button {
        margin-top: 4rem;
        margin-bottom: 6.7rem;
      }
      .progress {
        width: 100%;
        margin-bottom: 1.2rem;
        height: 20px;
        border-radius: 33px;
        .progress-bar {
          border-radius: 33px;
          background-color: $primary;
        }
      }
    }
  }
}
//

@media (max-width: 1100px) {
}

@media (max-width: 820px) {
  .information-wrapper {
    // padding: 0 2rem;
    // margin-top: 8.6rem;
    .form-area {
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 2rem;
      .profile-section {
        margin-bottom: 2rem;
      }
      .form-group {
        margin-bottom: 2rem;
      }
    }
  }
  .mb-90 {
    margin-bottom: 9rem !important;
  }
}
