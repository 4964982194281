.keywords-dropdown-frame {
    width: 100%;
    position: relative;

}
.keywords-input-fields {
    width: 100%;
    position: relative;
    .input-box-keywords {
        width: 100%;
        @include font($grey-757, "Lato-SemiBold", 1.8rem, 600, 24px);
        // font-family: "Roboto-Regular", Arial, Helvetica, sans-serif;
        // font-size: 1.8rem;
        // font-weight: normal;
        // color: $grey-757;
        // line-height: 2.4rem;
        // padding: 0px 12px;
        padding: 0px 7rem 0 1.2rem;
        height: auto;
        background-color:$white;
        background-clip: padding-box;
        border: none;
        border-radius: 0.25rem;
        outline: none;
        &:-webkit-autofill{
            -webkit-text-fill-color: transparent !important;
        }

    }
    .cancel-field {
        position: absolute;
        right: 3.5rem;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
    }
    .search-input-field-icon{
        position: absolute;
        right: 0rem;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
    }
}
.autocomplete-keywords-dropdown {
    list-style: none;
    width: 103%;
    padding: 0.5rem ;
    height: auto;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $grey-CED;
    border-radius: 0.25rem;
    outline: none;
    max-height: 25rem;
    position: absolute;
    left: -1rem;
    z-index: 999;
    overflow-y: auto;
    margin-top: 1.9rem;
    box-shadow: 0 0 6px $black-9;
    li {
      @include font($black-10, "Lato-SemiBold", 1.8rem, 600, 22px);
        // font-size: 1.8rem;
        // font-weight: normal;
        // color: $black-10;
        // line-height: 2.4px;
        // font-weight: bold;
        padding: 9px 8px;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover{
            background-color:$black-9;
        }
    }
}