
.white {
    color: $white;
}
.blue-270 {
    color: $primary;
}
// ============= COLORS END ===============
.mw-352 {
    width: 100%;
    max-width: 35.2rem;
}
.ghost-btn {
    padding: 0.9rem 1.8rem;
    border-radius: 0.4rem;
    border: 1px solid $white;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    @include font($white, "Inter-Regular", 1.6rem, 400, 24px);
    &:active,
    &:active:focus,
    &:focus,
    &:hover {
        background-color: transparent !important;
        border: 1px solid $white !important;
        outline: none;
        box-shadow: none;
    }
    &:not(.disabled),
    &:not(.disabled):active,
    &:not(:disabled) {
        background-color: transparent !important;
        border: 1px solid $white !important;
        cursor: auto;
    }
}
.wh-boder-b {
    border-bottom: 0.1rem solid $white;
    padding-bottom: 0.1rem;
}
.left-0 {
    left: 0;
}
.login-page {
    display: flex;
    justify-content: center;
    height: 100vh;
    min-height: 79rem;
    .left-carousel-frame {
        width: 50%;
        // max-width: 72rem;
        position: relative;
        // background-size: cover;
        .back-to-login {
            display: flex;
            align-items: center;
            gap: 0.0rem;
            position: absolute;
            left: 17%;
            top: 11%;
            z-index: 3;
            cursor: pointer;
        }
        .carousel {
            height: 100%;
            .carousel-indicators {
                display: none;
            }
            .carousel-control-next,
            .carousel-control-prev {
                display: none;
            }
            .carousel-inner {
                height: 100%;
                .carousel-item {
                    height: 100%;
                    .first-carousel {
                        height: 100%;
                        background: $green-5DF url("../../images/login-image-left.png") right no-repeat;
                        background-size: contain;
                        .left-heading {
                            // height: ;
                        }
                    }
                    .login-second-image {
                        background: $white url("../../images/second_left-part.png") right no-repeat;
                        background-size: contain;
                    }
                    .login-third-image {
                        background: $green-5DF url("../../images/login-left-third-part.png") right no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
    .right-login-frame {
        width: 50%;
        // max-width: 72rem;
        text-align: center;
        background: $primary;
        padding: 4rem 3rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .logo-wrapper {
            text-align: center;
            // padding: 8.4rem 0 5rem;
            padding: 0.4rem 0 4rem;
            img {
                width: 100%;
                max-width: 29.4rem;
            }
        }
        .login-box,
        .register-box {
            width: 100%;
            max-width: 470px;
            margin: 0 auto;
            h5 {
                padding-bottom: 2.9rem;
                text-align: center;
                color: $white;
            }
            .error-msg {
                text-align: start;
            }
            .pb-20 {
                div {
                    div {
                        display: inline-block;
                    }
                }
                p {
                    padding-top: 1.5rem;
                    a {
                        text-decoration: underline;
                        color: inherit;
                    }
                }
            }
        }
    }
}
.password-input-field {
    margin-bottom: 0.8rem !important;
}
.forgot-password {
    text-align: start;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}
.login-with {
    padding-top: 2.7rem;
    padding-bottom: 3.2rem;
    p {
        margin-bottom: 1.6rem;
    }
    .login-with-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.4rem;
        img {
            cursor: pointer;
        }
    }
}
.hr-lines {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    .line {
        width: 100%;
        background-color: $white;
        height: 0.1rem;
    }
}
.new-to-finpedia {
    padding-top: 3.2rem;
    color: $white;
}
// ============ LEFT PART CSS START ================
.left-heading {
    padding-left: 20%;
    padding-top: 18%;
    p {
        max-width: 44.6rem;
        margin-top: 0.2rem;
    }
}
// ============ LEFT PART CSS END ==================
// ========================== REGISTER BOX START --------------

.login-content {
    form {
        .form-group {
            text-align: left;
        }
    }
}
// ?----------------- OTP CSS START -----------------
.otp-container {
    display: flex;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    margin: 2.5rem auto 4.2rem;
    .otp-input {
        width: 4rem;
        height: 3.8rem;
        padding: 10px;
        text-align: center;
        font-size: 1.4rem;
        line-height: 2rem;
        border-radius: 0.8rem;
        background-color: $white;
        border: none;
        user-select: none;
        &:focus {
            outline: none;

        }
    }
}
.otp-main-wrapper{
    .back-to-register{
        position: relative;
        .back-btn{
            position: absolute;
            left: 0;
            display: flex;
            align-items: center;
            gap: .8rem;
            padding:0 .8rem;
            cursor: pointer;
            .back-arrow{
                display: block;
                width: 1rem;
                height: 1rem;
                border-top: .2rem solid $white;
                border-left: .2rem solid $white;
                transform: rotate(-45deg);
                margin-top: 2px;
            }
        }
    }
}
.black-06{
    color:$grey-7;
}
.resend-otp-frame{
    margin-top: 2rem;

    p{
        border-bottom: .1rem solid $white;
        cursor: pointer;
    }
}
.why-otp-need-box{
    background-color: $white;
    padding: 1.2rem;
    border-radius: .8rem;
    display: flex;
    justify-content: flex-start;
    gap: .8rem;
    max-width: 35.2rem;
    width: 100%;
    margin:2.8rem auto 0 auto;
    img{
        width: 2.4rem;
        height: 2.2rem;
    }
    

}
// ?----------------- OTP CSS END -----------------
@media (max-width:820px) {
    .login-page {
        height: auto;
        .left-carousel-frame {
            display: none;
        }
        .right-login-frame {
            width: 100%;
            max-width: 100%;
            padding: 4rem 3rem 5rem;
            .logo-wrapper {
                text-align: center;
                padding: 7.4rem 0 3.8rem;
            }
        }
    }
    .forgot-password {
        text-align: start;
        margin-bottom: 0;
    }
    .mw-b-15 {
        max-width: 24rem;
        width: 100%;
    }
}
