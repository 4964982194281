.targeted-content-card-wrapper {
    margin: 3.1rem 0;
    display: flex;
    align-items: stretch;
    // justify-content: space-around;
    gap: 2.5rem;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
    border-radius: 0.8rem;

    //   overflow-x: scroll;
    .targeted-content-slick-slider{
        .target-imag-frame,.target-img-wrapper {
            border-radius: 0.8rem !important;
            width: 26.5rem;
            max-height: 48rem;
            margin: 0 2rem;
            height: 100vh;
            .targetec-content-image {
                border-radius: 0.8rem;
                // background-color: ;
                width: 26.5rem;
                max-height: 48rem;
                height: 100%;
                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 1.4rem 0 $black-3;
                }
            }
        }
        .target-imag-frame-p {
            display: flex;
            align-items: center;
            justify-content: center;

            &:nth-child(3n) {
              background-color: $black-242;
              color: $white;
              text-align: center;
              padding: 0 1.5rem;
            }

            &:nth-child(3n + 1) {
              background-color: $primary;
              color: $white;
              text-align: center;
              padding: 0 1.5rem;

            }

            &:nth-child(3n + 2) {
              background-color: $green-5DF;
              color: $primary;
              text-align: center;
              padding: 0 1.5rem;
            }
          }
    }
    .target-content-not-found{
        width: 100% !important;
        margin: 0 0!important;
        display: flex;
        align-items: center !important;
        flex-direction: column;
        justify-content: center;
    }

    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //     appearance: none;
    //     display: none;
    // }
}
.targeted-clear-bth{
    position: absolute;
    right: 0;
    top: .4rem;
    border-radius: 0.8rem;
    border: 1px solid $grey-2 !important;
    background: $white;
    padding: 0.5rem 1.2rem 0.5rem;
    color: $black-242 !important;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    cursor: pointer;
    gap: 0.8rem;
    img{
        width: 2rem;
    }
}
// ?=========================== TARGETED CONTENT FILTER CSS START ==========================
.targeted-main-filter-wrapper{
    position: relative;
    padding: 3rem 0;
}
.targeted-content-filter {
    height: 40rem;
    position: absolute;
    top: 0;
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    // overflow-x:hidden ;
    gap: 0.8rem;
    padding: 0.5rem 0rem 2.5rem 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
        appearance: none;
        display: none;
    }


}
.target-item-dropdown {
    position: relative;

    .dropdown-toggle {
        border-radius: 0.8rem;
        border: 1px solid $grey-2 !important;
        background: $white;
        padding: 0.5rem 1.2rem 0.6rem;
        color: $black-242 !important;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none !important;
        gap: 0.8rem;
        &:active,
        &:focus,&:active:focus {
            box-shadow: none;
            background-color: $white;
            border-color: $grey-2;
            outline: none !important;
        }
    }
    .dropdown-menu {
        position: absolute;
        top: 110%;
        left: 0;
        z-index: 9;
        border-radius: .8rem;
        background: $white;
        box-shadow: 0 .4rem 1.5rem -.4rem $black-11;
        padding: .6rem 0;
        min-width: max-content;
        max-height: 26rem;
        overflow-y: auto;
        width: 100%;
        list-style: none;
        li{
            padding: .0rem 0 0 1.2rem;
            display: flex;
            align-items: center;
            justify-content: start;
            gap: .8rem;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 400;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space:nowrap;
            cursor: pointer;
            &:hover{
                background-color: $grey-ECE;
            }
            label{
                padding: .4rem 1.2rem .4rem 0;
            }
        }
    }

}
.w-89{
    width: 89%;
}
.active-bg {
    background: $green-A5F !important;
    color: $black-242 !important;
    &:hover {
        background: $green-A5F !important;
        color: $black-242 !important;
    }
}
.zindex-6{
    z-index: 6 !important;
}
.show-menu{
    display: block !important;
}
.dropdown-baground-wrapper{
    position: fixed;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    left: 0;
    top: 0;
}
// ?=========================== TARGETED CONTENT FILTER CSS END  ==========================
@media (max-width:820px) {
    .targeted-content-card-wrapper {
        gap: 2.5rem;
        .target-imag-frame,.target-img-wrapper {
            width: 15.6rem;
            max-height: 18.5rem;
            .targetec-content-image {
                width: 15.6rem;
                max-height: 19rem;
                border-radius: 0.8rem;

            }
        }


    }
}
