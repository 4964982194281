.my-social-wrapper {
  .trending-tabs {
    margin-bottom: 1rem;
    padding-top: 0;
  }

  .my-social-heading {
    padding: 1.4rem 0 4rem 0;
  }
  .my-social-tabs {
    // margin-right: 5rem;
    p {
      padding: 0 0;
    }
  }

  .my-socials-content {
    padding-bottom: 2rem;
    h6 {
      padding-bottom: 4rem;
    }

    h5 {
      padding-bottom: 2.4rem;
    }

    .my-socials-options {
      border: 1px solid $grey-ECE;
      border-radius: 1.2rem;
      width: 13rem;
      // height: fit-content;
      padding: 2rem;
      margin-bottom: 2.4rem;
      .social-media-image {
        border-radius: 50%;
        overflow: hidden;
      }
      .icon-background-connect {
        background-color: $green-A5F;
        border-radius: 0.4rem;
        height: 4rem;
        width: 4rem;
      }
      .disconnect-option {
        padding-top: 0.5rem;
      }
      .icon-background-disconnect {
        border: 1px solid $grey-ECE;
        width: 4rem;
        // height: 6rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
        border-radius: 0.4rem;
      }
      .social-username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .whatsapp-details {
      p {
        padding: 0 0 1rem 0;
      }

      h6 {
        padding-bottom: 0;
      }
    }

    .radio-option-frame {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 1.4rem;
    }

    .custom-input-group {
      position: relative;
      width: 100%;

      .input-with-image {
        padding-right: 3rem;
        width: 100%;
        border-radius: 0.5rem;

        &:focus {
          box-shadow: none; // Remove any default box-shadow on focus
        }
      }

      .clear-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        padding: 5px;
        z-index: 2;
        background-color: transparent;

        &:hover {
          opacity: 0.7;
        }

        .input-with-image:focus + & {
          display: block;
        }
      }
    }
  }
  .consent-bar {
    padding: 1.2rem 1.6rem;
    border: 1px solid $red-1;
    background-color: $red-1;
    border-radius: 0.8rem;
    // margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .primary-btn {
      border-radius: 0.8rem;
      padding: 0.5rem 1.5rem;
    }
  }
  .my-social-btn-main {
    margin: 4.5rem 0 4rem 0;

    .primary-btn {
      // padding: 1rem 2.4rem;
      border-radius: 0.8rem;
    }
  }
  .my-social-links {
    padding-bottom: 4.6rem;
    p {
      padding-right: 1rem;
    }
  }
  .social-modal-header {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    border: none;
    margin: 0 2.4rem 2.4rem 0;
    cursor: pointer;
  }
  .modal-dialog .modal-body {
    padding: 0 5rem;
  }

  .modal-dialog,
  .modal-content {
    /* 80% of window height */
    // height: 80%;
    z-index: 9999;
    margin-top: 2.4rem;
    padding-top: 2.4rem;
  }
  .my-social-tabs {
    p {
      // padding:1.4rem 0 2rem 0;
    }
    .my-social-tab-btn {
      margin: 4.4rem 0 1rem 0;
      .primary-btn {
        border-radius: 0.8rem;
      }
    }
  }
}

@media (max-width: 820px) {
  .my-social-wrapper {
    .modal-dialog .modal-body {
      padding: 0 2.4rem;
    }
    .my-socials-content {
      .my-social-cards {
        gap: 1.2rem;
        flex-wrap: nowrap;

        .my-socials-options {
          width: 8rem;
          height: auto;
          padding: 0.2rem;
          border: none;
        }
      }
    }
  }
}

//styling for My Preferences

.my-preferences-wrapper {
  .my-preference-heading {
    padding: 1.4rem 0 4rem 0;
  }
  .trending-tabs {
    margin-bottom: 1rem;
    padding-top: 0;
  }
  .my-social-links {
    padding-bottom: 4.6rem;
  }
  .modal-dialog .modal-body {
    padding: 0 5rem;
  }

  .modal-dialog,
  .modal-content {
    /* 80% of window height */
    // height: 80%;
    z-index: 9999;
    margin-top: 2.4rem;
    padding-top: 2.4rem;
  }
  .social-modal-header {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    border: none;
    margin: 0 2.4rem 2.4rem 0;
    cursor: pointer;
  }
  .my-preference-tabs {
    h2 {
      padding: 1.4rem 0 2rem 0;
    }
  }

  .my-preference-content {
    .branding-preference {
      h5 {
        padding-bottom: 1.6rem;
      }
      .preference-checkbox {
        .cobrand-custom-checkbox {
          min-width: 1.6rem;
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 0.4rem;
          border: 0.1rem solid $primary;
          padding: 0.1rem 0.05rem;
          background-color: $white;
          cursor: pointer;
          user-select: none;
          img {
            display: none;
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        p {
          padding-left: 0.6rem;
        }
      }
      h6 {
        padding: 1.6rem 0 1rem 0;
        max-width: 70.3rem;
      }

      .preferences-radio-btn {
        border: 1px solid $grey-ECE;
        background-color: $grey-ECE;
        border-radius: 0.8rem;
        max-width: 66.8rem;
        padding-left: 1.2rem;
        margin-bottom: 1.5rem;
      }
    }
    .my-preferences-co-branding-leabling {
      margin: 3rem 0;
      .start-label-frame {
        display: flex;
        gap: 0.8rem;
        margin-top: 1rem;
        border-radius: 0.8rem;
        background: $grey-8;
        padding: 1rem 1.2rem 1.5rem;
        .check-box-text-frame {
          width: 100%;
          .start-logo-frame {
            margin-top: 1.2rem;
            padding: 1.6rem 0 0;
            border-top: 0.1rem solid $grey-2;
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
          }
        }
      }
      .branding-color-language-frame {
        margin-top: 1.6rem;
        .dropwon-component-wrapper {
          margin-top: 0.8rem;
          margin-bottom: 4rem !important;
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1.2rem;
        }
      }
    }
  }
  .my-preference-btn-main {
    margin: 2rem 0 1rem 0;
    .primary-btn {
      border-radius: 0.8rem;
    }
  }
  .my-preference-tab-btn {
    margin: 2rem 0;

    .primary-btn {
      border-radius: 0.8rem;
    }
  }
}
