
.checkout-section {
    // background: linear-gradient(to right, $white 50%, $grey-ECE 50%);
    padding-top: 1rem;
}
.w-51 {
    width: 50%;
}
.checkout-frame {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding-bottom: 5rem;
    .billing-information {
        padding-right: 8rem;
        h4 {
            padding: 5rem 0 4rem;
        }
    }
    .order-details {
        // padding-left: 10%;
        // padding-top: 10rem;
        background-color: $grey-EBE;
        border-radius: .8rem;
        padding: 4rem 4.4rem;
        .billing-information {
            padding: 0;
            h4{
                padding: 0 0 4rem 0;
            }
        }
        .order-detail-ul {
            list-style: none;
            // padding: 4rem 0 0;
            // max-width: 34.6rem;
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1.6rem;
                .coupon-box {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: .6rem 1.2rem;
                    border-radius: 8px;
                    border: 1px solid $grey-ECE;
                    background: $white;
                    margin-top: 0.6rem;
                    .coupon-input {
                        border: none;
                        outline: none;
                        box-shadow: none;
                    }
                    button{
                        font-size: 1.4rem;
                        height: 3.2rem;
                        padding: .3rem 1.2rem .4rem 1.2rem !important;
                    }
                }
            }
        }
    }
}
.black-05 {
    color: $black-4;
}
.coupon-aplay {
    width: 100%;
    padding: 0.6rem 1.2rem;
    border-radius: .8rem;
    border: 1px dashed $green-A5F;
    background: $green-40;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.6rem;
}
.bg-ECE-desk{
    background-color: $grey-ECE;
}
@media (max-width:820px) {
    .w-51 {
        width: 100%;
    }
    .bg-ECE-desk{
        background-color: white;
    }
    .bg-ECE-mb{
        background-color: $grey-ECE;
        padding:0 1.6rem;
    }
    .checkout-section{
        padding-top: 2.4rem;
    }
    .checkout-frame {
        flex-wrap: wrap;
        .billing-information {
            padding: 2.4rem 0 6rem;
            h4 {
                padding: 0 0 2.4rem;
            }
        }
        .order-details {
            position: relative;
            padding: 2rem 0 4rem;
            &::before {
                position: absolute;
                bottom: -5rem;
                content: "";
                z-index: -1;
                left: -5.5%;
                width: 111%;
                height: 125%;
                background-color: $grey-ECE;
            }
            .confirm-pay-btn {
                position: absolute;
                bottom: -50px;
                left: -5.5%;
                background-color: white;
                padding: 1rem;
                width: 111%;
                margin-bottom: 0 !important;
                button {
                    margin: 0 auto !important;
                }
            }
         
        }
    }
  
}
