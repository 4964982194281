.signup-custom-model {
  .modal-content {
    background-color: $white;
    width: 100%;
    max-width: 56.8rem;
    margin: 6% auto auto;
    text-align: center;

    .signup-model-body {
      padding: 9.7rem 0 9.1rem !important;

      .close-btn {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        cursor: pointer;
      }

      .brand-logo {
        max-width: 23rem;
        width: 100%;
      }

      h5 {
        margin-top: 1.5rem;
        margin-bottom: 1.6rem;
      }

      .login-accound-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2.4rem;
        padding-top: 4rem;
        cursor: pointer;

        .continue-with-account {
          border-radius: 8px;
          width: 100%;
          max-width: 28rem;
          padding: 0.8rem 2rem;
          border: 0.1rem solid $black-4;
          background: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2rem;

          &:hover {
            background-color: $grey-EBE;
          }
        }
      }
    }
    .mysubscription-body-succes {
      padding: 3rem 0 !important;
      text-align: center;

      .close-btn {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        cursor: pointer;
      }

      .box-img {
        margin: 1.5rem auto 3rem auto;
        display: block;
      }

     .primary-btn{
      padding: 1rem 2.4rem;
      border-radius: 0.8rem;
      font-size: 1.4rem;
     }
    }
  }
}
.mysubscription-custom-succes {
  .modal-content {
    border-radius: 1.5rem !important;
    max-width: 87rem !important;
    margin-top: 8% !important;
  }
}
@media (max-width: 829px) {
  .signup-custom-model {
    .modal-content {
      margin: 20% auto auto;

      .signup-model-body {
        .close-btn {
        }

        .brand-logo {
        }

        h5 {
        }

        .login-accound-wrapper {
          .continue-with-account {
          }
        }
      }
    }
  }
}
