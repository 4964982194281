.personalization-wrapper {
  .personalization-tabs {
    padding-top: 2.4rem !important;
    gap: 5rem !important;
  }
  .templates-tab {
    margin-top: 2.4rem;
    .template-heading {
      margin-bottom: 3.2rem;
    }
    .template-search {
      display: flex;
      align-items: center;
      padding: 1.6rem 1rem !important;
      margin-bottom: 0.6rem !important;
      max-height: 4.4rem !important;
      text-align: center !important;
      box-sizing: border-box;
      margin-top: 0 !important;
    }
    .personalization-feeds-wrapper {
      // margin-bottom: 1.5rem;
      .Social-media-frame {
        border: 0.1rem solid $grey-2;
        border-radius: 0.8rem;
        padding: 0.6rem 1.2rem;
        gap: 0.8rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 0.8rem;
      }
      .active-feed {
        background-color: #abf0e2;
      }
    }
    .personalization-cards {
      margin-bottom: 2rem;
      .card-heading {
        margin: 3.2rem 0 0 0;
      }
      .personalization-cards-wrapper {
        margin-bottom: 0 !important;
        width: 100%;

        .personalization-card {
          max-width: 27rem;
          padding: 2.4rem 2rem 2.9rem 2rem;
          border-radius: 0.8rem;
          border: 1px solid black;
          margin-top: 1.6rem;
            height: 32.7rem;


          .card-image {
            margin-bottom: 2rem;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .thumbnail {
            width: 23rem ;
              height: 20.6rem;
            }
            .preview-image {
              position: absolute;
              bottom: 0.6rem;
              right: 0.6rem;
              cursor: pointer;
            }
          }
          .card-tag {
            padding: 0 0.8rem;
            border: 1px solid $grey-ECE;
            border-radius: 0.4rem;
            background-color: $grey-ECE;
            width: fit-content;
            margin-bottom: 0.8rem;
          }
          .text-ellipsis-one {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.has-extra-cards {
          overflow-x: scroll;
          display: flex;
          flex-wrap: nowrap;

          &::-webkit-scrollbar {
            display: none;
          }

          scrollbar-width: none;
        }
      }
    }
  }
}
.personalization-editor {
  width: 100%;
  .editor-details {
    margin: 1.8rem 0 1.2rem 0;
    .template-image{
      border-radius: 50%;
    }
    .editor-tags {
      padding: 0 0.8rem;
      border: 1px solid $grey-ECE;
      border-radius: 0.4rem;
      background-color: $grey-ECE;
      width: fit-content;
    }
    .buttons-container {
      .primary-btn {
        padding: 1rem 2.4rem !important;
        border-radius: 0.8rem !important;
        max-height: 4rem !important;
      }
    }
  }
  .canvas-container-wrapper {
    width: 100%;
    border-radius: 0.8rem;
    height: 50rem;
    border: 1px solid $grey-ECE;
    // background-color: $grey-ECE;
    margin-bottom: 5rem;
  }
}

@media (max-width: 820px) {
  .personalization-wrapper {
    .personalization-tabs {
      justify-content: start !important;
    }

    .templates-tab {
      .template-heading {
        margin-bottom: 2rem;
      }
      .template-search {
        padding: 1rem 1rem;
        max-height: 3.5rem !important;
      }
      .personalization-feeds-wrapper {
        display: flex !important;
        flex-wrap: wrap;
        width: 100% !important;
        overflow-x: hidden;
        gap: 1rem;

        .Social-media-frame {
          padding: 0.5rem 1.5rem !important;
          border-radius: 0.5rem;
          display: flex;
          flex-wrap: wrap !important;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .personalization-cards-wrapper {
        display: flex;
        justify-content: start;
        width: 100vw !important;
        .personalization-card {
       max-height: 20.5rem;


          padding: 1.5rem 1rem !important;
          border-radius: 0.8rem;
          margin-top: 1.6rem;
          .card-image {
            margin-bottom: 1rem !important;
            .thumbnail {
              width: 100% !important;
              height: 12rem !important;
            }
          }
        }
      }
    }
  }
  .personalization-editor {
    width: 100vw !important;
    .editor-details {
      max-width: 100%;
      margin: 1.2rem 0 1.2rem 0 !important;

      .buttons-container {
        .primary-btn {
          padding: 1rem 1.5rem !important;
        }
      }
    }
    .canvas-container-wrapper {
      max-width: 100% !important;
    }
  }
}
