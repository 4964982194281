//
// !====================== VIDEO DETAIS PAGE CSS START ===========================
.detail-wrapper {
    display: flex;
    justify-content: center;
    background-color: #242e3d33;
    // padding: 7.2rem 10rem;
    height: 60rem;
    border-radius: 12px;
    .infographic {
        height: 231.0932rem;
    }
    .minimalists {
        height: 97rem;
    }
    .presentation {
        width: 100% !important;
        max-width: 100vw;
        object-fit: contain;
        width: -moz-available;
        width: stretch;
        height: auto;
    }

}

.video-details-page {
    padding: 7.5rem 0 0;
}
.download-btn-container {
    display: none;
}
.display-b-n {
    display: block;
}
.download-btn {
    display: flex;
    padding: 6px 12px 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}


.video-detail-wrapper {
margin-bottom: 5rem;
    .video-profile-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-top: 1rem;
        width: 100%;
        .profile-detail {
            display: flex;
            align-items: start;
            justify-content: flex-start;
            gap: 1.3rem;
            width: 100%;
            .profile-img {
                width: 6rem;
                height: 6rem;
                border-radius: 50%;
            }
            .name-count-details {
                width: 100%;
                gap: 0.8px;
                display: flex;
                flex-direction: column;

                .count-detail-heading {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1.8rem;
                    color: $black-242;
                }
                p {
                    display: flex;
                    gap: 2.4rem;
                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 0.8rem;
                        h6 {
                            color: $black-242;
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }
    .filter-wrapper {
        display: flex;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            appearance: none;
            display: none;
        }
        gap: 2.4rem;
    }
    .video-trend {
        border-radius: 6px;
        border: 1px solid $primary;
        display: flex;
        padding: 6px 12px 6px 8px;
    }
    .video-detail-para {
        margin-top: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem .5rem;
        p {}
    }
    .trend-suggestions {
        margin-bottom: 2.9rem;
        margin-top: .7rem;
        .hashtag {
            gap: 0.3rem;
            img {
                width: 1.3rem;
            }
        }
    }
    .video-play-area {
        margin: .7rem 0 1.2rem 0;
        iframe {
            border-radius: 1.2rem;
            width: 100%;
            aspect-ratio: 2;
            html {
                body,
                footer,
                header {
                    display: none;
                }
            }
        }
    }
    .description-box-wrapper{
      margin-bottom: 1.2rem;
    }
    .common-all-card-wrapper {
        // justify-content: space-between;
    }
    .curated-sub-header {
        margin-bottom: 0.8rem;
        h5 {
            font-size: 3.2rem;
            line-height: 4rem;
            font-weight: 600;
            span {
                display: none;
            }
        }
    }
}
.amc-profile-img-mb{
    width: 3rem ;
    height: 3rem;
    object-fit: contain ;
}
.vist-btn {
    border-radius: .4rem;
    padding: .4rem;
    background: $grey-ECE;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    margin-bottom: 3rem;
    color: inherit;
    &:hover{
      color: inherit;
    }
}
.like-share-icons-wrapper {
    display: flex;
    gap: 2.4rem;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        cursor: pointer;
        h6 {
            color: $black-242;
            opacity: 0.6;
        }
    }
}
.mt-12 {
    margin-top: .6rem;
}
.details-duration {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}
.video-tag {
    border-radius: 0.4rem;
    h6 {
        color: $black-242;
    }
    background-color: $grey-ECE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.8rem;
}
.video-details-dropwon-component-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    // padding-top: 0.4rem;
}
.ellipsis-1 {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.view-more-des {
    white-space: nowrap;
}
.video-details-share-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    img{
        width: 2rem;
    }

}
// !====================== VIDEO DETAIS PAGE CSS END ===========================
//?================ DOWNLOAD MODEL CSS START ====================
.details-downolad-modal {
    width: 100%;
    max-width: 57.6rem;
    height: auto;
    flex-shrink: 0;
    border-radius: 1rem;
    border: 1px solid $grey-ECE;
    background: $white;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 50%);

    .header-download {
        margin-bottom: 2.1rem;
    }

    .modal-content {
        border: none;
        box-shadow: none;

        .model-download-body {
            padding: 0 0rem 2rem 0rem;
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
           .check-heading{
            padding-left: 1.8rem;
           }
           .compliance{
            padding-left: 1.8rem;

            margin-left: 1.8rem;
            max-width: 49.1rem;
           }
           .compliance-detail{
            padding-left: 1.8rem;

            margin-left: 1.8rem;
            max-width: 49.1rem;
            margin-top: 0.8rem;
           }

            .with-cobrand-download {
                border-radius: 5px;
                background: $green-5DF;
                padding: 0.8rem 1.8rem;
                // margin-bottom: 1.6rem;
                cursor: pointer;
                margin-top: 2.4rem;
                // width: fit-content;
            }

            .with-download {
                border-radius: 5px;
                padding: 0.8rem 1.8rem;
                border: 1px solid $grey-ECE;
                // margin-bottom: 1.6rem;
                cursor: pointer;
                margin-right: 0.8rem;
                margin-top: 2.4rem;
                // width: 26.9rem;
                // width:fit-content;
                max-height: 4rem;
                margin-left: 1.8rem;


            }

            .disabled-Co-Brand-download {
                background: $grey-CED;
                cursor: not-allowed;
                width: fit-content;


            }
        }
    }
}
@media (max-width: 820px) {
    .details-downolad-modal {
      width: 100%;
      height: auto;
      max-width: 34rem;
      padding-right: 0 !important;

       top: 0;
        // transform: translate(-50%, 50%);

        .header-download {
            margin-bottom: 2.1rem;
        }

        .modal-content {
            border: none;
            box-shadow: none;

            .model-download-body {
                padding: 0 0 2rem 0;
                display: flex;
                align-items: start;
                justify-content: start;
                flex-direction: column;
               .check-heading{
                padding-left:0rem;
               }
               .compliance{
                padding-left: 0;

                margin-left: 1.8rem;

               }
               .compliance-detail{
                padding-left:0;

                margin-left: 1.8rem;
                // max-width: 49.1rem;
                margin-top: 0.8rem;
               }
   .compliance-btns{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    width: 28rem;
   }
                .with-cobrand-download {
                    border-radius: 5px;
                    background: $green-5DF;
                    padding: 1rem 2.4rem;
                    // margin-bottom: 1.6rem;
                    cursor: pointer;
                    margin-top: 2rem;
                    margin-right: 2rem;
                    width: 100% !important;
                }

                .with-download {
                    border-radius: 5px;
                    padding: 1rem 2.4rem;
                    border: 1px solid $grey-ECE;
                    // margin-bottom: 1.6rem;
                    cursor: pointer;
                    // margin-right: 0.5rem;
                    margin-top: 2rem;
                    margin-right: 2rem;

                    // width: 26.9rem;
                    width:100% !important;
                    // max-height: 4rem;
                    margin-left: 0;


                }

                .disabled-Co-Brand-download {
                    background: $grey-CED;
                    cursor: not-allowed;
                    width: fit-content;


                }
            }
        }
    }

}

.carousel-main-slider-frame{
    border-radius: 1.3rem;
    background: $grey-ECE;
    // display: flex;
    // align-items: center;
    .carousel-slider-images{
        width: 100%;
        height: 466px;
        object-fit: contain;
    }
    .carousel-control-prev{
        background: linear-gradient(to right, rgba(12, 12, 12, 1) 0%, rgba(12, 12, 12, 0.2) 40%, transparent 100%);
    }
    .carousel-control-next{
        background: linear-gradient(to left, rgba(12, 12, 12, 1) 0%, rgba(12, 12, 12, 0.2) 40%, transparent 100%);
    }

}

//?================ DOWNLOAD MODEL CSS END ====================
@media (max-width: 1100px) {}
@media (max-width: 820px) {
    .mb-w15{
        width: 1.5rem !important;
    }
    .mb-w14{
        width: 1.4rem !important;
    }
    .mb-w9{
        width: 0.9rem !important;
    }

    .video-details-page {
        padding: 7.2rem 0 0;
    }
    .download-btn {
        width: 100%;
    }
    .display-b-n {
        display: none;
    }
    .like-share-icons-wrapper {
        gap: 0.8rem;
        align-items: flex-start;
        .desk-show {
            display: none !important;
        }
        span {
            margin-right: 0;
            gap: 0.3rem;
            h6 {
                font-weight: 500 !important;
            }
            img {
                height: 1.8rem ;
                width: 1.8rem ;
            }
        }
    }
    .video-details-share-icon{
        gap: .3rem;
        img{
            width: 1.3rem;
        }

    }
    .video-details-dropwon-component-wrapper{
        flex-wrap: wrap;
    }
    .video-detail-wrapper {

        .video-profile-details {
            padding-top: 0;
            .profile-detail {
                gap: 1.3rem;
                width: 100%;

                .name-count-details {
                    gap: 1.2rem;
                    .count-detail-heading {
                        flex-direction: column-reverse;
                        align-items: flex-start;
                        gap: 1.2rem;
                    }
                    p {
                        gap: 0.8rem;
                        align-items: center;
                        .desk-show {
                            display: none !important;
                        }
                        span {
                            margin-right: 0;
                            gap: 0.3rem;
                            h6 {
                                font-weight: 500 !important;
                            }
                            img {
                                height: 1.8rem !important;
                                width: 1.8rem !important;
                            }
                        }
                    }
                }
            }
            button {
                display: none !important;
            }
        }
        .filter-wrapper {
            &::-webkit-scrollbar {
                appearance: none;
                display: none;
            }
            gap: 2.4rem;
        }
        .video-trend-suggestion {
            gap: 0.8rem;
        }
        .video-detail-para {
            margin-top: 1.4rem;
            gap: .7rem;
            // height: 5.4rem;
            // overflow: hidden;
            h3 {
                // height: 5.4rem;
                overflow: hidden;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.8rem;
            }
            p {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.8rem;
            }
        }
        .trend-suggestions {
            margin-bottom: 2.4rem;
        }
        .video-play-area {
            .filter-trend {
                font-size: 1rem;
            }
            margin: 1.6rem 0;
            iframe {
                aspect-ratio: 1.5;
                min-height: 25rem;
            }
        }
        .common-all-card-wrapper {
            justify-content: space-between;
        }
        .video-play-area iframe {
            height: auto;
        }
    }
    .curated-sub-header {
        margin-bottom: 0;
        h5 {
            font-size: 20px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 29px !important;
            span {
                // display: none;
            }
        }
    }

    .common-all-card-wrapper {
        padding: 2.4rem 0 !important;
    }
    .download-btn-container {
        display: block;
        padding: 1rem 5%;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: $white;
        border: none !important;
        outline: none;
        z-index: 3;
    }
    @-moz-document url-prefix() {
        .download-btn-container {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
    .carousel-main-slider-frame{
        border-radius: 1.3rem;
        background: $grey-ECE;
        .carousel-slider-images{
            width: 100%;
            height: 250px;
            object-fit: cover;
        }


    }

}
@media (max-width:360px) {
    .common-all-card-wrapper {
        gap: 1rem;
    }
}



//styling for usage alert modal

.usage-alert-modal{

    max-width: 55.6rem;



    .modal-content{
        width: fit-content;
    padding: 1.6rem 2rem 2.4rem 2rem;

        border: none;
        box-shadow: none;
    }

    .alert-heading{
        padding: 0 0 2.4rem 0 ;
.alert-heading-text{
    color: $red-D72;


}
    }

.alert-text{
    max-width: 52.2rem;
    span{
        text-decoration: underline;

    }
}


}