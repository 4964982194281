.my-50 {
    // margin-top: 5rem;
    margin-bottom: 6rem;
}
.social-media-filter {
    padding: 2rem 0 3rem;
}
.sponsored-slider-frame {
    padding: 1.2rem 0 2.4rem;
    background: $grey-ECE;
    box-shadow: 0 0.4rem 0.4rem 0 $black-3;
    margin-bottom: 4rem;
}
.social-media-posts-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: clamp(10px, 5vw, 2.5rem);
}
.social-media-card-wrapper{
    padding-bottom:5rem ;
}
// ?============= SOCIAL MEDIA POST CARDS START =====================
.social-media-post-cards {
    width: 37rem;
    border: 0.1rem solid #242e3d33;
    background-color: $white;
    border-radius: 1.2rem;
    overflow: hidden;
    position: relative;
    margin-bottom: 1rem;
    &:hover{
        cursor: pointer;
        box-shadow: 0 0 1.4rem 0 $black-3;
    }
    .post-user {
        position: absolute;
        top: 1.5rem;
        right: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 1.2rem;
        img {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            object-fit: contain;
        }
    }
    .post-image {
        width: 100%;
        height: 17.2rem;
        object-fit: cover;
        margin-bottom: 1rem;
    }

}
.img-cover{
    width: 100%;
    height: 17.2rem;
}
.social-media-card-height{
    height: 48.3rem;
    width: 37rem;
    border-radius: 1.2rem;
}
.post-content-wrapper {
    width: 100%;
    h4{
        padding: 1rem 1.6rem 0 1.6rem;
    }
    .count-wrapper {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        overflow-x: auto;
        gap: 3.6rem;
        margin: 1.2rem 1.6rem;
        // padding: 0 1.6rem;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar{
            display: none;
            appearance:none;
        }
        .social-media-type-states{
            // min-width: 6rem;
            // border-right: .1rem solid $grey-ECE;
            position: relative;

            &::after{
                position: absolute;
                content: "";
                right: -1.8rem;
                top: .5rem;
                width: .1rem;
                height: 3rem;
                background-color: $grey-ECE;
            }
            &:last-child{
                &::after{
                    display: none;
                }
            }
        }

    }

}
.gap-25{
    gap: 2.5rem;
}
// ?============= SOCIAL MEDIA POST CARDS END =====================
@media (max-width:820px) {
    .my-50 {
        // margin-top: 2.9rem;
        margin-bottom: 2rem;
    }
    .social-media-filter {
        padding: 1.2rem 0 2.8rem;
    }
    .social-media-posts-wrapper {
        gap: 2rem;
    }
    .social-media-card-wrapper{
        padding-bottom:4rem ;
    }
    .img-cover{
        height: 18.5rem;
    }
    // ?============= SOCIAL MEDIA POST CARDS START =====================
    .social-media-post-cards {
        width: 33.6rem;//change
        border-radius: .48rem;//ch
        margin-bottom: 0rem;//change
        .post-user {
            top: .4rem;//ch
            right: 1rem;//ch
            gap: 2rem;//cnage
            img {
                width: 1.6rem;//ch
                height: 1.6rem;//ch
            }
        }
        .post-image {
            height: 18rem;//ch
            margin-bottom: .4rem;//ch
        }

    }
    .social-media-card-height{
        width: 15.17rem;//change
        border-radius: .48rem;//ch
        height: 22.3rem;

    }
    .post-content-wrapper {
        .count-wrapper {
            gap: 2rem;//ch
            margin: .4rem 0;//ch
            overflow: hidden;
        }
    }
    .social-media-type-states{
        // min-width: 6rem;
        // border-right: .1rem solid $grey-ECE;
        position: relative;

        &::after{
            position: absolute;
            content: "";
            right: -0.5rem;
            top: .5rem;
            width: .1rem;
            height: 3rem;
            background-color: $grey-ECE;
        }
        &:last-child{
            &::after{
                display: none;
            }
        }
    }
    // ?============= SOCIAL MEDIA POST CARDS END =====================

}


@media (max-width:500px) {
    .social-media-posts-wrapper{
        justify-content: center !important;
        gap: 1.5rem 5% ;
        .social-media-post-cards{
            max-width: 33.6rem;//change
            // width:46%;
            .post-image{

            }
        }
    }

}
