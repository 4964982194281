// =========================MY COLLECTION COMMON COMPONENT CSS START ==========================

.mycollection-wrapper {
  // margin: 15.1rem 0 0 0;
  // padding: 0 13.5rem 0 13.5rem;
  // margin-bottom: 6rem;
  .mycollection-body {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
 
  }
}
.folder-card {
    position: relative;
    text-align: center;
    color: $white;
    margin-bottom: 3rem;
    width: 27rem;
    height: 21.9rem;
    .three-dot {
        position: absolute;
        bottom: 12px;
        right: 12px;
        padding: 1rem;
    }
    .collection-main-dropdown{

    }
    .collection-dropdown-show{
        padding: 1rem;
        border-radius: .8rem;
        overflow: hidden;
        background-color: $white;
        a,.collection-share-wrapper-btn{
            padding: .5rem;
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1.2rem;
            img{
             width: 2rem;
            }
        }
    }
    .date-created {
        width: 20rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-items: center;
        align-items: flex-start;
        gap: 0.8rem;
        flex-direction: column;
        p {
            color: $grey-999;
        }
        h2{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .add-more {
      width: 20rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-items: center;
      align-items: flex-start;
      gap: 3.2rem;
      // flex-direction: column;
      h2 {
          width: 12.2rem;
          text-align: start;
      }
  }
}
// =========================MY COLLECTION COMMON COMPONENT CSS END ==========================


@media (max-width:1100px) {

}

@media (max-width:820px) {
 // =========================MY COLLECTION COMMON COMPONENT CSS START==========================
 .mycollection-wrapper {
  // padding: 0 2rem 0 2rem;
  margin-bottom: 3rem;


}
.folder-card {
    margin-bottom: 0 !important;
    width: 150px;
    height: 121.812px;
      img {
          width: 150px;
          height: 121.812px;
      }
      .date-created {
          width: 13.1rem !important;
          h2 {
              text-align: start;
              -webkit-line-clamp: 1 !important;

          }
      }
      .three-dot {
          bottom: 6px !important;
          right: 0 !important;
          img {
              width: 2.4rem;
              height: 2.4rem;
          }
      }
      .add-more {
        width: 13rem !important;
        gap: 1.2rem !important;
        img {
            width: 41px;
            height: 32px;
        }
    }
  }

// =========================MY COLLECTION COMMON COMPONENT CSS END==========================

}