.tolls-card-wrapper {
  padding: 2rem 0;
  display: flex;
  align-items: stretch;
  gap: 1.6rem;
  flex-wrap: wrap;
  // justify-content: space-around;
}

.toolsCardWrapper {
  border: 1px solid #ececec;
  border-radius: 1rem;
  cursor: pointer;
  width: 45%;
  .image-frame {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
    }
  }
  .content-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 1rem;
  }
}

// ========================== CALCULATOR PAGE CSS START =======================
.tools-calculator-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tools-sip-info {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.2rem;
    .tools-sip-name-frame {
      text-align: end;
    }
    .tools-sip-logo {
      width: 4.4rem;
      height: 4.4rem;
      border-radius: 50%;
      overflow: hidden;
      // border: 1px solid red;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.mix-blind-burn {
  mix-blend-mode: color-burn;
}
.tools-personalization-branding-box {
  background-color: $grey-ECE;
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin: 3.6rem 0;
  position: relative;
  h3 {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0.6rem 0;
    img {
      width: 2.4rem;
      height: 2.4rem;
    }
    .tools-up-arrow {
      width: 1.8rem;
      height: 1.8rem;
      border-top: 0.3rem solid $black-242;
      border-left: 0.3rem solid $black-242;
      transform: rotate(45deg);
      margin-right: 1rem;
    }
  }
  .coming-soon {
    position: absolute;
    z-index: 1;
    width: 100%;
    text-align: center;
    height: 80%;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .personalize-branding-content {
    display: flex;
    // align-items: center;
    gap: 2.6rem;
    padding-top: 1rem;
    // filter: blur(4px);
    .investor-details-frame {
      width: 50%;
    }
    .add-branding-frame {
      width: 50%;
    }
  }
}
.br-grey2 {
  border-color: $grey-2 !important;
  width: 100%;
  max-width: 100%;
}
.bg-gray {
  background-color: $grey-EEE;
}
.input-box-dropdown {
  .drop-down-icon {
    width: 18px;
  }
}
.dropdown-input {
  border: none;
  outline: none;
  width: 100%;
  &::placeholder {
    color: $black-242;
    opacity: 0.7;
  }
}
.input-radio {
  accent-color: $primary;
}
.calculator-result-main-wrapper {
  display: flex;
  gap: 2.6rem;
  padding: 3rem 0;
  .calculator-component-frame {
    width: 50%;
  }
  .result-graph-frame {
    width: 50%;
    h4 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .result-download-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        span {
          cursor: pointer;
          width: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $grey-2;
          border-radius: 0.8rem;
        }
      }
    }
  }
}
.w-49 {
  width: 49%;
}
.w-auto {
  width: auto;
}
.w-100 {
  width: 100%;
}
.result-graph-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
  padding: 1rem 0;
  .graph-1-frame {
    background-color: $grey-ECE;
    padding: 1rem 0;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.graph-1-frame {
  background-color: $grey-ECE;
  padding: 1rem 0;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.make-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.make-none {
  height: 1px;
  overflow: hidden;
}
// ========================== CALCULATOR PAGE CSS END =======================
.tools-calulator-output-screenshot-frame {
  width: 100%;
  max-width: 70rem;
  padding: 2rem 4rem;
  min-width: 70rem;

  // visibility: hidden;
}

.tools-calculator-output {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  // gap: 1.8rem;
  // padding-bottom: 3rem;
  flex-wrap: nowrap !important;
  height: 10rem;
  // border: 1px solid black;
  .startlabeling-size {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    flex: 50%;
    img {
      width: 15rem;
    }
  }
  .branding-details-box {
    display: flex;
    // align-items: center;
    justify-content: center;
    gap: 1.8rem;
    // height: 10rem;
    flex: 50%;
  }
}

@media (max-width: 825px) {
  .tolls-card-wrapper {
    // justify-content: space-around;
    .toolsCardWrapper {
      width: 47%;
    }
  }
  .tools-calculator-sub-header {
    flex-wrap: wrap;
    justify-content: start;
    gap: 2rem;
    padding-top: 1rem;
    h5 {
      font-weight: 600 !important;
    }
    .tools-sip-info {
      width: 100%;
      justify-content: start;
      flex-direction: row-reverse;
      .tools-sip-name-frame {
        text-align: start !important;
      }
    }
  }
  .tools-personalization-branding-box {
    h3 {
      img {
      }
    }
    .personalize-branding-content {
      flex-wrap: wrap;
      .investor-details-frame {
        width: 100%;
      }
      .add-branding-frame {
        width: 100%;
        .brand-select-text-wrapper {
          flex-wrap: wrap;
          padding: 1.2rem 0;
          input {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 1020px) {
  // !===================== SECOND RANGE AND GRAPH START =================
  .calculator-result-main-wrapper {
    flex-wrap: wrap;
    .calculator-component-frame {
      width: 100%;
    }
    .result-graph-frame {
      width: 100%;
    }
  }
  // !===================== SECOND RANGE AND GRAPH END =================
  .result-graph-wrapper {
    flex-wrap: wrap;
    .graph-1-frame {
      //  width: 100%;
    }
  }
}

// todo============== Range css start
.range-slider {
  .range-input-frame {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    padding: 1.2rem 0;
    gap: 1.9rem;
    .range-lable-frame {
      width: 100%;
      h3 {
        margin-bottom: 1rem;
      }
      .input-custom-range {
        // background: linear-gradient(to right, #000000 0%, #82CFD0 40%, #fff 40%, #fff 100%);
        transition: background 450ms ease-in;
        width: 100%;
        margin: 0;
        border-radius: 4px;
        // background: transparent;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        &:focus {
          outline: none;
        }

        &::-webkit-slider-runnable-track {
          //   background: #ddd;
          height: 6px;
          border-radius: 3px;
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background: #270089;
          margin-top: -9px; // This should be half of (thumb height - track height)
          position: relative; // Required for z-index to take effect
          z-index: 2; // Ensures the thumb is above the pseudo-element
        }

        // Filled part of the slider (for WebKit-based browsers)
        // &:focus::-webkit-slider-runnable-track {
        //   background: linear-gradient(to right, blue 0%, blue 50%, #ddd 50%, #ddd 100%);
        // }

        // Mozilla-based browsers
        &::-moz-range-track {
          //   background: #ddd;
          height: 6px;
          border-radius: 3px;
        }

        &::-moz-range-progress {
          //   background: $grey-2;
          height: 6px;
          border-radius: 3px;
        }

        &::-moz-range-thumb {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background: #270089;
          border: none;
        }

        // Microsoft-based browsers (Optional: for Internet Explorer/Edge)
        &::-ms-track {
          //   background: #ddd;
          height: 6px;
          border-radius: 3px;
          border-color: transparent;
          color: transparent;
        }

        &::-ms-fill-lower {
          background: $grey-2;
          border-radius: 3px;
        }

        &::-ms-fill-upper {
          //   background: #ddd;
          border-radius: 3px;
        }

        &::-ms-thumb {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background: #270089;
          border: none;
          top: 0;
          box-shadow: none;
        }
      }
      input[type="range"] {
        -moz-appearance: none;
        /* Disable default styling */
        width: 100%;
        height: 6px;
        /* Set the height of the overall range input */
      }

      &::-moz-range-track {
        background: #ddd;
        height: 6px;
        /* Set the height */
        border-radius: 3px;
      }

      &::-moz-range-thumb {
        -moz-appearance: none;
        /* Disable default thumb styling */
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #270089;
        position: relative;
        z-index: 2;
        margin-top: -9px;
        /* Align thumb with the track */
      }
    }
    .input-show-text {
      border: 1px solid #242e3d33;
      border-radius: 5px;
      cursor: default;
      margin-top: 0.8rem;
      width: 100%;
      max-width: 13rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 1rem;

      .amount-display {
        padding: 8px 10px;
        width: 100%;
        text-align: right;
        cursor: default;
        outline: none;
        border: none;
        -moz-appearance: textfield;
        margin: 0;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
          display: none;
        }
      }
      &.number {
        background-color: $grey-ECE;
        input {
          background-color: $grey-ECE;
        }
      }
    }
    &.number {
      align-items: baseline;
    }
  }
}

.min-max-frame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 0;
}
