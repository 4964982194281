// // =================== COMON CSS START ==================

// ========================= PROFILE CREATED COMMON COMPONENT CSS START ==========================
//
//
.modal-header {
  border-bottom: none !important;
  padding: 0.6rem 0.6rem 0 0 !important;
}

.modal-dialog {
  max-width: 97rem !important;

  .modal-body {
    padding: 0 11.6rem;

    .profile-created-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        margin-top: 6.2rem;
      }

      p {
        margin-top: 3.2rem;
        margin-bottom: 7.1rem;
      }

      button {
        margin-bottom: 10.2rem;
      }
    }
  }
}

// =========================SUB HEADER COMMON COMPONENT CSS START==========================
.sub-header-wrapper {
  margin: 15.1rem 0 0;
  // padding: 0 13.5rem 0 13.5rem;
  margin-bottom: 6rem;

  .mycollection-header {
    display: flex;

    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem !important;

    .mycollection-left {
      display: flex;
      min-width: 26.9rem;
      justify-content: space-between !important;
      align-items: baseline;

      h4 {
        display: block;
        white-space: nowrap;
        padding-left: 1.5rem !important;
      }

      h2 {
        white-space: nowrap;

      }
    }


    .mycollection-right {
      display: flex;
      width: 37rem;
      height: 4rem;
      padding: 0.5rem 1.1rem;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      border-radius: 5px;
      border: 1px solid $primary;

      input {
        width: 31.4rem;
        height: 2.8rem;
        border: none;
        outline: none;
        background: none;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

// =========================SUB HEADER COMMON COMPONENT CSS END ==========================

// ========================= MY COMPONENT COMMON COMPONENT CSS START ==========================

// ========================= PROFILE STATUS COMMON COMPONENT CSS END ==========================

// =================== HOME PAGE CSS START ===============
//
.home-page {
  padding: 0;
}

.hearo-section {
  background: $primary;

  // height:100%;
  // max-height: 692px;
  // min-height: 500px;
  .hero-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15rem 0 8rem;

    h4 {
      margin-bottom: 1.4rem;
      text-align: center;
    }

    h3 {
      margin-bottom: 4rem;
      text-align: center;
      // font-size: 4.5rem;
      // line-height: 52px;
    }
  }
}

.mb-8 {
  margin-bottom: 0.8rem;
}

.treanding-one-wrapper {
  padding: 2rem 0 2rem;
  max-width: 970px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  // &::-webkit-scrollbar{
  //     height: 20px;
  //     cursor: pointer;
  //    appearance: none;
  // //    display:none;
  // }
  // &::-webkit-scrollbar-thumb{
  //     background-image: linear-gradient(top, white 10%, white 90%); cursor: pointer;
  //     color: red;
  // }
  .lato-bold {
    white-space: nowrap;
    margin-right: 2rem;
  }

  .trending-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    width: 100%;
    overflow-x: auto;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      appearance: none;
      -moz-appearance: none;
      display: none;
    }

    .key-right-slider {
      position: absolute;
      right: 0;
      top: 1px;
      width: 24px;
      height: 24px;
      background-color: $white;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

// ------------------------- FEATURE SECTION CSS START -----------------------
.feature-section {
  padding: 5.2rem 0 7.2rem 0;
  background-color: $white;
  position: relative;

  .feature-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    overflow-x: scroll;
    gap: 3rem;
    cursor: pointer;
    padding: 0 2rem;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      height: 0.1px;
      appearance: none;
      -webkit-appearance: none;
      display: none;
    }
  }
}

.right-arrow {
  visibility: hidden;
  position: absolute;
  top: 50%;
  right: 5%;
  padding: 10px;
  cursor: pointer;
  transform: translateY(-50%);
}

// = FEATURE BOX START
.feature-box {
  width: 27rem;
  min-width: 15rem;
  margin: 0 1.2rem;
  height: 15.2rem;
  min-height: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.8rem;

  .feature-image {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .black-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $black-1;

    &:hover {
      background-color: $black-2;
    }
  }
}

// = FEATURE BOX END
// ------------------------- FEATURE SECTION CSS END -------------------------
//
// ?----------------------- ONLINE PRESENCE CSS START -----------------------
.home-explort-all {
  text-decoration-line: underline;
  position: absolute;
  right: 13rem;
  top: 0.5rem;
  -webkit-user-select: none;
  cursor: pointer;
}

.h-350 {
  height: 100vh;
  max-height: 36.2rem;
  overflow: hidden;
}

.online-presence-section {
  padding: 5.7rem 0 8rem;

  h4 {
    margin-bottom: 1.1rem;
  }

  .social-media-card-wrapper {
    margin: 3.1rem 0 0;
    padding-bottom: 0;

    // display: flex;
    // align-items: center;
    // justify-content: start;
    // overflow-x: scroll;
    // gap: 3%;
    //   overflow-x: scroll;
    .targeted-content-slick-slider {

      .target-imag-frame,
      .target-img-wrapper,
      img {
        border-radius: 0.8rem;
        width: 15vw;
        max-width: 28rem;
        min-width: 26.5rem;
        margin: 0 .6rem;

        &:hover {
          cursor: pointer;
          // box-shadow: 0 0 1.4rem 0 $black-3;
        }

        img {
          width: 100%;
          background-color: $blue-0C5;
        }
      }
      .target-imag-frame{
        display: flex !important;
        align-items: center;
      }
      .target-img-wrapper {
        img {
          // box-shadow: 0 0 1.4rem 0 $black-3;
        }
      }
      .target-imag-frame-t {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 0.8rem;
        width: 15vw;
        max-width: 28rem;
        min-width: 26.5rem;
        margin: 0 .8rem !important;
        text-align: center !important;


        &:nth-child(3n) {
          background-color: $black-242;
          color: $white;
          text-align: center;
          padding: 0 1.5rem;
        }

        &:nth-child(3n + 1) {
          background-color: $primary;
          color: $white;
          text-align: center !important;
          padding: 0 1.5rem;


        }

        &:nth-child(3n + 2) {
          background-color: $green-5DF;
          color: $primary;
          text-align: center;
          padding: 0 1.5rem;
        }
      }
    }

    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   appearance: none;
    //   display: none;
    // }
  }
}

.social-media-card-frame {
  margin: 3.1rem 0 0;
  padding-bottom: 0;

  .slick-list {
    .slick-track {
      .slick-slide {
        .social-image-frame {
          .rambo-image-wrapper {
            width: 26rem;
            height: 39.5rem;
          }
        }

        &:nth-child(3n) {
          .social-image-frame {
            .rambo-image-wrapper {
              background-color: $black-242;
              color: $white;
              text-align: center;
              padding: 0 1.5rem;
            }
          }
        }

        &:nth-child(3n + 1) {
          .social-image-frame {
            .rambo-image-wrapper {
              background-color: $primary;
              color: $white;
              text-align: center;
              padding: 0 1.5rem;
            }
          }
        }

        &:nth-child(3n + 2) {
          .social-image-frame {
            .rambo-image-wrapper {
              background-color: $green-5DF;
              color: $primary;
              text-align: center;
              padding: 0 1.5rem;
            }
          }
        }
      }
    }
  }

  .social-image-frame {
    margin: 0 1.4rem;

    .rambo-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-dots {
    // bottom: -4.7rem;

    li {
      margin: 0 1px;
    }
  }

  .social-image {
    border-radius: 0.8rem;
    width: 18.5vw;
    max-width: 27rem;
    // margin: 0 .5rem;
    height: 100%;
    object-fit: cover;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 1.4rem 0 $black-3;
    }
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    appearance: none;
    display: none;
  }

  .slick-arrow {
    display: none !important;
  }

  .slider-right-arrow {
    position: absolute;
    right: -10%;
  }

  .slider-left-arrow {
    position: absolute;
    left: -9%;
  }
}

// ?----------------------- ONLINE PRESENCE CSS END -----------------------
//
//
//
// ?------------------------------ MOST POWERFUL INTELIGENT SERCH ENGIN  CSS START --------------------------
.most-powerfull-engin {
  padding: 6.2rem 0 5.1rem;
  background: rgba(20, 20, 20, 0.9) url("../../images/serch_engin_bg.png") no-repeat center;
  // mix-blend-mode: overlay;
  background-size: cover;

  // h5{
  //     margin-bottom: 1rem;
  // }
  .engin-btn-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 5.2rem;
    gap: 1.6rem;

    .engin-btn {
      width: 17.9rem;
      padding: 2.9rem 2.5rem;
      border-radius: 1rem;
      text-align: center;
      border: 0.1rem solid $white;
      background: $grey-4;
    }
  }
}

// ?------------------------------ MOST POWERFUL INTELIGENT SERCH ENGIN  CSS END --------------------------
//
//
//
// ?------------------------------------- WHY FINPEDIA SUBSCRIBE CSS START ----------------------
.register-to-section {
  padding: 5.3rem 0 6rem;
  background-color: $white;

  h5 {
    margin-bottom: 1.1rem;
  }

  .registet-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3rem 0;
    gap: 2.2rem;
    flex-wrap: wrap;
  }
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.registerto-card-wrapper {
  width: 100%;
  max-width: 33rem;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  img {
    max-width: 11.2rem;
    width: 100%;
  }

  p {
    text-align: start;
    display: -webkit-box;
    // -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.why-premium-section {
  padding: 6.2rem 0;
  box-shadow: 0 -5px 5px $grey-2;
  background-color: $grey-F9F;

  h5 {
    margin-bottom: 1.1rem;
  }

  .premium-plans-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3.2rem 0;
    gap: 3rem;
  }
}

.plans-card-wrapper {
  padding: 2.6rem 2.2%;
  width: 100%;
  max-width: 32rem;
  text-align: center;

  img {
    margin-bottom: 1.2rem;
  }

  &.coming-soon {
    filter: blur(3px);
    cursor: not-allowed;
    position: relative;
  }
}

.coming-soon-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 1;
}

// ?------------------------------------- WHY FINPEDIA SUBSCRIBE CSS END   ----------------------
//
//
// ?-------------------------------- WHATSAPP COMMUNITY CSS START --------------------
.join-community-section {
  background-color: $green-5DF;

  .community-wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
    padding-bottom: 14rem;

    .whatsapp-community {
      width: 56vw;
      max-width: 64.8rem;

      h3 {
        padding: 8.5rem 0 3.2rem;
      }

      .primary-btn {
        margin: 3.2rem 0 6rem;
      }
    }

    .whats-app-image {
      img {
        width: 28vw;
        max-width: 38rem;
        position: absolute;
        top: 4.6rem;
        right: 0;
      }
    }
  }
}

// ?-------------------------------- WHATSAPP COMMUNITY CSS END --------------------
// .home-content-type-slider {
//   .slick-dots {
//     bottom: -4.7rem;

//     li {
//     }
//   }
// }


//! =================== HOME PAGE CSS END ===============
@media (max-width: 1100px) {
  .container {
    padding: 0 5% !important;
  }

  .ft-28-18 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .ft-57-32 {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  // =================== COMON CSS START ==================
  .desk-show {
    display: none;
  }

  .tab-show {
    display: block;
  }

  .tb-full {
    width: 100% !important;
  }

  // =================== COMON CSS START ==================
  // ?----------------------- ONLINE PRESENCE CSS START -----------------------
  .online-presence-section {
    .social-media-card-wrapper {
      padding: 0 1rem 5rem;
      // justify-content: start;
      // scrollbar-width: none;
      // -ms-overflow-style: none;
      // &::-webkit-scrollbar {
      //   display: none;
      //   appearance: none;
      // }

      .target-imag-frame,
      .target-img-wrapper,
      img {
        width: 100%;
        // min-width: 150px;
      }
    }

    .social-media-card-frame {
      padding-bottom: 5rem;

      .social-image-frame {
        // margin: 0 1.3rem;
        margin: 0 1.1rem 0 1.2rem;

        .social-image {
          width: 100%;
          max-width: 150px;
        }
      }
    }

    // ?----------------------- ONLINE PRESENCE CSS START -----------------------
  }

  // !======================== SUBSCRIPTION PAGE END ====================

  .up-your-game {
    padding: 6rem 0 0;

    .your-game-card-wrapper {
      flex-wrap: wrap;
      gap: 2rem;
      padding: 4.1rem 0 7.1rem;

      .plan-card {
        width: 100%;
        max-width: 100%;
        height: 8.9rem;
        overflow: hidden;
      }
    }
  }

  // !======================== SUBSCRIPTION PAGE END ====================
  //
  //
  //
  // !======================= USER FEED PAGE START END ====================
  //
}

@media (max-width: 820px) {
  .container-mb {
    padding: 0 !important;
  }

  .h-350 {
    // max-height: 19.4rem;
  }

  // todo: ========= FONT SICE RESPONSIVE -----------------------
  // =================== HOME PAGE CSS START ===============
  .home-page {
    padding: 0;
  }

  .home-explort-all {
    right: 0;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: -2.5rem;
  }

  .treanding-one-wrapper {
    width: 100%;
    padding-top: 1.7rem;
    overflow: hidden;
    overflow-x: scroll;
    justify-content: start;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      appearance: none;
      display: none;
    }

    .trending-one {
      font-size: 1.4rem;
      line-height: 2rem;

      img {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .hero-content {
    padding: 9.4rem 0 5rem !important;

    h4 {
      font-size: 24px;
      line-height: 37.795px;
    }

    h3 {
      // font-size: 32px;
      // line-height: 36px;
    }
  }

  // ------------------------- FEATURE SECTION CSS START -----------------------
  .right-arrow {
    visibility: visible !important;
  }

  .feature-section {
    padding: 2.8rem 0;
    overflow: hidden;

    .feature-box-wrapper {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1.5rem;
      padding: 0 5%;

    }

  }

  // = FEATURE BOX START
  .feature-box {
    width: 15rem;
    height: 10rem;

    // margin: 0 .6rem;
    .feature-image {
      object-fit: cover;
    }
  }

  .online-presence-section {
    padding: 3.5rem 0 5rem;

    .social-media-card-wrapper {

      // justify-content: start;
      // overflow: hidden;
      // overflow-x: scroll;
      // padding: 0 5% 1.5rem;
      .target-imag-frame,
      .target-img-wrapper,
      img {
        width: 100%;
        min-width: 150px;
        object-fit: cover;
      }
    }
  }

  .home-content-type-slider {
    height: 15rem;
    .slick-dots {
      bottom: -0.7rem;
      li {
        margin: 0 -3px;
      }
    }
  }

  // ?------------------------------ MOST POWERFUL INTELIGENT SERCH ENGIN  CSS START --------------------------
  .most-powerfull-engin {
    padding: 2.9rem 0;

    .engin-btn-wrapper {
      padding-top: 3rem;
      gap: 1.6rem;

      .engin-btn {
        width: 15rem;
        border-radius: 0.8rem;
        padding: 0.6rem 2.5rem;
      }
    }
  }

  // ?------------------------------ MOST POWERFUL INTELIGENT SERCH ENGIN  CSS END --------------------------
  //
  //
  //
  // ?------------------------------------- WHY FINPEDIA SUBSCRIBE CSS START ----------------------
  .register-to-section {
    padding: 4rem 0;

    .registet-wrapper {
      justify-content: center;
      padding: 2rem 0;
      gap: 1.2rem;
    }
  }

  .mb-32 {
    margin-bottom: 2rem;
  }

  .registerto-card-wrapper {
    justify-content: start;
    padding: 0.2rem 1rem;

    img {
      max-width: 6rem;
    }
  }

  .why-premium-section {
    padding: 4rem 0;

    h5 {
      margin-bottom: 1rem;
    }

    .premium-plans-wrapper {
      padding: 2rem 0 1rem;
      gap: 2rem;
    }
  }

  .plans-card-wrapper {
    padding: 0.1rem 2.2%;
    text-align: center;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: start;

    p {
      text-align: start;
    }

    img {
      max-width: 6rem;
      margin-bottom: 1rem;
    }
  }

  // ?------------------------------------- WHY FINPEDIA SUBSCRIBE CSS END   ----------------------
  //
  //
  //
  // ?-------------------------------- WHATSAPP COMMUNITY CSS START --------------------
  .join-community-section {
    .community-wrapper {
      padding-bottom: 0;

      .whatsapp-community {
        width: 100vw;
        text-align: center;

        h3 {
          text-align: start;
        }

        .mb-whatsapp-img-wrapper {
          display: flex;
          justify-content: space-between;

          p {
            width: 75%;
            text-align: start;
          }

          .mb-wp-img {
            width: 5.9rem;
          }
        }
      }

      .whats-app-image {
        img {
          display: none;
        }
      }
    }
  }

  // ?-------------------------------- WHATSAPP COMMUNITY CSS END --------------------
  //
  //

  //
  // =================== HOME PAGE CSS END ===============
  // ========================= NIKHIL PROFILE CREATED COMMON COMPONENT CSS START ==========================

  .modal-dialog {
    .modal-body {
      padding: 0 2.4rem !important;

      .profile-created-content {
        width: 100%;

        .profile-created-description {
          width: 100%;

          h2 {
            margin-top: 5rem !important;
          }

          p {
            width: 22.8rem;
            margin: 2.4rem 0 !important;
          }

          button {
            margin-bottom: 6.2rem !important;
            padding: 0.5rem 1.6rem;
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  // ========================= NIKHIL PROFILE CREATED COMMON COMPONENT CSS END ==========================
  //
  // ========================= NIKHIL PROFILE STATUS COMMON COMPONENT CSS END ==========================

  .profile-status-description {
    width: 100%;

    .heading {
      margin: 1.8rem 0 1.2rem !important;
    }

    .complete {
      margin: 2.8rem 0 2rem !important;
    }

    button {
      margin: 2.8rem 0 5rem !important;
      padding: 0.5rem 1.6rem;
      font-size: 1.4rem;
    }

    .progress {
      margin-bottom: 2.8rem !important;
    }
  }

  // =========================SUB HEADER COMMON COMPONENT CSS START==========================
  .sub-header-wrapper {
    margin: 8.6rem 0 0;
    padding: 0;
    margin-bottom: 3.2rem;

    .mycollection-header {
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      /* margin-bottom: 6rem; */
      flex-wrap: wrap;
      gap: 2rem;
      margin-bottom: 1rem !important;

      .mycollection-left {
        h2 {
          font-weight: 400 !important;
        }

        h4 {
          display: none;
        }
      }

      .mycollection-right {
        width: 32rem;
        height: 4.8rem;
        padding: 0.5rem 1.1rem;
        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        border: 1px solid $grey-C4C;
      }
    }
  }

  // =========================SUB HEADER COMMON COMPONENT CSS END==========================
}