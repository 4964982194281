$primary: #270089;
$primary-01: rgba(39, 0, 137, 0.1);
$primary-02: rgba(39, 0, 137, 0.2);

$primary-05: rgba(39, 0, 137, 0.05);
$blue-0C5: #0c54b6;
$blue-DED: #DED8ED;
$blue-270: #ebe5fb26;
$secondary: #f6f6f6; // USE AS BACKGROUND COLOR

$white: #ffffff;
$white-E1E: #e1e4e8;
$black: #000000;
$red :#D72630;
$red-1: rgba(215, 38, 48, 0.1);

$grey-1: rgba(61, 79, 87, 1);
$grey-2: rgba(36, 46, 61, 0.2);
$grey-3: rgb(110, 109, 109);
$grey-4: rgba(128, 124, 124, 0.25);
$grey-6: rgba(192, 180, 180, 0.5);
$grey-5: rgb(193, 193, 193);
$grey-6: rgba(36, 46, 61, 0.1);
$grey-7: rgba(36, 46, 61, 0.6);
$grey-8: rgba(236, 236, 236, 0.5);
$grey-9: #e8eaed;
$grey-10: #79747E;
$grey-11: #f5f5f563;
$grey-12: rgba(36, 46, 61, 0.25);
$grey-13: rgba(36, 46, 61, 0.35);
$grey-17: rgba(36, 46, 61, 0.5);
$grey-18: rgba(36, 46, 61, 0.6);
$grey-19: rgba(236, 236, 236, 0.5);


$grey-DDD: #dddddd;
$grey-D9D: #d9d9d9;
$grey-F1F: #f1f1f1;
$grey-F9F: #f9f9f9;
$grey-F5F: #f5f5f5;
$grey-757: #757575;
$grey-EBE: #ebebeb;
$grey-929: #929292;
$grey-919: #919191;
$grey-C4C: #c4c4c4;
$grey-CED: #ced4da;
$grey-CCC: #cccccc;
$grey-ECE: #ececec;
$grey-EEE: #eeeeee;
$grey-E6E: #e6e0e9;
$grey-799: #79747e;
$grey-999: #999999;
$grey-CDC: #cdcdcd;
$grey-C9D: #d9d9d9;
$grey-131: #13171e;
$grey-151: #515151;
$grey-919: #91969e;
$green-4CA: #4cae4c;
$green-9AB: #9ab740;
$green-A5F: #A5F5DC;
$green-ABF: #abf0e2;
$green-8EC: #8ec44a;
$green-A5F: #A5F5DC;
$red-D43: #d43f3a;
$red-DC3: #dc3545;
$red-EF0: #ef0048;
$red-FF3: #ff3535;
$red-D72: #D72630;
$pink-CC0: #cc0199;
$green-5DF: #5dfdcb;
$green-A5F-5: rgba(165, 245, 220,.5);
$black-707: #707070;
$black-797: #79747e;
$yellow-FFD: #ffd600;
$yello-EDC: #edc600;
$black-495: #49454f;
$black-252: #252f3e;
$black-242: #242e3d;
$black-1D1: #1D192B;

$black-242-05: rgba(36, 46, 61, 0.6);
$black-1: rgba(36, 46, 61, 0.8);
$black-2: rgba(36, 46, 61, 0.9);
$black-3: rgba(0, 0, 0, 0.25);
$black-4: rgba(36, 46, 61, 0.5);
$black-5: rgba(12, 12, 12, 0.7);
$black-6: rgba(0, 0, 0, 0.15);
$black-7: rgba(0, 0, 0, 0.3);
$black-8: rgba(236, 236, 236, 0);
$black-9: rgba(117, 117, 117, 0.1);
$black-10: rgba(117, 117, 117, 0.8);
$black-11: rgba(36, 46, 61, 0.22);
$black-12: rgba(36, 46, 61, 0.5);
$black-12: rgba(36, 46, 61, 1);


$grey-1D1: #1d1b20;
$grey-DCD: #dcdcdd;

$green-40: rgba(165, 245, 220, 0.4);
$green-10: rgba(165, 245, 220, 1);
$grey-15: rgba(29, 27, 32, 0.12);
$grey-16: rgba(36, 46, 61, 0.5);



