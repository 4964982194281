.pointer-none {
  cursor: not-allowed;
}

.filter-sort-frame {
  display: flex;
  align-items: start;
  position: relative;
  justify-content: space-between;
  gap: 1.4rem;
  padding: 2.6rem 0 6.3rem;

  .filter-button {
    display: flex;
    padding: 0.6rem 0.8rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
    border-radius: 0.8rem;
    border: 0.1rem solid $grey-799;
    position: relative;

    .filter-count {
      position: absolute;
      background-color: $green-5DF;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      font-weight: 400;
      right: -1rem;
      top: -1rem;
      color: $black-242;
      // background-color: #5DFDCB;
    }
  }

  .filter-wrapper {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      appearance: none;
      display: none;
    }

    gap: 2.4rem;
  }

  .targeted-filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 88%;

    .target-item-dropdown {
      margin-right: 1.2rem;
      margin-bottom: 0.6rem;
      // margin-top: 0.1rem;
    }
  }

  .sorted-by {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    min-width: 9rem;
    position: absolute;
    right: 0;
    background-color: $white;
  }
}

//EVINT FILTER CSS START

.filter-open-warpper {
  display: flex;
  align-items: start;
  justify-content: start;
}

.filter-btn-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 36.8rem;
  width: 27%;
}

.single-select-api {
  width: max-content;
  max-width: 27.2rem;
  height: 3.2rem;

  .dropdown-btn {
    height: 100%;
    border: 0.1rem solid $grey-799 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 0.8rem;
    overflow: hidden;
    padding: 0.45rem 0.8rem;
    width: 100%;

    h6 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }
  }

  .dropdown-menu {
    // top: .1rem !important;
    min-width: 21rem;
    margin-top: 0.3rem !important;
    max-height: 30rem;
    overflow: auto;
    padding-top: 0.9rem;
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    background-color: $white;
    color: $black-242;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow:
      0 2px 6px 2px $black-6,
      0 1px 2px 0 $black-7;

    a {
      width: 100%;
      padding: 0.4rem 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 4px;
      appearance: none;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $white;
    }

    &::-webkit-scrollbar-track-piece {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.3rem;
      background-color: $grey-CCC;
    }
  }
}
.single-select-api-time {
  width: max-content;
  max-width: 3.2rem;
  // max-height: 1.2rem;
  // z-index: 1 !important;

  .dropdown-btn {
    // height: 100%;
    border-bottom: 0.1rem solid $grey-799 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    overflow: hidden;
    width: 100%;
    border-radius: 0;
    /* Ensuring no border-radius */

    h6 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }

    /* Remove down arrow for select input */
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: none;
      border: none;
      padding-right: 0;
      /* Remove padding if any for arrow space */
    }

    select::-ms-expand {
      display: none;
      /* For Internet Explorer */
    }
  }

  .dropdown-menu {
    min-width: 4.2rem;
    margin-top: 0.3rem !important;
    max-height: 17rem;
    z-index: 9999 !important;
    overflow: auto;
    width: 100%;
    border: none;
    border-bottom: 0.1rem solid $grey-799;
    /* Only border bottom */
    background-color: $white;
    color: $black-242;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow:
      0 2px 6px 2px $black-6,
      0 1px 2px 0 $black-7;

    a {
      width: 100%;
      padding: 0.4rem 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 4px;
      appearance: none;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $white;
    }

    &::-webkit-scrollbar-track-piece {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.3rem;
      background-color: $grey-CCC;
    }
  }
}

.w-37 {
  width: 34%;
}

.add-isMultiple-option-frame,
.clear-filter-wrapper {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-right: 2rem;
}

.add-isMultiple-option-frame {
  padding: 1.3rem 2rem 1.3rem 0;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clear-filter-btn {
  padding: 0.1rem 1.6rem 0.4rem;
  border-radius: 0.8rem;
  // height: 3.2rem;

  border: 0.1rem solid $grey-757;
  cursor: pointer;
  &:hover {
    background-color: $grey-ECE;
  }
}

.filter-title {
  display: none;
}

.filter-side-bar {
  width: 34%;
  // max-width: 36.9rem;
  padding-right: 5rem;
  transition: all 0.4s;
  overflow: hidden;

  .accordion {
    &:last-child {
      .card {
        border: none;
      }
    }

    .Customization {
      &.card {
        .collapse {
          .card-body {
            padding-top: 0;
          }
        }
      }
    }
  }

  .card {
    border: none;
    border-bottom: 1px solid $grey-2;

    .card-header {
      border: none;
      background: $white;
      // width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.3rem 0.02rem;
      cursor: pointer;

      // position: relative;
      // border-bottom: 1px solid $grey-2;
      img {
        width: 2.4rem;
        aspect-ratio: 1/1;
        object-fit: cover;
      }

      h6 {
        .filter-indicator {
          width: 0.8rem;
          height: 0.8rem;
          display: inline-block;
          margin: 0 0 0.5rem 1rem;
          border-radius: 50%;
          background-color: $primary;
        }
      }
    }

    .card-body {
      width: 100%;
      height: 100%;
      max-height: 27rem;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      padding-left: 0;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        appearance: none;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $grey-9;
      }

      span {
        color: $black-4;
        height: 3.2rem;
        border-radius: 0.8rem;
        border: 1px solid $black-4;
        padding: 0.6rem 1.6rem 0.8rem;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background: $grey-ECE;
          color: $black-242;
        }
      }

      .multi-icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .filter-premium-card {
    border-bottom: none;

    .card-body {
      span {
        color: $black-242;
        border: 1px solid $black-242;
        color: $black-242;
      }
    }
  }
}

.active-bg {
  background: $green-A5F;
  color: $black-242 !important;
  &:hover {
    background: $green-A5F !important;
    color: $black-242 !important;
  }
}

.show-filter {
  width: 0;
  padding-right: 0;
  transition: all 0.1s;
}

.width-half {
  width: 66%;
}

.width-full {
  width: 100%;
}

.customization-color-box {
  width: 100%;

  .color-toggle-btn {
    border-radius: 10rem;
    border: 0.2rem solid $black-797;
    background: $grey-E6E;
    width: 5.2rem;
    height: 3.2rem;
    padding: 0.42rem 0.5rem;

    .color-toggle-thumb {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      cursor: pointer;
      background-color: $black-797;
    }
  }
  #color:checked ~ {
    .color-toggle-btn {
      .color-toggle-thumb {
        float: right;
      }
    }
  }
}

.filter-color-frame {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2rem;

  .filter-multi-colors {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.6rem;

    .filter-colors {
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 50%;
      // position: relative;
      cursor: pointer;
      border: 2px solid $white;
      &:nth-child(3) {
        // outline: 2px solid $primary;
        // &::after {
        //     content: "";
        //     position: absolute;
        //     bottom: -1rem;
        //     left: 0;
        //     width: 100%;
        //     height: 2px;
        //     background-color: $primary;
        // }
      }
    }

    .color-outline {
      outline-width: 2px;
      outline-style: solid;
    }
  }
}

.gap-6 {
  gap: 0.6rem !important;
}

.multi-color-outline {
  border-top: 2px solid green;
  border-right: 2px solid red;
  border-bottom: 2px solid blue;
  border-left: 2px solid orange;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

//
//
//
//
// ?============================ welcome-custom-model css start ==================
.welcome-custom-model {
  .modal-dialog {
    max-width: 99rem !important;
  }

  .modal-content {
    margin-top: 12%;
    padding: 4.7rem 8rem;
    max-width: 110rem;
    position: relative;
    border-radius: 1.5rem;

    .close-btn {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
    }

    .welcome-model-body {
      .progress {
        border-radius: 20rem;
        height: 2rem;
        margin: 1.3rem 0;

        .progress-bar {
          border-radius: 20rem;
          background-color: $primary;
          // height: 2rem;
        }
      }
    }
  }
}

.welcome-video-tag {
  width: 80%;
  aspect-ratio: 2;
  // height: 80%;
  border-radius: 2rem;
  margin: auto;
}

// ?============================ welcome-custom-model css end ==================
//
// ?===================== breadcrumb-wrapper start ==================


.breadcrumb-wrapper {
  padding: 0 0 1rem;

  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;

    .breadcrumb-item {
      opacity: 0.6;
      // width: 100%;

      white-space: nowrap;

      a {
        width: 100%;
        color: $black-242;
        padding-right: 1rem;
      }
      &:last-child {
        opacity: 1;
        cursor: auto;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          cursor: auto;
        }
      }
      &:first-child {
        a {
          padding-right: 0;
        }

        &::before {
          content: "";
        }
      }

      &::before {
        content: ">";
        align-self: baseline;
      }
    }
  }
}

// ?===================== breadcrumb-wrapper end ==================
//
//
//
//
//
.sort-targeted-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sort-in-filter-dropdown {
  position: absolute;
  top: 2.2rem;
  right: 0;
  z-index: 5;
}

.targatedConten-toggal-btn {
  top: 2.2rem;
  position: absolute;
  z-index: 5;
  right: 170px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.4rem;

  .targate-toggal-btn-frame {
    width: 3.2rem;
    height: 2rem;
    border-radius: 10rem;
    border: 2px solid $primary;
    background-color: $grey-ECE;
    padding: 4px;
    display: flex;
    align-items: center;

    .disk-toggal-btn {
      width: 1.2rem;
      height: 1.2rem;
      background-color: $primary;
      border-radius: 50%;
      margin: 0;
      cursor: pointer;
    }
  }
}

//
#targateChecked:checked ~ {
  .targate-toggal-btn-frame {
    justify-content: end;
    border: 2px solid $grey-757;
    .disk-toggal-btn {
      background-color: $primary;
    }
  }
}
.creator-amc-list {
  overflow-y: auto;
  max-height: 25rem;
  position: relative;

  .amc-title {
    position: sticky;
    top: 0;
    left: 0;
    background-color: $white;
  }
}
//

//
//
//
//
//
@media (max-width: 1360px) and (min-width: 820px) {
  .width-half {
    transition: all 0.4s;
    padding-top: 3rem;
  }
  .on-top-1 {
    top: 1rem !important;
    transition: all 0.4s;
  }
}
@media (max-width: 820px) {
  .filter-sort-frame {
    padding: 1.6rem 0 2.3rem;
    align-items: start;

    .filter-button {
      border-radius: 0;
      border: none;

      background-color: $white;
      border-right: 1px solid $black-252;
    }

    .filter-wrapper {
      gap: 1rem;
    }

    .sorted-by {
    }
  }

  // side bar filter
  .filter-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0;
  }
  .sort-targeted-wrapper {
    padding-top: 0.8rem;
  }
  .sort-in-filter-dropdown {
    position: static;
  }
  .targatedConten-toggal-btn {
    position: static;
    p {
      font-size: 12px !important;
    }
  }
  .filter-side-bar {
    width: 100%;
    height: 100vh;
    position: fixed;
    // min-width: 100vh;
    overflow: auto;
    left: 0;
    top: 6rem;
    background-color: white;
    z-index: 6;
    padding: 0 5% 6rem;

    .card {
      .card-header {
        width: 100%;
      }

      .card-body {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        padding-left: 0;

        span {
          &:hover {
            background-color: inherit;
            /* Set to default background color */
            color: initial;
          }
        }
      }
    }
  }

  .single-select-api {
    max-width: 13rem;
  }

  .show-filter {
    left: -100%;
    transition: all 0.4s;
  }

  .width-half {
    width: 100%;
  }

  .sticky-bottom {
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: $white;
    padding: 1rem 0;
  }
  .sticky-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    padding: 1.2rem 2rem;
    // padding: 1rem 0 ;
    outline: none;
    border: none;
    box-shadow: 0px -2px 10px -4px #242E3D38;



  }
  .fixed-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    padding: 1.2rem 2rem;
    outline: none;
    border: none;
    box-shadow: 0px -2px 10px -4px #242E3D38;



  }


  .main-filter-frame {
    flex-wrap: wrap;
    padding: 0rem 0 0;
  }

  .targeted-filter-wrapper {
    order: 1;
    width: auto !important;
  }

  // ?============================ welcome-custom-model css start ==================
  .welcome-custom-model {
    .modal-content {
      padding: 5rem 2.4rem;

      .welcome-model-body {
        padding: 0 !important;

        .progress {
          border-radius: 12rem;
          height: 1.5rem;
          margin: 1.3rem 0;

          .progress-bar {
            border-radius: 12rem;
            background-color: $primary;
            // height: 2rem;
          }
        }
      }
    }
  }

  // ?============================ welcome-custom-model css end ==================
  //
  //
  // ?===================== breadcrumb-wrapper start ==================
  .breadCrumb-pr {
    padding-right: 1.5rem;
  }

  .breadcrumb-wrapper {
    padding-top: 1rem;
    .breadcrumb {
      .breadcrumb-item {
        // padding-right: 1.5rem;

        a {
          padding-right: 0;
          min-width: 5.6rem;
        }
        &:last-child {
          opacity: 1;
        }
        &:first-child {
          padding-right: 0;
        }
        &:nth-child(3) {
          max-width: 14rem;
          padding-right: 0.5rem;
          width: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          a {
            width: auto ;
          }
        }
      }
    }
  }

  .detail-page-breadcrumb {
    .breadcrumb {
      .breadcrumb-item {
        &:nth-child(3) {
          max-width: 100%;
          padding-right: 0;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .collection-page-breadcrumb {
    .breadcrumb {
      .breadcrumb-item {
        &:nth-child(2) {
          max-width: 100%;
          padding-right: 0;
          // width: fit-content;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .add-isMultiple-option-frame {
    padding: 1.3rem 0;

    .clear-filter-btn {
      &:hover {
        background-color: white;
      }
    }
  }

  // ?===================== breadcrumb-wrapper end ==================
  //
}

.react-datepicker-popper {
  z-index: 5;
}

.form-control {
  // @include font($white, "lato-regular", 1.6rem, 400, 18px);
  height: 3.2rem;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "lato-regular";
  border: 0.1rem solid $grey-799 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.8rem;
  overflow: hidden;
  padding: 0.45rem 0.8rem;
  width: 100%;
  outline: none;
}
.form-control-date {
  // @include font($white, "lato-regular", 1.6rem, 400, 18px);
  height: 3rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: "lato-regular";
  border: 0.1rem solid $grey-799 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.4rem;
  overflow: hidden;
  padding: 0.45rem 0.8rem;
  width: 100%;
  outline: none;
  text-align: center;
}
.form-control-modal {
  // @include font($white, "lato-regular", 1.6rem, 400, 18px);
  height: 3.2rem;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "lato-regular";
  border: 1px solid $grey-2;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.8rem;
  overflow: hidden;
  padding: 0.45rem 0.8rem;
  width: 100%;
  outline: none;
}

@media (max-width: 820px) {
  .form-control-date {
    // @include font($white, "lato-regular", 1.6rem, 400, 18px);
    height: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "lato-regular";
    border: 0.1rem solid $grey-799 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 0.3rem 0.3rem;
    width: 100%;
    outline: none;
    text-align: center;
  }
}
