
.notification-text{
    margin-bottom: 4rem;

}
.main-notification-tab-page-frame{
  position: relative;


}
.content-event-main-notification {
    background-color:rgba(236, 236, 236, 0.5);
    margin: 24px 0;
    border-radius: 8px;
    padding: 1rem;
    // margin-top: 7rem;
    position: relative;
    z-index: 1;
    // width: 100%;
    // top: 41px;
    .toggle-btn {
        height: 20px;
        width: 33px;
        border-radius: 100px;
        margin-right: 15.5px;
        border: 2px solid $grey-799;
        display: inline-flex;

        .toggle-circle {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            color: $grey-799;
        }

    }
    .n-active {
        // .toggle-btn{
        justify-content: end;
        transition: all 0.4s;
        border: 2px solid $primary;
        .toggle-circle {
            background-color: $primary;
        }
        // }

    }
    h4 {
        color: $black-242;
        margin: 12px 0;

        .icons-style {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }

}
.border-ECE{
    border: 1px solid $grey-ECE;
}
.notification-main{
    background-color:rgba(236, 236, 236, 0.5);
    margin: 24px 0;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 7rem;
    h5 {
        color: $black-242-05;
    }





    .pills-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: calc(100%-32px);
        overflow: auto;
        padding-bottom: 8px;
        .notification-pills {
            margin: 3px;
            color: $black-4;
            height: 3.2rem;
            border-radius: 0.8rem;
            border: 1px solid $black-4;
            padding: 0.6rem 1.6rem 0.8rem;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            text-overflow: ellipsis;
            // overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
                background: $grey-ECE;
                color: $black-242;
            }
            &.active {
                background: $green-A5F;
                color: $black-242;

            }
            position: relative;
            .targated-value-removed {
                position: absolute;
                right: -4px;
                top: -4px;
                width: 18px;
                height: 18px;
                border: 1px solid $black-4;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                padding: .1px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.notification-type {
    margin-top: 0;
    margin-bottom: 16px;
}
.content-notification-main {
    display: flex;
    flex-direction: column;
    .primary-btn {
        align-self: flex-end;
        margin-bottom: 1rem;
        border-radius: 0.8rem;
        cursor: pointer;
    }
    .primary-btn.disabled,
    .primary-btn:disabled {
        background-color: $grey-CCC;
        border: $grey-799;
        cursor: not-allowed;
        pointer-events: none;
    }
}


