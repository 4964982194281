.dropdown-main-wrapper {
    // padding: 1.2rem .01rem;
    // width: 100%;
    max-width: 15.1rem;
    position: relative;
    margin-left: auto;

}
.dropdown-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.2rem;
    padding: .1rem .7rem;
    cursor: pointer;
    // z-index: -1 !important;
    background-color: $white;
    // border-bottom: 1px solid $grey-2;
    border: none ;
    outline: none ;
    box-shadow: none ;
    background-color: $white !important;
    &::after{
        display: none;
    }
    &:hover,&:focus,&:active{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: $white;
    }
    img {
        width: 2.4rem;
        aspect-ratio: 1/1;
        object-fit: cover;
    }

}
.show-filter-dropdown {
    // display: none;
    list-style: none;
    position: absolute;
    right: 0;
    top: 3rem;
    min-width: 13rem;
    border-radius: 5px;
    background: $white;
    padding: .9rem 0;
    border: none  ;
    background-color: $white;
    color: $black-242 ;
    z-index: 6;
    box-shadow: 0 2px 6px 2px $black-6, 0 1px 2px 0 $black-7;

    a{
        // font-size:1.2rem;
        // line-height: 18px;
        width: 100%;
        padding: .3rem 2.2rem;

    }
}
.filter-dropdown:checked~ {
    .show-filter-dropdown {
        display: block;
    }
}

@media (max-width:820px) {
    .dropdown-btn{

        z-index: 999 !important;
    }
}