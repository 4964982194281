.content-result-page{
  // padding: 13.4rem 0 0;
  background-color: $white;
}
// .result-wrap{
//   overflow-x: unset;
//   flex-wrap: wrap;
//   justify-content:start;
//   .common-card-box{
//     width: 45%;
//   }
// }

.socialMedias-main-header{
  padding-bottom: 2.4rem;
}

// ============== BANER CSS START ================
.banner-main-wrapper{
  margin:2rem 0 5rem 0;
  width: 100%;
  height: 19rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit:cover;
  }
}
// ============== BANER CSS END ================




@media (max-width:1100px) {

}

@media (max-width:820px) {
  .result-wrap{
    overflow-x: unset;
    flex-wrap: wrap;
    gap: 2rem;
    .common-card-box{
      width: 45%;
    }
  }
}