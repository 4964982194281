// ======================= HEADER CSS START ==============
.main-header {
    width: 100%;
    z-index: 1111;
    position: fixed;
    top: 0;
    left: 0;
    background: $primary;

    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;

        .header-ul {
            list-style: none;
            transition: all 0.6s;

            label {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6.4rem;
                margin: 0;

                a {
                    color: inherit;
                }

                li {
                    cursor: pointer;
                }

                .header-profile-dropdown {
                    .show-profile-dropdown {
                        // padding: 0.5rem 1.5rem;
                        padding: 1rem 0;

                        a {
                            @include font($black-242, "Lato-Regular", 1.8rem, 400, 28px);
                            // padding: 0.8rem 1rem 0.8rem 0;
                            padding: 1rem 2rem;
                            opacity: 1;
                        }
                    }
                }
            }
        }

    }
}

// ======================= HEADER CSS END ==============
//
//
.header-logo{
    width: 100%;
    max-width: 21.9rem;
    max-height: 4.8rem;
}
.header-mblogo{
    width: 100%;
    max-width: 16rem;
    max-height: 3.5rem;

}
.header-profile-icon {
    outline: none;
    border: none;

    box-shadow: none;

    &::after {
        display: none;
    }

    &:focus {
        box-shadow: none !important;
    }

}

.show-profile-dropdown {
    padding: 1rem 2rem;

    .dropdown-item {
        padding: .9rem 0rem .6rem 0;
        border-bottom: .05rem solid rgba(36, 36, 36, .4);

        &:last-child {
            border-bottom: none;
        }
    }
}

.mb-fixed-user {

    .notification-header-icon {
        margin-left: 3rem;
    }
}


.gap-12 {
    gap: 1.2rem;
}

.notification-sub-header {
    h4 {
        border-bottom: 1px solid $black-242;
    }
}

.notification-keys {
    border-radius: .8rem;
    border: .1rem solid $grey-2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    padding: .3rem 1.2rem;
}

.active-noti-key {
    background: $green-ABF;
}
.at-hight{
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
// .notificaton-card-wrapper {
//     border-radius: .9rem;
//     overflow: hidden;

//     .notification-main-card {
//         display: flex;
//         background-color: $white;
//         align-items: center;
//         padding: .5rem 1rem;

//         .notifi-disk {
//             visibility: hidden;
//         }

//         .img-wrapper {
//             width: 7rem;
//             min-width: 7rem;
//             height: 7rem;
//             margin: 0 1rem;
//             overflow: hidden;
//             border-radius: 50%;
//             background-color: $blue-270;

//             img {
//                 object-fit: contain;
//                 border-radius: 50%;
//                 width: 100%;
//                 height: 100%;
//             }
//         }

//         .notification-content {
//             height: 100%;
//             width: 90%;

//             .w-90 {
//                 width: 90%;
//             }

//             .title-header {
//                 // padding-bottom: 1.6rem;

//                 #dropdown-basic {
//                     outline: none;
//                     box-shadow: none;

//                     &:active,
//                     &:focus {
//                         border: none;
//                         outline: none;
//                     }
//                 }
//             }

//             .footer-content {

//                 .view-btn {
//                     min-width: 12rem;
//                     padding: .6rem 2.4rem;
//                     border: 1px solid $grey-10;
//                     border-radius: 1.2rem;
//                     background-color: $white;
//                 }
//             }
//         }

//     }

//     .active {
//         // background-color: $grey-11;
//         background-color: $blue-270;

//         .notifi-disk {
//             visibility: visible;
//             background-color: $primary;
//             min-width: .8rem;
//             height: .8rem;
//             border-radius: 50%;
//             margin-left: .6rem;
//         }
//     }
// }
.notificaton-card-wrapper{
    .active{
        background: $blue-270;
    }
}
.notification-main-card {
    display: flex;
    align-items: center;
    background-color: $blue-270;
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid $grey-EEE;

    .notificaton-tick-img-frame {
        position: relative;
        width: 12%;
        min-width: 13rem;
        padding-left: 3rem;

        .notifi-disk {
            width: .8rem;
            height: .8rem;
            border-radius: 50%;
            background-color: $primary;
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }

        .img-wrapper {
            width: 7rem;
            height: 7rem;
            // border-radius: 50%;
            background-color: $grey-F5F;

            img {
                width: 100%;
                height: 100%;
                // border-radius: 50%;
                overflow: hidden;
                object-fit: contain;
            }

        }

    }

    .notification-content {
        width: calc(88% - 8rem);

        .description-view {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            a{
                span{

                }
            }

        }
        .footer-content{
            padding-top: .8rem;
        }

    }
}

@media (max-width:1100px) {}

@media (max-width:820px) {

    // ======================= HEADER CSS START ==============

    .main-header {
        z-index: 10;

        .header-wrapper {
            padding: 1.8rem 0;

            .header-ul {
                position: fixed;
                z-index: 9999;
                top: 6rem;
                left: -100%;
                width: 100%;
                height: 100vh;
                list-style: none;
                background-color: $white;
                padding: 5%;

                label {
                    gap: 0;
                    flex-direction: column;
                    justify-content: start;

                    li {
                        width: 100%;
                        cursor: pointer;
                        padding: 2.4rem 0;
                        border-bottom: 0.1rem solid $black-242;
                        color: $black-242;

                        &:last-child {
                            border: none;

                            .secondary-btn {
                                background: $primary !important;
                                color: $white !important;
                                width: 14.9rem;
                            }
                        }
                    }
                }

            }

            .menu-bar {
                display: block;
                width: 2.4rem;
                aspect-ratio: 1/1;
                height: 2.4rem;
            }

            #so:checked~.header-ul {
                left: 0;
                transition: all 0.6s;
            }

            #so:checked~.nemue-lable {
                .cancel-menu {
                    display: block;
                }

                .menu-bar {
                    display: none;
                }
            }

        }
    }

    .mb-fixed-user {
        position: fixed;
        top: 0;
        right: 5%;
        width: auto !important;
        padding: 1.5rem 0 !important;

        .notification-header-icon {
            // display: none;
            margin-left: 1rem;
        }
    }

    // .notificaton-card-wrapper {

    //     .notification-main-card {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         padding: 1.6rem 0rem 1.6rem .3rem;

    //         .notifi-disk {
    //             visibility: hidden;
    //         }

    //         .img-wrapper {
    //             width: 9rem;
    //             max-height: 6.8rem;
    //             margin: 0 1rem 0 .5rem;

    //             img {
    //                 width: 100%;

    //             }

    //         }

    //         .notification-content {
    //             width: 100%;
    //             height: 100%;

    //             .w-90 {
    //                 width: 90%;
    //             }

    //             .title-header {
    //                 padding-bottom: 1.6rem;
    //             }

    //             .footer-content {
    //                 padding-top: 0rem;
    //                 padding-right: 1rem;

    //                 .view-btn {
    //                     padding: .2rem 2.4rem;
    //                     border: 1px solid $grey-10;
    //                     border-radius: 1.2rem;
    //                     background-color: $white;
    //                     font-size: 1.2rem !important;
    //                 }
    //             }
    //         }

    //     }

    //     .active {
    //         .notifi-disk {
    //             min-width: .8rem;
    //             height: .8rem;
    //             margin-left: .3rem;
    //             visibility: visible;
    //         }
    //     }
    // }
    .notification-main-card {
        display: flex;
        align-items: center;
        background-color: $grey-11;
        padding: 1rem;
        width: 100%;

        .notificaton-tick-img-frame {
            min-width: 8rem;
            padding-left: 2.5rem;
            .notifi-disk {
                width: .6rem;
                height: .6rem;
            }
            .img-wrapper {
                width: 4.3rem;
                height: 4.3rem;

                img {
                }

            }

        }

        .notification-content {
            width: calc(88% - 5rem);

            .description-view {

                p {

                }
                a{
                    span{

                    }
                }

            }
            .footer-content{
                padding-top: .5rem;
            }

        }
    }
    // ======================= HEADER CSS END ==============

}