// !======================= MY SUBSCRIPTION PAGE START ==================
.mySubscription-page {
  // padding: 13rem 0 0;
  padding-top: 7rem;
}
.my-subscription-buttons {
  .secondary-btn {
    padding: 1rem 3.8rem;
    border-radius: 0.8rem;
    font-size: 1.4rem;
  }
}

.cancel-plan-btn {
  border: 1px solid $primary;
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 17rem;
  width: 100%;
  margin-top: 1rem;
}
.min-w-150 {
  min-width: 15rem;
}
.mySubscription-wrapper {
  h5 {
    margin-bottom: 4rem;
  }

  .subscription-details {
    padding: 1.6rem 0;
    // position: absolute;
    width: 100%;
    left: 0;
    right: 0;

    .plan-update-wrapper {
      display: flex;

      .plan-details-box {
        // height: 17.1rem;
        // padding: 0 3rem;
        margin-right: 3rem;
        width: 25%;
        border-right: 1px solid rgba(36, 46, 61, 0.2);
        &:nth-child(2) {
          width: 50%;
        }

        h6 {
          margin-bottom: 1.6rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        p {
          margin-bottom: 0.8rem;
        }

        &:nth-child(3) {
          width: 30%;
        }

        &:last-child {
          width: 20%;
          border: none;

          p {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1.6rem;
          }
        }

        a:hover {
          color: inherit;
        }
      }
    }
    .pricing-description {
      h5 {
        margin-bottom: 1rem;
      }
    }
  }
}
.main-renew-toggle-btn {
  position: absolute;
  right: -4rem;
  top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.toggle-btn {
  width: 5.2rem;
  height: 3rem;
  border-radius: 20rem;
  border: 0.2rem solid $grey-799;
  padding: 0.4rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  transition: all 0.4s;

  .toggle-circle {
    width: 2rem;
    height: 2rem;
    background-color: $grey-799;
    border-radius: 50%;
    cursor: pointer;
  }
}

#renewal:checked ~ .toggle-btn {
  justify-content: end;
  transition: all 0.4s;
}

.up-your-game {
  // padding: 26rem 0 0;

  .your-game-card-wrapper {
    display: flex;
    align-items: stretch;
    // justify-content: flex-start;
    justify-content: center;
    gap: 3.1rem;
    padding: 4.1rem 0 7.1rem;
    flex-wrap: wrap;

    .disable-card {
      position: relative;

      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        left: 0;
        top: 0;
        z-index: 6;
        background-color: $grey-6;
      }

      &:hover {
        transform: scale(1);
        background-color: $grey-ECE !important;
        transition: none;
        cursor: not-allowed;
      }

      .secondary-btn {
        background-color: $grey-ECE !important;
        color: $black-10 !important;
        border-color: $grey-2 !important;

        &:hover {
          background-color: $grey-ECE !important;
          color: $black-10 !important;
          border-color: $grey-2 !important;
        }
      }
    }

    .plan-card {
      // max-width: 22rem;
      max-width: 30%;
      // height: 35.4rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 3.5rem 1rem;
      gap: 4.1rem;
      border-radius: 0.8rem;
      border: 0.1rem solid $black-4;
      background: $white;
      position: relative;

      &.hover {
        // height: 37rem;
        transform: scale(1.05);
        background-color: $green-A5F;
        transition: transform 0.3s ease;

        .secondary-btn {
          background-color: $primary;
          color: $white;

          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }

      &.active {
        padding-bottom: 7rem;
      }

      ul {
        list-style: none;
        height: 100%;

        li {
          font-size: 1.4rem;
          line-height: 2.8rem;
          width: 100%;

          .desk {
            font-size: 7px;
          }

          &:last-child {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3.1rem;
            margin-top: 2.5rem;
          }
        }
      }

      button {
        // position: absolute;
        top: 27.6rem;
        cursor: pointer;
      }

      .currently-active {
        position: absolute;
        bottom: 0;
        background: $black-242;
        padding: 1.5rem 2rem;
        border-bottom-left-radius: 0.7rem;
        border-bottom-right-radius: 0.7rem;
        width: 100%;
        text-align: center;
        color: $white;
      }
    }

    .activeted-plan {
      transform: scale(1.05) !important;
      background-color: $green-A5F !important;
      transition: transform 0.3s ease;

      .secondary-btn {
        background-color: $primary !important;
        color: $white !important;
      }
    }
  }
}

.active-plan {
  transform: scale(1.05);
  background-color: $green-A5F;
  transition: transform 0.3s ease;

  .secondary-btn:hover {
    background-color: $primary !important;
    color: $white !important;
  }
}

.bg-primary-05 {
  background-color: $primary-05;
}

// !======================= MY SUBSCRIPTION PAGE END ==================
//?-------------- prising-plan- start -----------------
.prising-plan-wrapper {
  padding: 2.4rem 0 0 0 !important;

  .pricing-description {
    padding: 26px 0;
    position: relative;
    background-color: $primary-05;
  }
}

//?-------------- prising-plan- end -----------------
.black-05 {
  color: $black-4;
}

//
.mw-634 {
  width: 100%;
  max-width: 63.4rem;
}
.my-24 {
  margin: 24px 0;
}
.text-area-reason-frame {
  width: 100%;
  border: 1px solid $grey-2;
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;
  // padding: 0.7rem 1.2rem 1.4rem;
  .text-area {
    border: none;
    outline: none;
    width: 100%;
    height: 7.5rem;
    background-color: $white;

    text-overflow: ellipsis;
    &::placeholder {
      color: $grey-2;
    }
  }
  .count-text-words {
    position: absolute;
    bottom: 0.6rem;
    right: 1.2rem;
  }
}
// ?================== NEW PLAN SUBSCRIPTION CARD START ==========================
.black-242-5 {
  color: #242e3d80;
}
.plan-activate {
  background: #a5f5dc;
  // transform: scaleY(1.04);
}

.new-plan-card-frame {
  width: 100%;
  min-height: 52.4rem;
  height: fit-content;
  max-width: 35.2rem;
  padding: 1.6rem clamp(10px, 5%, 1.6rem);
  padding-top: 4rem;
  border: 1px solid $grey-ECE;
  border-radius: 1rem;
  box-shadow: 0px 4px 15px -10px #27008980;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .ative-plan-sticker {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: $white;
    width: 100%;
    max-width: 15.2rem;
    height: 2.8rem;
    border-radius: 0 0 0.8rem 0.8rem;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    // background: #A5F5DC;
  }

  .header-new-pan-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .plan-select-dropdown-frame {
      margin: 0.5rem 0;
      padding: 1.6rem;
      border-radius: 0.8rem;
      // background-color: rgba(255, 255, 255, .5);
      background-color: $grey-ECE;

      .select-plan-dropdown {
        .dropdown-btn {
          border-color: #242e3d33 !important;
        }
      }

      .duration-select-frame {
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1px;
        overflow-x: scroll;
        border-radius: 0.4rem;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          appearance: none;
          display: none;
        }

        .duration-box {
          width: 100%;
          padding: 0.5rem 1rem;
          margin: 2px 0;
          cursor: pointer;
          text-align: center;
        }

        .active {
          background-color: $primary;
          border-radius: 0.4rem;
          color: $white;
        }
      }
    }
  }

  .new-plan-card-body {
    height: 100%;
    width: 100%;
    padding: 1.4rem 0;

    p {
      @include font($black-242, 'lato-SemiBold', 1.4rem, 500, normal);
      margin-bottom: 1rem;
      color: $black-242 !important;
      span {
        background-color: transparent !important;
        @include font($black-242, 'lato-SemiBold', 1.4rem, 500, normal);
      }
    }
  }

  .new-plan-card-footer {
    width: 100%;
  }
  .new-plan-card-bottom {
    height: 100%;
    width: 100%;
    // padding: 1.4rem 0 0 0;

    p {
      @include font($black-242, 'lato-SemiBold', 1.4rem, 500, normal);
      margin-bottom: 0.8rem;
      color: $black-242 !important;
      span {
        background-color: transparent !important;
        @include font($black-242, 'lato-SemiBold', 1.4rem, 500, normal);
      }
    }
  }
}
.ghost-btn2 {
  border: 1px solid #242e3d33;
  border-radius: 0.8rem;
}
.save-confirm-collection-custom-model {
  .modal-content {
    max-width: 80.6rem;
  }
}
.bg-A5F {
  background: $green-A5F !important;
}
.cancelation-reason-modal {
  padding: 2rem !important;
  position: relative;
  width: 100%;
  overflow-x: auto;

  .close-btn {
    position: absolute;
    right: 3rem;
    top: 2rem;
  }
  .input-box {
    max-width: 100%;
    background-color: rgba(213, 208, 208, 0.2);
  }
  .primary-btn {
    border-radius: 0.8rem !important;
  }
}
.mw-145 {
  width: 100%;
  max-width: 14.5rem;
}
.disabled-btn {
  // background-color:rgba(216, 215, 215,.1) !important;
  background: $grey-ECE !important;
  border: 1px solid $white !important;
  border-radius: 0.8rem;
  color: #242e3d80;
  border: 1.7px solid #242e3d80 !important;
  &:hover {
    color: $grey-6;
  }
}
.text-ellipsis-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disable-pointer {
  cursor: not-allowed;
}
.invoice-table::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and other WebKit browsers */
}
.invoice-table {
  overflow-x: scroll;
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none;
  overflow-y: hidden;
  .invoice-table-frame {
    .invoice-header-row {
      border-bottom: 1px solid #242e3d1a;
      th {
        padding: 1.8rem 1rem;
        color: #242e3d;
      }
    }
    .invoice-header-row,
    .invoice-body-row {
      td,
      th {
        &:nth-child(5) {
          text-align: center;
        }
        &:last-child {
          text-align: center;
        }
      }
    }
    .invoice-body-row {
      border-bottom: 1px solid #242e3d1a;
      td {
        padding: 1.8rem 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.doted-vertical-line {
  border-left: 0.2rem dashed $grey-999;
  opacity: 0.2;
}

// ?================== NEW PLAN SUBSCRIPTION CARD END ==========================
@media (max-width: 820px) {
  .mySubscription-page {
    padding-top: 6.4rem;
  }
  .main-renew-toggle-btn {
    right: 0.5rem;
    top: 7rem;
  }
  .save-confirm-collection-custom-model {
    .modal-content {
      margin: 20% 0 10% !important;
    }
    .modal-dialog {
      .modal-body {
        padding: 2rem 2rem !important;
      }
    }
  }

  .cancelation-reason-modal {
    padding: 2rem 2rem !important;
    position: relative;
    width: 100%;
    overflow-x: hidden;

    // .cancel-text{
    //   margin-right: 8rem;
    // }

    .close-btn {
      position: absolute;
      right: 2rem;
      top: 2rem;
    }

    .input-box {
      max-width: 100%;
      background-color: rgba(213, 208, 208, 0.2);
    }
    .primary-btn {
      padding: 0.5rem 0.5rem !important;
      font-size: 1.2rem !important;
    }
    .ghost-btn2 {
      padding: 0.5rem 0.6rem !important;
      font-size: 1.2rem !important;
    }
  }

  // !======================== SUBSCRIPTION PAGE START ====================
  .mySubscription-wrapper {
    h5 {
      margin-bottom: 0rem;
    }

    .subscription-details {
      padding: 1.6rem 0;
      // margin: 0 1.6rem;

      .plan-update-wrapper {
        flex-wrap: wrap;

        .plan-details-box {
          width: 100% !important;
          height: auto;
          padding: 2.1rem 0 1.4rem;
          border-right: none;
          border-bottom: 1px solid $black-242;
          margin-right: 0;

          h6 {
            margin-bottom: 0.8rem;
          }
        }
      }
    }
  }

  .up-your-game {
    // padding: 60rem 0 0;

    .your-game-card-wrapper {
      flex-wrap: wrap;
      gap: 2rem;
      padding: 2rem 0 4rem 0;

      .plan-card {
        width: 100%;
        max-width: 100%;
        height: 8.9rem;
        overflow: hidden;

        &:hover {
          background-color: $green-A5F;
          height: auto;
          transform: scale(1);
        }
      }
    }
  }

  .active-plan,
  .disable-card {
    width: 100%;
    max-width: 100%;
    height: 35.4rem !important;
    overflow: hidden;

    &:hover {
      background-color: $green-A5F;
      transform: scale(1);
    }
  }
  .doted-vertical-line {
    border-top: 0.2rem dashed $grey-999;
    opacity: 0.2;
    width: 90%;
  }
  img {
    // margin-bottom: 0.5rem;
  }

  // !======================== SUBSCRIPTION PAGE END ====================
  // ?================== NEW PLAN SUBSCRIPTION CARD START ==========================
  .black-242-5 {
    color: #242e3d80;
  }

  .new-plan-card-frame {
    .header-new-pan-card {
      .plan-select-dropdown-frame {
        .select-plan-dropdown {
          .dropdown-btn {
          }
        }

        .duration-select-frame {
          padding: 0 1px;

          .duration-box {
            padding: 0.4rem 0.6rem;
            margin: 2px 0;
            cursor: pointer;
          }

          .active {
            background-color: $primary;
            border-radius: 0.4rem;
            color: $white;
          }
        }
      }
    }

    .new-plan-card-body {
    }

    .new-plan-card-footer {
      width: 100%;
    }
  }

  // ?================== NEW PLAN SUBSCRIPTION CARD END ==========================
}
