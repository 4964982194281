.myactivity-pageheader {
    margin: 0;
    .mycollection-header {
        margin-bottom: 3.2rem;
    }
}
.tabs-main-wrapper {
    .nav,
    .nav-tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: auto;
        gap: 3rem;
        flex-wrap: nowrap;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
        border-bottom: 1px solid $black-242;

        .nav-item {
            @include font($black-242, "Lato-Regular", 2.2rem, 400, 28px);
            border-radius: 0.8rem 0.8rem 0 0;
            border: 1px solid $black-4;
            background: $white;
            text-align: center;
            padding: 1.3rem 0;
            width: 100%;
            max-width: 27rem;
            margin-bottom: 0;
            border-bottom: 0;
            // text-overflow:ellipsis;
            // overflow: hidden;
            white-space: nowrap;
        }
        .active {
            background: $green-A5F;
        }
    }
    .tab-content {
        min-height: 40rem;
    }
}
.mw-30 {
    max-width: 3rem;
    width: 100%;
}
.tab-content-wrapper {
    padding: 2.4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    .activity-box {
        width: 100%;
        max-width: 57rem;
        padding: 2rem 2.4rem;
        display: flex;
        align-items: stretch;
        justify-content: start;
        gap: 3rem;
        border-radius: 0.8rem;
        border: 0.1rem solid $grey-ECE;
        background: $white;
        .activity-image-frame {
            width: 100vw;
            height: 100%;
            max-width: 18.1rem;
            height: 100vh;
            max-height: 14rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .activity-image {
                width: 100%;
                // max-width: 15.1rem;
                height: 100%;
                max-height: 14rem;
                object-fit: cover;
                // position: relative;
            }
        }
        .premium-icon{
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
           
           
        }
    }
}
.activity-content-frame {
    width: 60%;
    h6 {
        margin-bottom: 0.8rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .activity-options {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1.6rem;
        .act-btn {
            border-radius: 0.8rem;
            border: 1px solid $black-4;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.8rem;
            padding: 0.6rem;
            cursor: pointer;
            &:hover {
                background-color: $green-A5F;
                color: inherit;
            }
            img {
                width: 1.8rem;
            }
        }
    }
}
.tab-recent-wrapper {
    padding: 2.4rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    gap: 1.6rem;
}
.resent-serch-box {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 3.2rem;
    padding: 1.2rem 3rem;
    border-radius: 0.8rem;
    border: 0.1rem solid $grey-ECE;
    background: $white;
    width: 100%;
    cursor: pointer;
}
@media (max-width:920px) {
    .tabs-main-wrapper {
        .nav,
        .nav-tabs {
            border-bottom: none;
            gap: 0.8rem;
            .nav-item {
                font-size: 1.2rem;
                line-height: 1.8rem;
                padding: 0.8rem 1.5rem;
                border-radius: 0.8rem;
                width: 100%;
                border-bottom: 1px solid $black-4;
            }
        }
    }
    .tab-content-wrapper {
        .activity-box {
            padding: 1.2rem 0.8rem;
            gap: 1.4rem;
            .activity-image-frame {
                max-height: 11rem;
                max-width: 14.1rem;
                .activity-image {
                    width: 100%;
                    // max-width: 11.4rem;
                    object-fit: cover;
                }
            }
        }
    }
    .activity-content-frame {
        overflow: hidden;
        h6 {
            margin-bottom: 0.4rem;
        }
        .activity-options {
            margin-top: 1rem;
            gap: 0.8rem;

        }
    }
}
