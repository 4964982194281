.sponsored-slider-wrapper {

   
  
}
.sponsored-card-wrapper {
    width: 12rem;
    border-radius: .8rem;
    height: 21.3rem;
    // background-color: $black-4;
    margin: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 100%;
        object-fit: cover;
    }
}
.slider-right-arrow,.slider-left-arrow{
    border-radius: 24px;
    background: #FFF;
    box-shadow: -1px 1px 2px 0px $black-3;
}
.mb-20{
    margin-bottom: 2rem;
}
.sponsored-title{
    width: 100%;
    padding-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.2rem;
    font-size: 1.8rem;
    line-height:24px;
    img{
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        object-fit: contain;
        border: .05rem solid $black;
    }
}
@media (max-width:820px) {
    .sponsored-slider-wrapper {
        padding: 1.6rem 4.5%;}
    .sponsored-card-wrapper {
        width: 13.65rem;
        height: 24.1rem;
        background-color: $black-4;
        margin: 0 .8rem;
    }
    .slick-next{
        right: -1rem;
    }
    .slick-prev{
        left: -1rem;
        z-index: 1;
    }
}
