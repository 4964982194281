.grid-container {
  width: 100%;
  column-count: 4;
  column-width: 330px;
  column-gap: 2.5rem;
  padding: 3.5rem 0 2rem;
  // padding: 2rem;

  .grid-item {
    background: $white;
    border: 1px solid $grey-EEE;
    border-radius: 8px;
    /* Rounded corners */
    overflow: hidden;
    /* Ensures the content fits within the rounded corners */
    display: flex;
    flex-direction: column;
    margin: 0 0 2.5rem;
  }
}
.embeded-wrapper {
  // display: flex;
  // justify-content: center;
  margin-bottom: 2.5rem;
  .rsme-embed {
    width: 100%;
    max-width: 34rem;
  }
}
.twiter-iframe {
  .rsme-twitter-embed {
    width: 100%;
    max-width: 34rem;
  }
}
.treandign-search {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $grey-2;
  background: $white;
  border-top: 0.1rem solid $white-E1E;
  padding: 1.5rem 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem 0 3.6rem;
  .input-search-box {
    width: 100%;
    outline: none;
    border: none;
    &::placeholder {
      opacity: 0.6;
    }
  }
}
.trend-serch {
  margin: 0.6rem 0 3.6rem;
}
.video-trend-border {
  border-radius: 6px;
  border: 1px solid $grey-ECE;
  display: flex;
  padding: 6px 12px 6px 8px;
  img {
    width: 1.3rem;
  }
}
.treanding-tab-wrapper {
  // position: relative;
  // padding-top: 2.6rem;
  // z-index: 0;
  // margin-top: -30rem;
  .settinng-reset-btn {
    position: absolute;
    right: 0;
    top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    img {
      cursor: pointer;
    }
  }
  .treanding-one-wrapper {
    max-width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  }
}
.trending-tabs,
.notification-tabs {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-top: 2.6rem;


  // margin-bottom: 27.5rem;
  border-bottom: 0.1rem solid $grey-6;
  .nav-item {
    padding: 1.5rem 0.4rem;

    opacity: 0.6;
    @include font($black-242, "Lato-Medium", 1.8rem, 500, 1.9px);
  }
  .active {
    color: $primary !important;
    font-weight: 600 !important;
    border-bottom: 0.2rem solid $primary;
    opacity: 1 !important;


  }
}
.around-the-globe-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0 0.1rem 0;
  .feeds-wrapper {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    .Social-media-frame {
      border-radius: 0.8rem;
      border: 0.1rem solid $grey-2;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      padding: 0.6rem 1.2rem;
    }
    .active-feed {
      background: $green-ABF;
    }
    .vertial-line {
      width: 0.1rem;
      height: 3.2rem;
      background: $grey-6;
    }
  }
}
.notification-tabs {
  // margin-bottom: 27.5rem;
  position: relative;
  padding-top: 0;
  z-index: 0;
  // margin-top: -30rem;
}
@media (max-width: 600px) {
  .grid-container {
    column-count: 2;
    /* Single column on smaller screens */
  }
}
@media (max-width: 1024px) {
  .grid-container {
    column-count: 3;
    /* Single column on smaller screens */
  }
}
@media (max-width: 375px) {
  .grid-container {
    column-count: 1;
    /* Single column on smaller screens */
  }
}
@media (max-width: 820px) {
  .around-the-globe-wrapper {
    .feeds-wrapper {
      .Social-media-frame {
        border-radius: 0.4rem !important;
        padding: 0.4rem !important;
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .trending-tabs {
    justify-content: space-around;
    .nav-item {
      padding: 1.5rem 0.4rem;
      opacity: 0.6;
      @include font($black-242, "Lato-Medium", 1.4rem, 400, 1.8px);
    }
  }
  .treanding-tab-wrapper {
    // padding-top: 1.6rem;
    margin-top: 0rem;
  }
  .trend-serch {
    margin-bottom: 2.4rem;
  }
}
