.shimmer-container {
    width: 200%;
    background-image: linear-gradient(90deg, rgba(239, 238, 238, 0.1) 25%, $white 50%, rgba(239, 238, 238, 0.1) 75%);
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
    background-color: $grey-ECE;
}
@keyframes shimmer {
    0% {
        background-position: 200% 0;
        // background-color: $grey-ECE;
    }
    100% {
        background-position: -200% 0;
        // background-color: $grey-ECE;
    }
}
.shimmer-frame {
    width: 200%;
    background-image: linear-gradient(90deg, #7e7b7b1a 25%, #ffffff 50%, #f8f9fa 75%);
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
    background-color: $white;
}
@keyframes shimmer {
    0% {
        background-position: 200% 0;
        // background-color: $grey-ECE;
    }
    100% {
        background-position: -200% 0;
        // background-color: $grey-ECE;
    }
}
.justify-content-center-wrap{
    justify-content: center;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
        appearance: none;
        display: none;
    }
}
.socialmedia-card-shrim{
    width:clamp(15rem, 18.5vw,27rem);
    height:clamp(24rem,70vw,40rem);
}
.sponsored-card-shrimer-box{
    height: 40rem;
}

@media (max-width:820px) {
    .justify-content-center-wrap{
        justify-content: start;
        padding: 0 5%;
    }
    .sponsored-card-shrimer-box{
        height: 37.1rem;
    }
}

