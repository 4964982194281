.cobranding-hero-section {
  padding: 7.4rem 0 0;
  background-color: $white;
}
.cobranding-wrapper {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 2rem;
}
.under-line {
  text-decoration: underline;
}
.co-branding-box,
.preview-box {
  width: 100%;
}
.co-branding-options {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
}
.dropwon-component-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  margin-top: 1.6rem;
  margin-bottom: 4.4rem;
}

.branding-file-information-desk {
  margin-bottom: 1.6rem;
  .brand-file-info-wrapper {
    padding: 2rem 2.2rem;
    margin-top: 3.6rem;
    border-radius: 8px;
    min-width: 48rem;
    width: 100%;
    background: $grey-2;
    display: flex;
    gap: 3rem;
    .thabmail-box {
      width: 100%;
      .file-thumbnail {
        width: 100%;
        height: 14rem;
        object-fit: contain;
      }
      .file-user-frame {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.9rem;
        margin-top: 1.8rem;
        .file-user-profile {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          object-fit: fill;
        }
      }
    }
    .file-info-right-box {
      width: 100%;
      p {
        margin: 1.2rem 0;
      }
    }
  }
}
.preview-box-frame {
  border-radius: 12px;
  padding: 2.4rem 2.38rem 2rem;
  background: $grey-2;
  margin-bottom: 2rem;
  .preview-image-wrapper {
    width: 100%;
    // height:29.3rem;
    position: relative;
    margin-top: 28px;
    // border: .2rem solid $black-242;//Dont remov this commit
    border-radius: 0.8rem;
    .preview-main-img {
      width: 100%;
      object-fit: fill;
    }
  }
}
.co-branding-logo {
  width: 8rem;
  height: 3rem;
  position: absolute;
  object-fit: contain;
  right: 1rem;
  top: 1rem;
}
.loading-button {
  cursor: not-allowed !important;
  mix-blend-mode: color-burn;
  width: auto;
  .download-icon {
    width: 2rem;
  }
}
.text-logo {
  background-color: $white;
  color: $black;
  padding: 0.5rem 1.2rem;
  max-width: 19.9rem;
  border-radius: 0.5rem;
  // min-width: 16.3rem;
  // width: 47%;
  position: absolute;
  // top:1.2rem;
  // right: .98rem;
}
.select-position-wrapper {
  max-width: 100%;
  padding-right: 30%;
  margin-top: 2rem;
  // border-top:1px solid $black-4 ;
}
.preview-action-frame {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.6rem;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: $white;
}
.share-icon {
  width: 2rem;
}
.save-frame {
  min-width: 4.2rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  border: 0.1rem solid $grey-2;
  .icon {
    font-size: 1.8rem;
    color: $grey-3;
  }
}
.button-download {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 0.6rem 0.8rem;
  border-radius: 0.8rem;
  gap: 0.8rem;
  border: 0.1rem solid $grey-2;
  color: $grey-3;
  cursor: pointer;
}
.brand-uploade-logo-frame {
  .uploade-img-wrapper {
    width: 15rem;
    height: 67.456px;
    flex-shrink: 0;
    border-radius: 8px;
    background: $grey-2;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.brand-select-text-frame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.3rem;
  flex-wrap: wrap;
  padding: 2rem 0;
}
.mb-3-desk {
  margin-bottom: 1rem;
}
// todo : ---------- NEW VERSION COBRAND CSS START --------------------------------------------
.black-50 {
  color: $black-4;
}
.underline {
  text-decoration: underline;
}
.mb-16 {
  margin-bottom: 1.6rem;
}
.gap-8 {
  gap: 0.8rem;
}
.mb-12 {
  margin-bottom: 1.2rem;
}
.mw-83 {
  max-width: 8.3rem;
}
.button-download {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 0.6rem 0.8rem;
  border-radius: 0.8rem;
  gap: 0.8rem;
  border: 0.1rem solid $grey-2;
  color: $grey-3;
  cursor: pointer;
}
.cobranding-hero-section {
  padding-bottom: 5.9rem;
}
.mw-184 {
  max-width: 184px;
  width: 100%;
}
.custom-button-font {
  .custom-dropdown-btn {
    font-size: 14px !important;
    line-height: 16px !important;
    height: 38px;
    .drop-down-icon {
    }
  }
}
.cobrandingv-main-wrapper {
  display: flex;
  align-items: flex-start;
  gap: clamp(10px, 3%, 35px);
  padding-top: 2.4rem;
  .cobrandv-left-content {
    width: clamp(10px, 59%, 68.8rem);
    .cobanding-sub-frame {
      display: flex;
      flex-direction: column;
      gap: 4rem;

      .co-branding-leabling {
        .start-label-frame {
          display: flex;
          gap: 0.8rem;
          margin-top: 1rem;
          border-radius: 0.8rem;
          background: $grey-8;
          padding: 1rem 1.2rem 1.5rem;
          .check-box-text-frame {
            width: 100%;
            .start-logo-frame {
              margin-top: 1.2rem;
              padding: 1.6rem 0 0;
              border-top: 0.1rem solid $grey-2;
              display: flex;
              flex-direction: column;
              gap: 0.8rem;
            }
          }
        }
        .branding-color-language-frame {
          margin-top: 1.6rem;
          .dropwon-component-wrapper {
            margin-top: 0.8rem;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1.2rem;
          }
        }
      }
    }
  }

  .cobrand-right-preview {
    width: 41%;
    .privew-main-wrapper {
      padding: 1.6rem 1rem 1.6rem;
      border-radius: 0.98rem;
      background-color: $grey-2;
      margin-top: 1.2rem;
      .preview-header-frame {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        .personalization-btn {
          max-width: 15.7rem;
          width: 100%;
          border-radius: 0.65rem;
          height: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.3rem;
          border: 0.084rem solid $grey-2;
          padding: 0.4rem 1rem;
          .preview-arrow-box {
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              // background-color: greenyellow;
            }
            .preview-left-arrow {
              border-top: 0.65rem solid transparent;
              border-bottom: 0.65rem solid transparent;
              border-right: 0.65rem solid $black;
            }
            .preview-right-arrow {
              border-top: 0.65rem solid transparent;
              border-bottom: 0.65rem solid transparent;
              border-left: 0.65rem solid $black;
              cursor: pointer;
            }
          }
          p {
            min-width: 8.1rem;
          }
        }
      }
      .preview-content-box {
        margin-top: 1.9rem;
        background-color: $white;
        height: 23.8rem;
        width: 100%;
        border: 0.15rem solid $grey-2;
        border-radius: 0.8rem;
      }
      .important {
        margin: 3.2rem 0 0.4rem 0;
      }
      .bottom-text {
        margin-top: 0.8rem;
      }
    }
  }
}

.company-details-wrapper {
  .uplad-data {
    margin-top: 1.2rem;
    display: flex;
    align-items: start;
    gap: clamp(5px, 3%, 1.2rem);
    .company-logo-frame,
    .upload-image-logo {
      border-radius: 0.71rem;
      // background-color: red;
      background-color: $grey-F5F;
      border: 0.1rem solid $grey-6;
      // padding: 2.3rem clamp(5px,2%,3rem);
      padding: 0.6rem;
      // width:clamp(120px,35vw,144px);
      min-width: 11rem;
      width: 100%;
      max-width: 14.4rem;
      height: 8rem;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .company-logo-main-wrapper {
      width: 100%;
      max-width: 144px;
      .company-logo-frame {
        width: 100%;
        height: 8rem;
        padding: 0.3rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      p {
        cursor: pointer;
        margin-top: 0.4rem;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        img {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
    .brand-select-text-wrapper {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      flex-wrap: wrap;
      padding: 0;
      input {
        border-color: $grey-2;
      }
    }
  }
}

.show-loading-effect {
  &::after {
    content: "Uploading...";
    position: absolute;
    font-size: 1.8rem;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.4);
  }
}
.disabled-checkBox {
  position: relative;
  z-index: 2;
  &::after {
    position: absolute;
    content: "";
    width: 10rem;
    height: 100%;
    background-color: $grey-8;
    z-index: 4;
    opacity: 0.9;
    cursor: not-allowed;
  }
}

.personalization-frame {
  .text-area-frame {
    width: 100%;
    border: 1px solid $grey-2;
    border-radius: 0.8rem;
    position: relative;
    padding: 0.7rem 1.2rem 1.4rem;
    .text-area {
      border: none;
      outline: none;
      width: 100%;
      height: 7.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      &::placeholder {
        color: $grey-2;
      }
    }
    .count-text-words {
      position: absolute;
      bottom: 0.6rem;
      right: 1.2rem;
    }
  }
}
.cobrand-custom-checkbox {
  min-width: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $primary;
  padding: 0.1rem 0.05rem;
  background-color: $white;
  cursor: pointer;
  user-select: none;
  img {
    display: none;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.cobrand-custom-checkbox-modal {
  min-width: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $grey-2;
  padding: 0.1rem 0.05rem;
  background-color: $white;
  cursor: pointer;
  user-select: none;
  img {
    display: none;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
#end-check-box,
#end-check-box1,
#end-check-box2,
#full-check-box,
#full-check-box1,
#full-check-box2,
#start-check-box,
#start-check-box1,
#branding,
#personalization-check-box,
#start-check-box2 {
  &:checked ~ {
    .cobrand-custom-checkbox {
      img {
        display: block;
      }
    }
  }
}
.light-border {
  .dropdown {
    button {
      border-color: $grey-2 !important;
    }
  }
}

.file-information-main-wrapper {
  width: clamp(10px, 59%, 68.8rem);
  margin-top: 4rem;
  .information-file-box {
    margin-top: 1.2rem;
    display: flex;
    gap: 1.2rem;
    .thumb-img-box {
      width: 100%;
      max-width: 162px;
      height: 13.3rem;
      min-width: 14.5rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .right-content-box {
      .sub-header-frame {
        margin-bottom: 1.1rem;
        img {
          width: 2.7rem;
          height: 2.7rem;
          border-radius: 50%;
          object-fit: contain;
        }
      }
    }
  }
}
.start-labelling-frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;

  .startlabeling-size {
    width: 100%;
    max-width: 17rem;
    object-fit: contain;
    max-height: 8rem;
  }
  .line {
    height: 0.1rem;
    margin: 0.6rem auto;
  }
}
.full-labelling-frame {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  .thumbanial-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .box-position-set {
    position: absolute;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    //  flex-direction: column-reverse;
    gap: 0.6rem;
    z-index: 2;
    .startlabeling-size {
      width: 100%;
      max-width: 8rem;
      max-height: 3rem;
      object-fit: contain;
    }
  }
  .branding-details-box {
    background-color: $white;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.3rem 0.6rem;
  }
}
.my-12 {
  margin: 1.2rem 0;
}
.cobrand-edit-modal {
  .modal-dialog {
    .modal-content {
      margin: 20% auto auto auto;
      border-radius: 1.5rem;
      overflow: hidden;
      max-width: 556px;
      .modal-body {
        background-color: $white;
        padding: 1.6rem 2rem !important;
        .cobrand-edit-modal-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.6rem;
        }
      }
    }
  }
}
// todo : ---------- NEW VERSION COBRAND CSS END --------------------------------------------
@media (max-width: 1024px) {
  .cobranding-wrapper {
    flex-wrap: wrap;
  }
}
@media (max-width: 820px) {
  .mb-full {
    width: 100% !important;
    max-width: 100% !important;
  }
  // todo : ---------- NEW VERSION COBRAND CSS START --------------------------------------------
  .mb-hide {
    display: none;
  }
  .cobranding-hero-section {
    padding-bottom: 7rem !important;
  }
  .custom-button-font {
    width: 100%;
    max-width: 100%;
    .custom-dropdown-btn {
      max-width: 100%;
      width: 97%;
    }
  }
  .cobrandingv-main-wrapper {
    flex-wrap: wrap;
    .cobrandv-left-content {
      width: 100%;
      .cobanding-sub-frame {
        gap: 3.2rem;

        .company-details-wrapper {
          .uplad-data {
            gap: 2rem;
            .company-logo-frame,
            .upload-image-logo {
            }
            .brand-select-text-wrapper {
              input {
              }
            }
          }
        }
      }
    }
    .cobrand-right-preview {
      width: 100%;
      .privew-main-wrapper {
        padding-bottom: 3.1rem;
        .preview-header-frame {
          .personalization-btn {
            .preview-arrow-box {
            }
          }
        }
        .preview-content-box {
          margin-top: 2.4rem;
          height: 17rem;
        }
      }
    }
  }

  .file-information-main-wrapper {
    margin-top: 3.2rem;
    .information-file-box {
      flex-wrap: wrap;
      // background-color: $white;
      .thumb-img-box {
        height: auto;
      }
    }
  }
  .button-download {
    width: 100%;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center !important;
    background: $grey-2;
    // padding-left: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  // todo : ---------- NEW VERSION COBRAND CSS END --------------------------------------------

  .cobranding-hero-section {
    padding: 6.7rem 0 0;
  }
  .mb-3-desk {
    margin-bottom: 0;
  }
  .co-branding-box {
    h4 {
      font-weight: 600;
    }
  }

  .co-branding-options {
    gap: 2.4rem;
    padding-top: 5px;
  }
  .dropwon-component-wrapper {
    gap: 0.8rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    justify-content: start;
  }

  .max-width {
    .dropdown {
      button {
        width: 28rem;
      }
    }
  }

  .button-download {
    width: 90%;
    justify-content: center !important;
    background: $grey-9;
    z-index: 6;
  }
  .branding-file-information-desk {
    .brand-file-info-wrapper {
      min-width: 100%; //change
      background: $white;
      margin-top: 0;
      padding: 0;
      gap: 2rem;
      .thabmail-box {
        .file-thumbnail {
        }
        .file-user-frame {
          justify-content: start;
          margin-top: 0;
        }
      }
      .file-info-right-box {
        padding-bottom: 1.3rem;
        p {
          margin: 0.8rem 0;
        }
      }
    }
  }
  // .text-logo{
  //     min-width:16.3rem;
  // }
  .preview-box-frame {
    padding: 1.7rem 1rem;
  }
  .select-position-wrapper {
    padding-right: 0;
    border-top: none;
  }
  .lable-position {
    .custom-radio-label {
      min-width: 10rem;
    }
  }
  .loading-button {
    mix-blend-mode: normal;
    background: $white;
    border: 0.1rem solid $grey-3;
  }
  .company-details-wrapper {
    .uplad-data {
      margin-top: 1.2rem;
      display: flex;
      align-items: start;
      gap: clamp(5px, 3%, 1.2rem);
      .company-logo-frame,
      .upload-image-logo {
      }
      .company-logo-main-wrapper {
      }
      .brand-select-text-wrapper {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        flex-wrap: wrap;
        padding: 0;
        input {
          border-color: $grey-17 !important;
        }
        input:disabled {
          color: $grey-17 !important;
        }
      }
    }
  }
  .cobrand-edit-modal {
    .modal-content {
      margin: 0 0 0 0 !important;
    }
  }
}

//Cobrandingv3
.download-btn-container {
  display: none;
}
.cobrand-detail-wrapper {
  margin-bottom: 5rem;
  .cobrand-profile-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-top: 1rem;
    width: 100%;
    .profile-detail {
      display: flex;
      align-items: start;
      justify-content: flex-start;
      gap: 1.3rem;
      width: 100%;
      .profile-img {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
      }
      .name-count-details {
        width: 100%;
        gap: 0.8px;
        display: flex;
        flex-direction: column;

        .count-detail-heading {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1.8rem;
          color: $black-242;
        }
        p {
          display: flex;
          gap: 0.5rem;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.8rem;
            h6 {
              color: $black-242;
              opacity: 0.6;
            }
          }
        }
        .details-duration {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.8rem;
        }
        .video-tag {
          border-radius: 0.4rem;
          h6 {
            color: $black-242;
          }
          background-color: $grey-ECE;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0.8rem;
        }
        .video-detail-para {
          margin-top: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 1rem 0.5rem;
          p {
          }
        }
        .video-details-dropwon-component-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          // padding-top: 0.4rem;
        }
        .ellipsis-1 {
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .download-btn {
          display: flex;
          padding: 6px 12px 6px 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 0.8rem;
        }
      }
    }
  }
  .canvas-container-wrapper {
    width: 100%;
    border-radius: 0.8rem;
    height: 50rem;
    border: 1px solid $grey-ECE;
    // background-color: $grey-ECE;
    margin-bottom: 5rem;
  }
}
@media (max-width: 820px) {
  .download-btn-container {
    display: block;
    padding: 1rem 5%;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: $white;
    border: none !important;
    outline: none;
    z-index: 3;
  }
}
