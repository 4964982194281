.planner-wrapper {
  .post-planner-heading {
    h5 {
      margin-bottom: 2.4rem;
    }
  }
  .post-plan-no-login-wrapper {
    h3 {
      padding: 1.2rem 0 2.4rem;
    }

    h6 {
      max-width: 82.6rem;
      padding: 1.2rem 0 2.4rem;
    }

    .planner-video-tag {
      max-width: 60rem;
      max-height: 30rem;
      aspect-ratio: 2;
      width: 100%;

    .video-frame{
      border-radius: 1rem ;
    }

    }

    .primary-btn {
      margin: 2.4rem 0;
    }
  }

  .post-planer-tabs {
    margin-bottom: 0;
    width: 70%;
    position: relative;
    // z-index: 0;
    margin-top: -7.5rem;
    border-bottom: none;

    .nav-item {
      padding: 2.3rem 0.4rem;
    }
  }

  .social-preferences-frame {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: clamp(1rem, 10%, 4.8rem);
    position: relative;
    height: 4.9rem;
    border-bottom: 0.1rem solid $grey-6;
    // z-index: 1;
  }
}

@media (max-width: 820px) {
  .planner-wrapper {
    .post-planner-heading {
      h5 {
        margin-bottom: 1.4rem;
      }
    }
    .post-plan-no-login-wrapper {
      h3 {
        padding: 1.2rem 0 1.4rem;
      }

      h6 {
        padding: 1.2rem 0 1.4rem;
      }
    }

    .post-planer-tabs {
      width: 100%;
      justify-content: start !important;
    }
  }
}

//styling for create plan tab
//styling for create plan tab
.create-plan-tab {
  .post-planner-detail {
    margin-bottom: 2rem;

    h6 {
      margin-right: 1.6rem;
    }

    .youtube-icon {
      background-color: $green-A5F;
      border-radius: 0.6rem;
      max-width: 3.6rem;
      max-height: 2.4rem;
      padding: 0.5rem 1.1rem;
      cursor: pointer;
      align-items: center;
    }
    .planner-disclaimer{
      border: 1px solid $grey-ECE;
      padding : 0.8rem 0.8rem;
      border-radius : 0.8rem;
      width: fit-content;
      .disclaimer-icon{
        margin-right: 0.8rem;
      }
    }
  }


  .planner-feeds-wrapper {
    margin-top: 3.2rem;
    margin-bottom: 2.3rem;
    gap: 0.8rem;
  }
  .feeds-card-dropdown{
    margin-top: 3.2rem;
    border-radius: 0.8rem;


  }
  .active-feed {
    background-color: #abf0e2;
  }

  .trending-tabs .nav-tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Social-media-frame {
    border: 0.1rem solid $grey-2;
    border-radius: 0.8rem;
    padding: 0.6rem 1.2rem;
    gap: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  #myTabContent {
    margin-left: 10.25rem !important;
  }

  .trending-tabs .nav-item {
    margin-right: 0.5rem;
  }

  .trending-tabs .right-aligned-tabs {
    margin-left: auto;
  }

  .feeds-card-dropdown {
    .dropdown-btn {
      border: 1px solid $grey-2 !important;
      max-height: 3.4rem;
      border-radius: 0.8rem;
    }

    .dropdown-icon {
      margin-left: 0.7rem;
      font-size: 10rem;
    }
  }
  .post-planner-button {
    .primary-btn {
      padding: 1rem 2.4rem;
      border-radius: 0.8rem;
    }
  }
  .checkbox-container {
    .cobrand-custom-checkbox {

    min-width : 2rem;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 0.6rem;
      margin-left: 0.8rem;
      border: 2px solid $black-242 !important;
    }
  }


  .planner-cards-main {
    margin-top: 3.2rem;

    .planner-card-wrapper {

      max-width: 27rem;
      height: 32.7rem;
      // height: fit-content;
      padding: 2.5rem 2rem 2.3rem 2rem;
      border-radius: 0.8rem;
      margin-bottom: 5.7rem;
      background-color: $green-A5F;

      &.card-active {
        color: white;
        background-color: rgba(39, 0, 137, 1);
      }

      .white-line {
        border-color: white;
      }
      .card-first {
        // width: 22.9rem;
        min-height: 18.4rem;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border-radius: 0.6rem;
        cursor: pointer;
        .first-image {
          max-height: 5.6rem;
          max-width: 5.6rem;
        }
      }
      .card-image {
        position: relative;
        .card-img {
          height: 12.5rem;
        }
        .card-plus {
          position: relative;
          height: 12.5rem;
          background-color: $primary;
          border-radius: 0.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
        }
        .planner-card-icons {
          position: absolute;
          top: 1.3rem;
          left: 1.5rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .card-icon {
            background-color: $white;
            // padding: 0.5rem;
            border-radius: 0.2rem;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-right: 0.8rem;
            height: 2rem;
            width: 2rem;
          }
        }
        .view-playlist {
          position: absolute;
          bottom: 1.3rem;
          right: 1.5rem;
          cursor: pointer;

          .active-status {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 0.8rem;
            background-color: $white;
            text-align: center;
            border-radius: 0.2rem;
            max-height: 2rem;
            margin-right: 0.8rem;
          }
        }
      }

      .card-content {
        margin-top: 2rem;


        h5 {
          min-height: 4.8rem;
          max-width: 19.2rem !important;
          display: block;
          overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
          margin-bottom: 0;
          hyphens: auto;
          word-wrap: break-word; /* Ensures long words break properly */
  // white-space: normal;
        }
.btn-wrapper{
  width : 100%;
        .custom-btn{
          border: 1px solid $grey-ECE;
          background-color: $white;
          text-align: center;
          padding: 0.5rem 0.9rem;
          border-radius: 0.4rem;
          // max-width: 22.8rem !important;
          max-height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 100% !important;
          margin-bottom : 1.6rem;
          cursor: "pointer";

        }
      }

        .checkbox-container {
          .cobrand-custom-checkbox {
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 0.6rem;
          }
          .border-white {
            border: 2px solid $white !important;
          }
          .border-black {
            border: 2px solid $black-242 !important;
          }
        }
      }

      hr {
        border: 1px solid $black;
        margin: 0.8rem 0 0.8rem 0;
        max-width: 17.2rem;
      }

      .nav-link {
        margin-left: 30rem;
      }

      .card-icon {
        margin-bottom: 1.2rem;

        p {
          padding-left: 0.8rem;
        }
      }
      .date-range {
        margin-left: 0.8rem;
      }

      .post-card-dropdown {
        padding: 0.5rem 0.9rem;
        // max-width: 15.2rem;
        max-height: 3.4rem;

        width: 15.2rem;
        border-radius: 0.4rem;
        border: 1px solid $grey-ECE !important;
      }

      .dropdown-icon {
        width: 2.4rem;
        height: 2.4rem;
      }
      .amc-logo {
        .user-logo {
          border-radius: 50%;
          object-fit: contain;
          background: $white;
        }
      }
    }
    .planner-card-wrapper:nth-child(1) {
      h5 {
        max-width: 100%;
        // max-height: 1.5rem;
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

      }

    }
  }
}

@media (max-width: 820px) {
  .create-plan-tab {
    .post-planner-detail {
      margin-bottom: 2rem;
      .youtube-icon{
        margin-top: 1.5rem;
      }
      .planner-text-start{
        font-size: 1.2rem;
        max-width: 21.1rem;
      }
    }
    .planner-disclaimer{
      // margin-top: 0.5rem;
      .disclaimer-icon{
        margin-right: 0 !important;
      }

    }

    .planner-feeds-wrapper {
      margin-bottom: 2rem;
    }
    .Social-media-frame {
      padding: 0.5rem 0.5rem;
      border-radius: 0.15rem;
      height: 1.5rem;
      width: 1.5rem;
      // gap: 0.4rem;
      display: flex;
      justify-content: center;
      align-content: center;
      margin-right: 0.4rem;
      margin-bottom: 0;
      // margin-left: 0.2rem;
    }
    .feeds-card-dropdown {
      margin-top: 2.8rem;
      .dropdown-btn {
        max-height: 2.6rem;
        .dropdown-image {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
      .social-icons {
        gap: 0.4rem;
      }
    }
    .post-plannar-button {
      margin-top: 2rem;
    }
    .checkbox-container {
      .cobrand-custom-checkbox {


        margin-right: 0rem;

      }
    }

    .planner-cards-main {
      margin-top: 2.5rem;

      .planner-card-wrapper {
        padding: 1rem 1.1rem;
        margin-bottom: 2.2rem;
       max-height: 20.5rem;

        .card-first {
          // width: 13rem;
          min-height: 13rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          border-radius: 0.6rem;
          cursor: pointer;
          .first-image {
            max-height: 4rem;
            max-width: 4rem;
          }
        }
        .card-image {
          .card-img {
            height: 6.6rem;
          }

          .card-plus {
            height: 6.6rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .planner-card-icons {
            // margin-bottom: 0.2rem;
            top: 0.4rem;
            left: 0.4rem;
            .card-icon {
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
        .post-icon {
          height: 1.6rem;
          width: 1.6rem;
        }
        .date-range {
          font-size: 1rem;
        }
        .view-playlist {
          bottom: 0.5rem !important;
          right: 0.5rem !important;
          cursor: pointer;
          .preview-image {
            height: 1.5rem;
            width: 1.5rem;
          }

          .active-status {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 0.8rem;
            background-color: $white;
            text-align: center;
            border-radius: 0.2rem;
            max-height: 2rem;
            margin-right: 0.8rem;
          }
        }

        .card-content {
          margin-top: 0.8rem;

          h5 {
            // max-height: 2.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-width: 11rem;
            overflow: hidden;
            white-space: wrap;
     text-overflow: ellipsis;
          margin-bottom: 0;
        line-height: 2.2rem;

          }
          .btn-wrapper{

                  .custom-btn{

                    text-align: start;
                    padding: 0.5rem 0.2rem;
                    border-radius: 0.4rem;
                    // max-width: 22.8rem !important;
                    max-height: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 100% !important;
                    margin-bottom : 0.8rem;
                    cursor: "pointer";
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 1rem;

                  }
                }
        }

        hr {
          margin: 0.4rem 0 0.4rem 0;
          max-width: 10.5rem;
        }

        .card-icon {
          margin-bottom: 0.8rem;
        }

        .post-card-dropdown {
          padding: 0.5rem 0.4rem;
          width: 9.5rem;
          height: 2.6rem;
          .dropdown-icon {
            width: 1.7rem;
            height: 1.7rem;
          }
          // border: 1px solid $grey-1D1 !important;
        }
        .user-logo {
          height: 2.6rem;
          width: 2.6rem;
        }
      }
    }
  }
}


//CSS for CREATE PLANNER MODEL
.planner-custom-model {
  .modal-dialog {
    max-width: 100%;
    height: fit-content;
    top: 6rem;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 85vh;
    display: flex;
    z-index: 99999;
  }

  .create-planner-body {
    padding: 4.6rem 4rem 1rem 4rem;
    position: relative;
    .create-cross-icon {
      cursor: pointer;
    }


    p {
      padding-bottom: 4rem;
    }

    form label {
      font-size: 1.8rem;
      color: $black-242;
      // padding-bottom: 2rem;
      margin-bottom: 0;
    }
    .customization{
      h4{
        margin-bottom: 1.6rem;
      }
      p{
        padding-bottom: 0 !important;

      }
      .custom-radio {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid #ccc;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        outline: none;
      }

      .custom-radio:checked {
        border-color: darkblue; /* Border color when selected */
      }
      .custom-radio:checked::after {
        content: '';
        width: 9px; /* Adjust size for inner dot */
        height: 9px;
        border-radius: 50%;
        background-color: darkblue; /* Dark blue inner circle when selected */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .auto{
        margin: 0 5rem 0 0.8rem;

      }


    }
    .auto-text{
      max-width: 60rem;
      margin-bottom: 3rem;
      margin-top: 1rem;
    }
    hr{
      margin-bottom: 3rem;
    }
    .compliance-check{
      .compliance-text{
        font-size: 1.2rem;
        font-weight: 500;

      }
      p{
        padding-bottom: 0 !important;
      }
    }

    .content-checkbox {
      h5 {
        opacity: 50%;
        padding-bottom: 2rem;
      }

      h4 {
        padding-bottom: 2rem;
      }

      .form-checkbox {
        .form-checkbox-item {
          display: inline-flex;
          width: 25%;
          align-content: center;

          // margin-bottom: 2.2rem;
          .cobrand-custom-checkbox {
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 0.6rem;
            cursor: pointer;

            user-select: none;
            img {
              display: block;
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
          .platform-text {
            margin-left: 1.2rem;
          }

          .platform-image {
            height: 2rem;
            width: 2rem;
            border: 1px solid $grey-ECE;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1.2rem;
            // margin-right: 0.8rem;
          }
        }
      }
    }
    .compliance-text{
      // margin-bottom: 3rem;
    }
    .create-modal-bottom{
      margin-top: 3rem;


     .delete-plan{

      border : 1px solid $black-4;
      padding: 1rem 2.4rem 1rem 1.6rem;
      border-radius: 0.8rem;
      max-height: 4rem;

     }

    .create-modal-btn {
       max-height: 4rem;
       text-align: center;

      .primary-btn {
        display:flex;
        align-items: center;
       padding: 1rem 2.4rem !important;
        border-radius: 0.8rem;
      }
     .secondary-btn {
      display:flex;
      align-items: center;
      padding: 1rem 2.4rem !important;
        border-radius: 0.8rem;
      }
    }
  }
}
}

@media (max-width: 820px) {
  .planner-custom-model {
    padding-left: 0 !important;
    .modal-dialog {
      max-width: 100%;
      height: auto;
      top: 1rem;
    }

    .create-planner-body {
      margin: 2rem 0;


      p {
        padding-bottom: 3rem;
        // font-size: 1.6rem;
      }
      .custom-radio {



      }
      .auto-text{
        margin-bottom: 2.4rem;
      }

      .custom-radio:checked {
        border-color: darkblue; /* Border color when selected */
      }
      .custom-radio:checked::after {
        content: '';
        width: 10px !important; /* Adjust size for inner dot */
        height: 9px !important;

      }
      hr{
        margin-bottom: 2.4rem;
      }

      .content-checkbox {
        h5 {
          opacity: 50%;
          padding-bottom: 2rem;
        }

        h4 {
          padding-bottom: 2rem;
        }

        .form-checkbox {
          display: flex;
          flex-direction: column;
          .form-checkbox-item {
            display: flex;
            width: 100%;

            // margin-bottom: 2.2rem;
            .cobrand-custom-checkbox {
              width: 2.2rem;
              height: 2.2rem;
              border-radius: 0.6rem;
              cursor: pointer;

              user-select: none;
              img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
            p {
              margin-left: 0.5rem;
            }

            .platform-image {
              height: 2rem;
              width: 2rem;
              border: 1px solid $grey-ECE;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 1.2rem;
              // margin-right: 0.8rem;
            }
          }
        }
      }
    }
  }
  .create-modal-bottom{
    margin-top: 3rem;
    position: sticky;
    bottom: 0;
    background-color: $white;
padding: 1.5rem 0;
width: 100%;
display: flex;
justify-content: center ;
align-items: center;
.delete-plan{

  border : 1px solid $black-4;
  padding: 1rem 1.2rem 1rem 1.2rem;
  border-radius: 0.8rem;
  max-height: 4rem;
  margin-left: 2rem;

 }
.create-modal-btn-sm {
  margin-right: 2rem;
.secondary-btn{
  padding: 1rem 2.4rem ;
  border-radius: 0.8rem;
  min-width: 9rem;

  font-size: 1.4rem;
  margin-right: 1rem;


}
.primary-btn{
  padding: 1rem 2.4rem ;
 min-width: 9rem;

  border-radius: 0.8rem;
  font-size: 1.4rem;
}
}
  }
}



//styling for scheduled plan tab

.scheduled-plan-tab {
  padding: 2.5rem 0 19.8rem 0;

  .scheduled-plan-heading {
    h5 {
      padding-bottom: 1.2rem;
      margin-bottom: 0;
    }
  }
  .schedule-btn-wrapper {
    gap: 0.6rem;
    display: flex;

    .scheduled-plan-btn {
      padding: 0.2rem 0.4rem;
      border-radius: 0.4rem;
      text-align: center !important;
      background-color: $grey-ECE !important; // Default background color
      color: grey;
      border: none !important;
      margin-right: 1rem;

      &.active {
        background-color: $green-5DF !important;
        color: black !important;
        border: none !important;
        outline: none !important;
      }
      &:focus,
      &:active {
        outline: none !important;
        border: none !important;
        box-shadow: none !important; // Remove any box shadow that might appear on focus or active state
      }
    }
  }
  .icons-wrapper {
    .icons-small {
      border: 0.5px solid $grey-ECE;
      display: inline-block;
      height: 2.4rem;
      width: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.4rem;
      background-color: $grey-ECE !important; // Default background color
      color: grey;
      &.active {
        background-color: $green-5DF !important;
        color: black !important;
        border: none !important;
        outline: none !important;
      }
    }

    .icon {
      display: block;
    }
  }

  .scheduled-content {
    max-width: 66.5rem;
    // padding-bottom: 2rem;
  }

  .date-wrapper {
    margin: 2rem 0 0 0;

    p {
      padding-left: 0.8rem;
    }
  }

  .alert-danger {

    padding:  1.6rem;
    margin-bottom: 0;
    border-radius: 0.8rem;
    margin-top: 2rem;
    max-height: fit-content;



.content-details{
  margin-left: 1.2rem;
}
.primary-btn{
  border-radius: 0.8rem !important;
  cursor: pointer;
}

  }
  .schedule-text {
    margin: 2rem 0 2rem 0;
  }
.filter-wrapper{
 margin-bottom: 2rem;
 .checkbox-container {
  .cobrand-custom-checkbox {
    min-width : 2rem ;
    width: 2rem;
    height: 2rem;
    border-radius: 0.6rem;
  }
  .border-white {
    border: 2px solid $white !important;
  }
  .border-black {
    border: 2px solid $black-242 !important;
  }
}
  .feeds-card-dropdown {
    .dropdown-btn {
      border: 1px solid $grey-2 !important;
      max-height: 3.4rem;
      border-radius: 0.8rem;
    }

    .dropdown-icon {
      margin-left: 0.7rem;
      font-size: 10rem;
    }
  }
}

  .select-menu{
    position: absolute;
    top: '50px';
     right: '0';
     z-Index: '1000';
      width: "18rem" !important;
      background-color: 'white';
       box-shadow: '0px 4px 12px rgba(0, 0, 0, 0.1)';
        border-radius: '8px';
              padding: '10px';



  }



  .schedule-cards {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 0 0.8rem;

    .scheduled-content-wrapper {
      .checkbox-container {
        .cobrand-custom-checkbox {
          min-width: 2rem;
          width: 2rem;
          height: 2rem;
          border-radius: 0.6rem;
        }
        .border-white {
          border: 2px solid $white !important;
        }
        .border-black {
          border: 2px solid $black-242 !important;
        }
      }
      .scheduled-item {
        padding: 1.6rem 0;
        border-bottom: 1px solid rgba(236, 236, 236, 1);

        .schedule-card-image {
          // position: relative;
          .thumbnail-image{
            position: relative;
            height: 8rem;
            width: 8rem;

          }
          .card-plus {
            position: relative;
            height: 8rem;
            width: 8rem;
            // border: 1px solid black;
            background-color: $green-A5F;
            border-radius: 0.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
          }
          .preview-image {
            .view-detail {
              position: absolute;
              bottom: 0.4rem !important;
              right: 0.6rem !important;
              cursor: pointer;
            }
          }
          .premium-icon {
            .view-detail {
              position: absolute;
              top: 0.4rem !important;
              left: 0.6rem !important;
              cursor: pointer;
            }
          }
        }
        .upload-card-image{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2.9rem;
          background-color: $grey-ECE;
          border: 1px dashed $black-4;
          border-radius: 1.2rem;

        }

        .scheduled-content-text {
          margin-left: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;
        }

        .planner-title-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.5rem;
          .primary-btn{
            border-radius: 0.8rem;
            padding: 0.5rem 1rem !important;
          }

          .icon-delete {
            margin-left: 2.4rem;
          }
        }

        .tag-planner-text {
          background: rgba(236, 236, 236, 1);
          padding: 0.2rem 0.8rem;
          border-radius: 0.2rem;
          margin-right: 0.8rem;
          margin-bottom: 0.8rem;
        }

        .social-icons-wrapper {
          margin: 0 0 0 0.8rem;
          border: 0.5px solid $grey-ECE;
          padding: 0.6rem;
        }

        .dot-icon {
          margin-right: 0.4rem;
        }

        .alert-text {
          margin-left: 3.6rem;
        }
      }
    }
  }

  .planner-break-line {
    height: 0.1rem;
    opacity: 50%;
    background-color: $black-4;
    margin: 2rem 0 0 0;
  }


}

@media (max-width: 820px) {
  .scheduled-plan-tab {
    .alert-danger{
      .primary-btn{
        margin-top: 1.2rem !important;
        margin-left: 3rem;
        width: fit-content !important;
        padding: 0.5rem 1.6rem !important;
      }
     }
    .schedule-cards {
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);
      padding: 0 0.8rem;

      .scheduled-content-wrapper {
        .scheduled-item {
          // padding: 1.6rem 0;
          border-bottom: 1px solid rgba(236, 236, 236, 1);

          .scheduled-content-text {
            margin-left: 0.4rem;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            flex-grow: 1;
          }

          .planner-title-container {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            margin-bottom: 0.2rem;
            .primary-btn{
              font-size: 1rem;
              padding: 0.1rem 1.2rem !important;
              max-height: 2.5rem;
              overflow: hidden;
              text-align: center !important;
              white-space: nowrap;

            }

            .icon-delete {
              margin-left: 2.4rem;
            }
          }

          .schedule-card-image {
            position: relative;
            .card-plus {
              .preview-image {
                bottom: 0.5rem !important;
                right: 0.5rem !important;
                cursor: pointer;
                .view-detail {
                  height: 1.5rem !important;
                  width: 1.5rem !important;
                }
              }
              .premium-icon {
                top: 0.5rem !important;
                left: 0.5rem !important;
                cursor: pointer;
                .view-detail {
                  height: 1.5rem !important;
                  width: 1.5rem !important;
                }
              }
            }
          }

          .tag-planner-text {
            background: rgba(236, 236, 236, 1);
            padding: 0.2rem 0.8rem;
            border-radius: 0.2rem;
            margin-right: 0.8rem;
            margin-bottom: 0.1rem;
          }

          .social-icons-wrapper {
            margin: 1.2rem 0 0 0.8rem;
            border: 0.5px solid $grey-ECE;
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

//styling for history planner tab
.planner-history-wrapper {
  padding: 2.5rem 0 2.5rem 0;

  .planner-history-heading {
    padding: 0 0 2rem 0;
  }
  .scheduled-plan-btn {
    padding: 0.2rem 0.4rem;
    border-radius: 0.4rem;
    text-align: center !important;
    background-color: $grey-ECE !important; // Default background color
    color: grey;
    border: none !important;
    margin-right: 1rem;

    &.active {
      background-color: $green-5DF !important;
      color: black !important;
      border: none !important;
      outline: none !important;
    }
    &:focus,
    &:active {
      outline: none !important;
      border: none !important;
      box-shadow: none !important; // Remove any box shadow that might appear on focus or active state
    }
  }
  .icons-wrapper {
    .icons-small {
      border: 0.5px solid $grey-ECE;
      display: inline-block;
      height: 2.4rem;
      width: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.4rem;
      background-color: $grey-ECE !important; // Default background color
      color: grey;
      &.active {
        background-color: $green-5DF !important;
        color: black !important;
        border: none !important;
        outline: none !important;
      }
    }

    .icon {
      display: block;
    }
  }

  .scheduled-content {
    max-width: 66.5rem;
    // padding-bottom: 2rem;
  }

  .date-wrapper {
    margin: 2rem 0 0 0;

    p {
      padding-left: 0.8rem;
    }
  }
  .schedule-cards {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 0 0.8rem;

    .scheduled-content-wrapper {
      .scheduled-item {
        padding: 1.6rem 0;
        border-bottom: 1px solid rgba(236, 236, 236, 1);
        .history-card-image {
          position: relative;
          .thumbnail-image{
            position: relative;
            height: 8rem;
            width: 8rem;

          }
          .card-plus {
            position: relative;
            width: 8rem;
            height: 8rem;
            // border: 1px solid black;
            background-color: $green-A5F;
            border-radius: 0.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            // margin-right: 2rem;
          }
          .preview-image {
            position: absolute;
            bottom: 0.6rem;
            right: 0.6rem;
            cursor: pointer;
          }
          .premium-icon {
            position: absolute;
            top: 0.6rem;
            left: 0.6rem;
            cursor: pointer;
          }
        }
        .scheduled-content-text {
          margin-left: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;
        }

        .planner-title-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.8rem;

          .icon-delete {
            margin-left: 2.4rem;
          }
        }

        .tag-planner-text {
          background: rgba(236, 236, 236, 1);
          padding: 0.2rem 0.8rem;
          border-radius: 0.2rem;
          margin-right: 0.8rem;
          margin-bottom: 0.8rem;
        }

        .social-icons-wrapper {
          margin: 0 0 0 0.8rem;
          border: 0.5px solid $grey-ECE;
          padding: 0.6rem;
        }

        .dot-icon {
          margin-right: 0.4rem;
        }

        .alert-text {
          margin-left: 3.6rem;
        }
      }
    }
  }

  .planner-break-line {
    height: 0.1rem;
    opacity: 50%;
    background-color: $black-4;
    margin: 2rem 0 0 0;
  }
}
@media (max-width: 820px) {
  .planner-history-wrapper {
    .schedule-cards {
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);
      padding: 0 0.8rem;

      .scheduled-content-wrapper {
        .scheduled-item {
          // padding: 1.6rem 0;
          border-bottom: 1px solid rgba(236, 236, 236, 1);

          .scheduled-content-text {
            margin-left: 0.4rem;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            flex-grow: 1;
          }

          .planner-title-container {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            // margin-bottom: 0.2rem;

            .icon-delete {
              margin-left: 2.4rem;
            }
          }

          //   .schedule-card-image{
          //     position: relative;

          //   .preview-image{
          //     position: absolute;
          //     bottom:0.9rem;
          //     right: 0.6rem;
          //     cursor: pointer;
          //     height: 1.8rem;
          //     width: 1.8rem;
          //   }
          // }
          .tag-planner-text {
            background: rgba(236, 236, 236, 1);
            padding: 0.2rem 0.8rem;
            border-radius: 0.2rem;
            margin-right: 0.8rem;
            margin-bottom: 0.3rem;
          }

          .social-icons-wrapper {
            margin: 1.4rem 0 0 0.8rem;
            border: 0.5px solid $grey-ECE;
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
          }

          .dot-icon {
            margin-bottom: 0;
          }
          .preview-image {
            position: fixed;
          }
        }
      }
    }
  }
}

//styling for edit post modal
.edit-post-modal {
  .modal-dialog {
    .modal-body {
      padding: 3rem 4rem;
    }
  }

  margin-top: 3rem;

  .edit-modal-heading {
    padding: 0 0 2rem 0;

    .date-wrapper {
      margin: 2rem 0 0 0;

      p {
        padding-left: 0.8rem;
      }
    }
  }

  .edit-modal-main {
    display: flex;
    flex-direction: row;

    .edit-schedule-image {
      width: 20rem;
      height: 20rem;
      margin-right: 2rem;
    }

    .card-plus {
      position: relative;
      width: 20rem;
      height: 20rem;
      // border: 1px solid black;
      background-color: $green-A5F;
      border-radius: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      margin-right: 2rem;
    }
  }

  .branding-color-language-frame {
    .dropwon-component-wrapper1 {
      gap: 2.4rem;
      margin-right: 4rem;
    }
  }
  .position-input{
    margin-top: 2.4rem;
    display: flex;
    align-items: center;
    position: relative;
    input{
      max-width: 15.5rem;
      max-height: 3.6rem;
    }
    .position-select{
      position: absolute;
      right: 5.5rem;

    }
  }

  .replace-dropdown {
    .dropdown-btn {
      border-radius: 0.8rem;
      border: 1px solid $grey-2 !important;
      padding: 0.9rem 1.3rem;
    }
  }

  .social-media-edit {
    gap: 1rem;

    .social-icons-details {
      padding-bottom: 3rem;
      padding: 0 0 3rem 0;
      // width: 8rem;
      // border: 1px solid black;

      .social-icons-wrapper {
        border: 0.5px solid $grey-ECE;
        padding: 0.6rem;
      }
    }
  }

  .time-selector {
    // width: 3.5rem;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #000;

    /* Border only at the bottom */
    -webkit-appearance: none;
    /* Removes the up/down arrows in WebKit browsers */
    -moz-appearance: textfield;
    /* Removes the up/down arrows in Firefox */
  }

  .time-selector::-webkit-inner-spin-button,
  .time-selector::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .time-selector:focus {
    outline: none;
    /* Remove outline on focus */
    border-bottom: 1px solid #000;
    /* Optional: Change border color on focus */
  }

  .timing-wrapper {
    gap: 0.5rem;
    height: 8rem;

    .timings {

      .period-container {
        display: flex;
        width: 5.6rem;
        /* Adjust as needed */
        height: 2.5rem;
        z-index: 0 !important;

        /* Adjust as needed */
        // background: linear-gradient(to right, #A5F5DC 50%, #ECECEC 50%);
        position: relative;
        align-items: center;
        justify-content: space-between;
        border-radius: 1rem !important;
      }

      .period-container span {
        // border: 1px solid $black;
        // background-color: $green-5DF;
        max-width: 2.8rem;
        max-height: 2.4rem;
        // border-radius: 1rem !important;

        cursor: pointer;
        flex: 1;
        text-align: center;

        z-index: 0 !important;
      }

      .vertical-line {
        // width: 2.2rem;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 1;
        height: 100%;
        color: $black;
      }
      .b-left {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }

      .b-right {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }
    }
  }

  .post-details {
    padding: 1.2rem 0 2rem 0;

    .tag-planner-text {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.8rem;
      border-radius: 0.2rem;
      margin-right: 0.8rem;
    }
  }

  .text-area-frame {
    width: 100%;
    border: 1px solid $grey-2;
    border-radius: 0.8rem;
    position: relative;
    padding: 0.7rem 1.2rem 1.4rem;
    margin-bottom: 2rem;

    .text-area {
      border: none;
      outline: none;
      width: 100%;
      height: 7.5rem;
      overflow: hidden;
      text-overflow: ellipsis;

      &::placeholder {
        color: $grey-2;
      }
    }

    .count-text-words {
      position: absolute;
      bottom: 0.6rem;
      right: 1.2rem;
    }
  }

  .edit-modal-tab-btn {
    border-radius: 0.8rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 820px) {
  .edit-post-modal {
    padding-right: 0 !important;
    .social-media-edit {
      .social-icons-details {
        padding: 0 0 0 0;
      }
    }
    .position-input{
      margin-bottom: 2rem;
      margin-right: 3rem;
      input{
      width: 100% !important;
      max-width: 32rem !important;
      }

      .position-select{
        position: absolute;
        right : 2rem;


      }
    }
    .post-details {
      padding: 1.2rem 0 2rem 0;

      .tag-planner-text {
        background: rgba(236, 236, 236, 1);
        padding: 0.2rem 0.8rem;
        border-radius: 0.2rem;
        margin-right: 0.8rem;

      }
      .tag-planner-text-two {
        background: rgba(236, 236, 236, 1);
        padding: 0.2rem 0.8rem;
        border-radius: 0.2rem;
        margin-right: 0.8rem;
        margin-top: 0.5rem;
        width: fit-content;

      }
    }

    .edit-modal-main {
      display: flex;
      flex-direction: column;

      .custom-dropdown-btn {
        min-width: 13.5rem;
      }

      .edit-schedule-image {
        width: 8rem !important;
        height: 8rem;
      }

      .card-plus {
        max-width: 8rem !important;
        height: 8rem;
        margin-right: 0;
      }
    }

    .time-selector {
      // width: 3.5rem;
      font-size: 1.2rem;

    }
    .timing-wrapper{
      height: 3rem;
    }

    .edit-modal-dropdown {
      display: flex;
      flex-direction: row;
    }
  }
}

//styling for delete post modal

.delete-post-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  height: 100vh;
  // margin-left: 8rem;
   .modal-content{
    max-width: 55.6rem;
    border-radius: 1.5rem !important;
   padding: 1.6rem 2rem;



   }
   .modal-dialog{
    .modal-body{
      padding: 0 0 !important;
    }
  }
  .delete-text {
 margin: 1.6rem 0 0 0;
  }
  .delete-modal-tab-btn {
    margin-top: 4rem;
    .primary-btn {
      padding: 1rem 2.4rem;
      max-height: 4rem;
      font-size: 1.4rem;
      border-radius: 0.8rem;
      text-align: center;
      display: flex;
      align-items: center;
    }
    .secondary-btn {
      padding: 1rem 2.4rem;
      max-height: 4rem;
      font-size: 1.4rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
    }
  }
}
@media (max-width: 820px) {
  .delete-post-modal {
    display: flex !important;
    align-items: center !important;
    padding-right: 0 !important;
    margin-left: 0;
    .modal-content{
      max-width: 32rem;
    }
    .modal-dialog {
      width: fit-content;
    }
    .delete-modal-tab-btn {
      display: flex;
      justify-content: center;
      align-items: center !important;
      gap: 1.5rem;
      margin-top: 2rem ;
      .primary-btn{
        font-size: 1.2rem;
      }
    }
  }
}

//styling for performance modal

.performance-post-modal {


  .modal-dialog {
    .modal-body {
      padding: 1.5rem 4rem;
    }
  }
  .card-plus {
    position: relative;
    width: 20rem;
    height: 20rem;
    // border: 1px solid black;
    background-color: $green-A5F;
    border-radius: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    // margin-right: 2rem;
    // margin-left: 1.5rem;
  }

  .social-icons-wrapper {
    height: 4rem;
    width: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    .image-outer {
      border: 0.5px solid $grey-ECE;
      padding: 1rem;
    }
  }

  .centered-table th,
  .centered-table td {
    text-align: center;
    // border: 1px solid black;
  }

  .centered-table th.left-align {
    text-align: left;
  }

  margin-top: 3rem;

  .performance-modal-heading {
    padding: 0 0 2rem 0;

    .date-wrapper {
      margin: 2rem 0 0 0;

      p {
        padding-left: 0.8rem;
      }
    }
  }
  .text-area-frame {
    width: 100%;
    border: 1px solid $grey-2;
    border-radius: 0.8rem;
    position: relative;
    padding: 0.7rem 1.2rem 1.4rem;
    margin-bottom: 2rem;
    .text-area {
      border: none;
      outline: none;
      width: 100%;
      height: 16.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      &::placeholder {
        color: $grey-2;
      }
    }
    .count-text-words {
      position: absolute;
      bottom: 0.6rem;
      right: 1.2rem;
    }
  }

  .edit-modal-main {
    display: flex;
    flex-direction: row;

    .edit-schedule-image {
      width: 18rem;
      height: 20rem;
    }
  }

  .branding-color-language-frame {
    .dropwon-component-wrapper1 {
      gap: 2.4rem;
      // border: 1px solid black;
    }
  }

  .social-icons-details {
    padding-bottom: 3rem;

    .social-icons-wrapper {
      border: 0.5px solid $grey-ECE;
      padding: 0.6rem;
    }
  }

  .timing-wrapper {
    .timings {
      span {
        border: 1px solid $black;
        background-color: $green-5DF;
      }
    }
  }

  .post-details {
    padding: 0 0 1.2rem 0;

    .tag-planner-text {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.8rem;
      border-radius: 0.2rem;
      margin-right: 0.8rem;
    }
  }

  .edit-modal-tab-btn {
    border-radius: 0.8rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 820px) {
  .performance-post-modal {
    .edit-modal-main {
      display: flex;
      // flex-direction: column;
      margin-bottom: 2rem;

      .edit-schedule-image {
        width: 8rem;
        height: 8rem;
      }

      .card-plus {
        width: 8rem;
        height: 8rem;
        margin: 0 1.2rem 0 1.5rem;
      }
    }
    .text-area-frame {
      max-width: 92%;
      border: 1px solid $grey-2;
      border-radius: 0.8rem;
      position: relative;
      margin-left: 1.5rem;
      padding: 0.7rem 1.2rem 1.4rem;
      .text-area {
        border: none;
        outline: none;
        height: 13.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        &::placeholder {
          color: $grey-2;
        }
      }
      .count-text-words {
        position: absolute;
        bottom: 0.6rem;
        right: 1.2rem;
      }
    }

    .branding-color-language-frame {
      .dropwon-component-wrapper1 {
        width: 100%;
        gap: 1rem;
        // border: 0px solid black;
        margin-bottom: 2.4rem;

        .performance-dropdown {
          width: 2rem;
        }
      }
    }

    .social-icons-wrapper {
      .image-outer {
        width: 2.3rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-image {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
    .post-details {
      padding-bottom: 0.5rem;
      height: 2.4rem;
    }
  }
}

//styling for replace post

.replace-post-wrapper {
  // box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);
  // padding: 1.8rem 0.8rem;
  width: 100%;

  .replace-heading{
    // position: relative;
    .finpedia-option-wrapper{
     border: 1px solid $primary;
     border-radius: 1.2rem;
     width: 100%;
     padding: 1.6rem 2.4rem !important;
     box-shadow: 0px 2px 10px -4px #242E3D38;
     ;

    }
    .finpedia-text{
      margin-left: 2rem;
    }

    .custom-radio {
      // position: absolute;
      // left: 22rem;
      appearance: none;
      width: 18px;
      height: 18px;
      border: 2px solid #ccc;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      outline: none;
    }

    .custom-radio:checked {
      border-color: darkblue; /* Border color when selected */
    }
    .custom-radio:checked::after {
      content: '';
      width: 9px; /* Adjust size for inner dot */
      height: 9px;
      border-radius: 50%;
      background-color: darkblue; /* Dark blue inner circle when selected */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .date-wrapper {
    margin: 4rem 0 0 0;

    p {
      padding-left: 0.8rem;
    }
  }
  .custom-upgrade{
    width: 100%;
    padding: 1.2rem 2rem;
    background-color: $grey-ECE;
    border-radius: 0.8rem;
    margin-bottom: 3rem;
    .primary-btn{
      max-width: 8.6rem;
      max-height: 3.2rem;
      padding: 0.5rem 1.6rem !important;
    border-radius: 0.8rem;
    display: flex;
    text-align: center !important;
    align-items: center !important;
    font-size: 1.4rem !important;

    }
  }
  .disabled-div {
    pointer-events: none;
    opacity: 0.6; /* To give a disabled visual effect */
    cursor: not-allowed !important;
  }
    .edit-modal-main {
    display: flex;
    flex-direction: row;

    .replace-schedule-image {
      width: 20rem;
      height: 20rem;
    }

    .card-plus {
      position: relative;
      width: 20rem;
      height: 20rem;
      // border: 1px solid black;
      background-color: $green-A5F;
      border-radius: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      margin-right: 1rem;
      // margin-left: 1.5rem;
    }
  }
  .image-uploader{
    // padding: 3.5rem 6.7rem 2.8rem 6.7rem;
    margin-right: 2rem;

    .image-main{
      border : 1px solid $grey-19;
    // width: 25vw;
    min-width: 40.2rem;
      height: 20rem;
      background-color: $grey-19;
      // opacity: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.2rem;
      text-align: center;
      // margin-left: 1rem;
      .uploaded-image {
        width: 100%;
        height: 300px; /* Adjust this height as needed */
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .uploaded-file {
        // position: relative;
        width: 100%;
        height: 100%;
        display: flex;
  align-items: center;
  justify-content: center;
      }

      .uploaded-video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .delete-icon {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
      }

      .plus-icon{
        border: 1px solid $black-4;

        padding: 2.3rem 2.3rem;
        border-style: dashed;
        display: flex;
        align-items: center;
        border-radius: 1.2rem;
        margin-bottom: 2.9rem;
      }
      .plus-text{
        padding: 0 6.7rem;
      }

    }
    .note-text{
      margin-left: 1.2rem;
      margin-top: 0.5rem;
    }
    .small-images {

      display: flex;
        align-items: center;
        margin-top: 1.2rem;
        max-width: 42.3rem;
        flex-wrap: wrap;
    }

    .small-image-wrapper {
      position: relative;
      margin-right: 1.2rem;
      margin-top: 1rem;


       /* Adds spacing between images */
    }

    .small-images .small-image {
      width: 72px;
      height: 72px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .small-images .small-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.8rem;

    }

    .plus-small-icon {
      cursor: pointer;
      margin-right: 5px;
       display: flex;
       justify-content: center;
       width: 7.2rem;
       height: 7.2rem;
       background-color: $grey-19;

       align-items: center;
       border-radius: 0.8rem;
       margin-top: 1rem;
       margin-right: 0.8rem;
    }

    .delete-icon {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      cursor: pointer;
      border : 1px solid $black-4;
      // opacity: 50%;
      border-radius: 0.4rem;
      background-color: $white;
      /* Semi-transparent background for delete icon */
      padding: 2px;
    }

    .delete-icon img {
      width: 16px;
      height: 16px;
    }

  }

  .branding-color-language-frame {
    .dropwon-component-wrapper1 {
      gap: 2.4rem;
      margin-right: 3rem;
    }
    .position-input{
      margin-top: 2.4rem;
      display: flex;
      align-items: center;
      position: relative;
      input{
        max-width: 15.5rem;
        max-height: 3.6rem;
      }
      .position-select{
        position: absolute;
        right: 4rem;

      }
    }
  }

  .social-media-edit {
    gap: 2.4rem;

    .social-icons-details {
      padding-bottom: 3rem;

      .social-icons-wrapper {
        border: 0.5px solid $grey-ECE;
        padding: 0.6rem;
      }
    }
  }

  .time-selector {
    // width: 3.5rem;
    font-size: 0.9rem;
    border: none;
    border-bottom: 1px solid #000;
    /* Border only at the bottom */
    -webkit-appearance: none;
    /* Removes the up/down arrows in WebKit browsers */
    -moz-appearance: textfield;
    /* Removes the up/down arrows in Firefox */
  }

  .time-selector::-webkit-inner-spin-button,
  .time-selector::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .time-selector:focus {
    outline: none;
    /* Remove outline on focus */
    border-bottom: 1px solid #000;
    /* Optional: Change border color on focus */
  }

  .timing-wrapper {
    gap: 0.5rem;
    height : 8rem;

    .timings {
      .period-container {
        display: flex;
        width: 5.6rem;
        /* Adjust as needed */
        height: 2.5rem;
        /* Adjust as needed */
        // background: linear-gradient(to right, #A5F5DC 50%, #ECECEC 50%);
        position: relative;
        align-items: center;
        justify-content: space-between;
        border-radius: 1rem !important;
      }

      .period-container {
        display: flex;
        width: 5.6rem;
        z-index: 0;
        /* Adjust as needed */
        height: 2.5rem;
        /* Adjust as needed */
        // background: linear-gradient(to right, #A5F5DC 50%, #ECECEC 50%);
        position: relative;
        align-items: center;
        justify-content: space-between;
        border-radius: 1rem !important;
      }

      .period-container span {
        // border: 1px solid $black;
        // background-color: $green-5DF;
        max-width: 2.8rem;
        max-height: 2.4rem;
        cursor: pointer;
        flex: 1;
        text-align: center;

        z-index: 1;
      }

      .b-left {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }

      .b-right {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }
    }
  }

  .post-details {
    padding: 1.2rem 0 2rem 0;

    .tag-planner-text {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.8rem;
      border-radius: 0.2rem;
      margin-right: 0.8rem;
    }
  }

  .text-area-frame {
    width: 100%;
    border: 1px solid $grey-2;
    border-radius: 0.8rem;
    position: relative;
    padding: 0.7rem 1.2rem 1.4rem;
    margin-bottom: 2rem;

    .text-area {
      border: none;
      outline: none;
      width: 100%;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;

      &::placeholder {
        color: $black-4;
      }
    }

    .count-text-words {
      position: absolute;
      bottom: 0.6rem;
      right: 1.2rem;
    }
  }

  .edit-modal-tab-btn {
    border-radius: 0.8rem;
    margin-bottom: 2rem;
  }

  .replace-post-btns {
    max-height: 4rem;
    align-items: center;
    margin-left: 13.8rem;

    .secondary-btn {
      padding: 1rem 2.4rem;
      margin-right: 1rem;
      border-radius: 0.8rem;
    }

    .primary-btn {
      padding: 1rem 2.4rem;
      border-radius: 0.8rem;
    }
  }

  .post-details {
    padding: 1.2rem 0 2rem 0;

    .tag-planner-text {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.8rem;
      border-radius: 0.2rem;
      margin-right: 0.8rem;
    }
  }

  .input-area {
    margin-bottom: 2rem;

    input {
      width: 100%;
      height: 10rem;
      border: 1px solid #ececec;
      border-radius: 0.8rem;
    }
  }

  .select-post-details {
    h5 {
      padding-bottom: 2rem;
    }
  }

  .radio-option-frame {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4.4rem;
  }

  .replace-bottom-container {
    background: rgba(236, 236, 236, 0.3);
    border-radius: 1.2rem;
    padding: 2rem;

    .treandign-search {
      margin: 0 0 0 0;
    }

    .filter-sort-frame {
      padding: 1.2rem 0 0 0;

      .sorted-by {
        background-color: transparent;
      }
    }

    .card-checkbox {
      position: absolute;
      right: 1.2rem;
      top: 1.2rem;

      input {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        border: 2px solid #242424 !important;
      }
    }
  }
}
.replace-post-btns {
  max-height: 4rem;
  align-items: center;
  margin-left: 13.8rem;

  .secondary-btn {
    padding: 1rem 2.4rem;
    margin-right: 1rem;
    border-radius: 0.8rem;
  }

  .primary-btn {
    padding: 1rem 2.4rem;
    border-radius: 0.8rem;
  }
}
@media (max-width: 820px) {
  .replace-post-btns {
    margin-left: 0 !important;
    margin-top: 1rem;
    padding: 4rem 2rem 3rem 2rem ;
    z-index: 9;
    width: 100%;
    margin-bottom: 0!important;
  }

}
@media (max-width: 820px) {


  .replace-post-wrapper {
    // padding: 0.8rem;
    .replace-heading{

      .finpedia-option-wrapper{
        padding: 1.8rem 1.2rem !important;
      }
      .finpedia-text{
        margin-left: 1.2rem;
      }

    }
.date-wrapper{
  margin : 3rem 0 0 0;
}
    .dropwon-component-wrapper1 {
      margin-right: 0rem;
      .custom-dropdown-btn {
        min-width: 13.5rem;
        // margin-bottom: 1.2rem !important;
      }
    }

    .edit-modal-main {
      display: flex;
      flex-direction: column;

      .replace-schedule-image {
        width: 8rem;
        height: 8rem;
      }

      .post-details {
        height: fit-content;
        width: fit-content;
      }

      .card-plus {
        width: 20rem;
        height: 20rem;
      }
      .image-uploader{
        // padding: 3.5rem 6.7rem 2.8rem 6.7rem;
        margin-right: 0rem;

        .image-main{
          border : 1px solid $grey-ECE;
        width: 100%;
        min-width: auto !important;
        margin-bottom: 1.2rem;

          .uploaded-image {
            width: 100%;
            height: 300px; /* Adjust this height as needed */
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }
      .plus-text{
            padding: 0 6.7rem;
          }

        }
        .note-text{
          margin-left: 1.2rem;
          margin-top: 0.5rem;
          margin-bottom: 1.2rem;
        }
        .small-images {

          margin-bottom: 1.2rem;
          margin-left: 1rem;


        }

        .small-image-wrapper {
          position: relative;
          margin-right: 1.2rem;
          margin-top: 1rem;
           /* Adds spacing between images */
        }

        .small-images .small-image {
          width: 60px;
          height: 60px;

        }
        .plus-small-icon {
          cursor: pointer;

           width: 6rem;
           height: 6rem;

        }

    .delete-icon img {
          width: 16px;
          height: 16px;
        }

      }
      .social-icons-details {
        padding-bottom: 0rem;
        padding: 0 0 0 0;

        .social-icons-wrapper {
          border: 0.5px solid $grey-ECE;
          padding: 0.6rem;
        }
      }

      .edit-modal-dropdown {
        display: flex;
        flex-direction: row;
      }
    }
    .branding-color-language-frame{
      margin-bottom: 1.2rem !important;
    .position-input{
      margin-bottom: 2rem;
      margin-right: 3rem;
      input{
      width: 100% !important;
      min-width: 32rem !important;
      }

      .position-select{
        position: absolute;
        right : 2rem;


      }
    }
  }
    .time-selector {
      // width: 3.5rem;
      font-size: 1.2rem;
    }

    .timing-wrapper {
      height: 3rem;
      .timings {
        margin-left: 1.8rem;
        .period-container{
        // margin-right: 1.5rem;
        span {
          // border: 1px solid $black;
          background-color: $green-5DF;
        }
      }
      }
    }

    .card-content {
      margin-top: 2rem;

      .checkbox-container {
        .cobrand-custom-checkbox {
          width: 2.2rem;
          height: 2.2rem;
          border-radius: 0.6rem;

          margin-right: 0.8rem;
          border: 2px solid $black-242 !important;
        }
      }
    }


  }
}

.calendar-view {
  margin-top: 4rem;

  .event-dot {
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-right: 5px;
  }

  .rbc-header {
    font-size: 1.4rem;
    height: 4.6rem;
    color: $black-242;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Event Styling */
  .rbc-event {
    background-color: $primary-02;
    padding: 0.2rem 0.4rem;
    border-radius: 0.4rem;
    color: $black-242;
    max-width: 11.7rem;
    margin-left: 1.4rem;
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
  }

  /* Event Label Dot */
  .rbc-event-label::before {
    content: "•";
    margin-right: 5px;
    color: red;
    font-size: 14px;
  }

  /* Month View */
  .rbc-month-view {
    .rbc-day-bg {
      // max-height: 11.5rem;
    }
    .rbc-day-bg {
      // min-height: 15.5rem;
    }
    .rbc-events-container {
      // min-height: 15.5rem;
    }
    .rbc-month-row {
      .rbc-date-cell {
        display: flex;
        align-items: start;
        margin: 0.9rem 0 0.8rem 1.4rem;
        font-size: 1.2rem;
        font-weight: bolder;
        color: $black-242;
        line-height: 2.4rem;
        cursor: pointer;

        a {
          font-weight: bold;
        }
      }

      .rbc-button-link {
        font-weight: 600;
      }
      .rbc-off-range-bg {
        background-color: $white;
        color: $grey-ECE;
      }
      .rbc-off-range {
        color: $black-242;
        opacity: 0.5;
      }
      /* Current date cell */
      .rbc-current {
        background-color: $white;
        color: $black-242; // Background for the current date
      }
    }
  }

  /* Week and Day Views */
  .rbc-time-view {
    /* Time grid */
    .rbc-time-gutter {
      background-color: $white; // Background for the time gutter
      color: $black-242;
      font-weight: 600;
      font-size: 1.2rem;
    }
    /* Day column */

    /* Timeslot group */
    .rbc-timeslot-group {
    }
  }

  /* Today Indicator */
  .rbc-today {
    background-color: $white; // Background for today's date
  }

  @media (max-width: 820px) {
    .rbc-event {
      padding: 0.2rem 0.1rem;
      margin-left: 0;
      margin-bottom: 0.3rem;
    }
  }
}
.post-modal {
  // margin-top: 5rem;
  padding-right: 0 !important;
  .modal-body {
    padding: 2.7rem 5rem;
  }
  .date-wrapper {
    margin: 0 0 2rem 0;

    p {
      padding-left: 0.8rem;
    }
  }
  .scheduled-item {
    padding: 1.6rem 0;
    border-bottom: 1px solid rgba(236, 236, 236, 1);
    .thumbnail-image{
      position: relative;
      height: 8rem;
      width: 8rem;

    }
    .card-plus {
      position: relative;
      height: 8rem;
      width: 8rem;
      // border: 1px solid black;
      background-color: $green-A5F;
      border-radius: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
    }
    .premium-icon {
      .view-detail {
        position: absolute;
        top: 0.4rem !important;
        left: 0.6rem !important;
        cursor: pointer;
      }
    }
    .scheduled-content-text {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
    }

    .planner-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      .icon-delete {
        margin-left: 2.4rem;
      }
    }

    .tag-planner-text {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.8rem;
      border-radius: 0.2rem;
      margin-right: 0.8rem;
    }


    .social-icons-wrapper {
      margin: 0 0 0 0.8rem;
      border: 0.5px solid $grey-ECE;
      padding: 0.6rem;
    }

    .dot-icon {
      margin-right: 0.4rem;
    }

    .alert-text {
      margin-left: 3.6rem;
    }
  }
  .primary-btn {
    padding: 1rem 2.4rem;
    border-radius: 0.8rem;
    margin-top: 4rem;
  }
}
@media (max-width: 820px) {
  .post-modal {
    padding-right: 0;
    .modal-dialog {
      .modal-body {
        padding: 1.4rem 2.4rem !important;
      }
    }
    .date-wrapper {
      margin: 0 0 1rem 0;

      p {
        padding-left: 0.2rem;
      }
    }
    .scheduled-content-text {
      margin-left: 0.3rem;
    }
    .tag-planner-text {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;
      margin-right: 0.9rem;
      width: fit-content;

    }
    .tag-planner-text-two {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.8rem;
      border-radius: 0.2rem;
      margin-right: 0.8rem;
      margin-top: 0.5rem;
      width: fit-content;
    }
  }
}

//styling for preview modal
.preview-modal {
  margin-top: 5rem;
  .modal-dialog .modal-body {
    overflow: auto;
    padding: 2rem 4rem 4rem 4rem;
  }
  .modal-dialog .modal-body::-webkit-scrollbar {
    display: none;
}

  .preview-image{
    height: 40rem;
    width: 40rem;

  }
  .card-plus {
    // position: relative;
    height: 40rem;
    width: 40rem;
    // border: 1px solid black;
    background-color: $green-A5F;
    border-radius: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
  }
  .preview-text {
    margin-top: 2rem;

  }
}

@media (max-width: 820px) {
  .preview-modal {
    .modal-dialog .modal-body {
      padding: 2rem 2.4rem 5rem 2.4rem !important;
    }
    .cross-icon {
      // margin-bottom: 2rem;
    }
    .preview-image{
      height: 20rem;
      width: 20rem;

    }
    .card-plus {
      width: 20rem;
      height: 20rem;
    }
  }
}

//styling for upload post modal
.upload-post-modal {
  // max-height: 72.2rem;
  .modal-dialog {
    .modal-body {
      padding: 3rem 4rem 1rem 4rem;
    }
  }

  margin-top: 3rem;

  .upload-modal-heading {
    padding: 0 0 2rem 0;


  }

  .upload-modal-main {
    display: flex;
    flex-direction: row;
    margin-top: 2.8rem;

  .image-uploader{
    // padding: 3.5rem 6.7rem 2.8rem 6.7rem;
    margin-right: 3rem;

    .image-main{
      border : 1px solid $grey-19;
    // width: 25vw;
    max-width: 41rem;
      height: 20rem;
      background-color: $grey-19;
      // opacity: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.2rem;
      text-align: center;
      margin-left: 1rem;
      .uploaded-image {
        width: 100%;
        height: 300px; /* Adjust this height as needed */
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .uploaded-file {
        // position: relative;
        width: 100%;
        height: 100%;
        display: flex;
  align-items: center;
  justify-content: center;
      }

      .uploaded-video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .delete-icon {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
      }

      .plus-icon{
        border: 1px solid $black-4;

        padding: 2.3rem 2.3rem;
        border-style: dashed;
        display: flex;
        align-items: center;
        border-radius: 1.2rem;
        margin-bottom: 2.9rem;
      }
      .plus-text{
        padding: 0 6.7rem;
      }

    }
    .note-text{
      margin-left: 1.2rem;
      margin-top: 0.5rem;
    }
    .small-images {

      display: flex;
        align-items: center;
        margin-top: 1.2rem;
        max-width: 42.3rem;
        flex-wrap: wrap;
    }

    .small-image-wrapper {
      position: relative;
      margin-right: 1.2rem;
      margin-top: 1rem;


       /* Adds spacing between images */
    }

    .small-images .small-image {
      width: 72px;
      height: 72px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
    }

    .small-images .small-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.8rem;

    }

    .plus-small-icon {
      cursor: pointer;
      margin-right: 5px;
       display: flex;
       justify-content: center;
       width: 7.2rem;
       height: 7.2rem;
       background-color: $grey-19;

       align-items: center;
       border-radius: 0.8rem;
       margin-top: 1rem;
       margin-right: 0.8rem;
    }

    .delete-icon {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      cursor: pointer;
      border : 1px solid $black-4;
      // opacity: 50%;
      border-radius: 0.4rem;
      background-color: $white;
      /* Semi-transparent background for delete icon */
      padding: 2px;
    }

    .delete-icon img {
      width: 16px;
      height: 16px;
    }

  }

.social-media-edit {
    gap: 1rem !important;

    .social-icons-details {
      padding-bottom: 3rem;
      padding: 0 0 3rem 0;
      // width: 8rem;
      // border: 1px solid black;

      .social-icons-wrapper {
        border: 0.5px solid $grey-ECE;
        padding: 0.6rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .time-selector {
    // width: 3.5rem;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #000;

    /* Border only at the bottom */
    -webkit-appearance: none;
    /* Removes the up/down arrows in WebKit browsers */
    -moz-appearance: textfield;
    /* Removes the up/down arrows in Firefox */
  }

  .time-selector::-webkit-inner-spin-button,
  .time-selector::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .time-selector:focus {
    outline: none;
    /* Remove outline on focus */
    border-bottom: 1px solid #000;
    /* Optional: Change border color on focus */
  }

  .timing-wrapper {
    gap: 0.5rem;

    .timings {

      .period-container {
        display: flex;
        width: 5.6rem;
        /* Adjust as needed */
        height: 2.5rem;
        z-index: 0 !important;

        /* Adjust as needed */
        // background: linear-gradient(to right, #A5F5DC 50%, #ECECEC 50%);
        position: relative;
        align-items: center;
        justify-content: space-between;
        border-radius: 1rem !important;
      }

      .period-container span {
        // border: 1px solid $black;
        // background-color: $green-5DF;
        max-width: 2.8rem;
        max-height: 2.4rem;
        // border-radius: 1rem !important;

        cursor: pointer;
        flex: 1;
        text-align: center;

        z-index: 0 !important;
      }
      .b-left {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }

      .b-right {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }

      .vertical-line {
        // width: 2.2rem;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 1;
        height: 100%;
        color: $black;
      }
    }
  }

  .post-details {
    padding: 1.2rem 0 2rem 0;

    .tag-planner-text {
      background: rgba(236, 236, 236, 1);
      padding: 0.2rem 0.8rem;
      border-radius: 0.2rem;
      margin-right: 0.8rem;
    }
  }

  .text-area-frame {
    width: 100%;
    border: 1px solid $grey-2;
    border-radius: 0.8rem;
    position: relative;
    padding: 0.7rem 1.2rem 1.4rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
    // height: 4rem !important;


    .text-area {
      border: none;
      outline: none;
      width: 100%;

      // height: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;

      &::placeholder {
        color: $grey-2;
      }
    }

    .count-text-words {
      position: absolute;
      bottom: 0.6rem;
      right: 1.2rem;
    }
  }
}

 .upload-bottom{
  display: flex;
  justify-content: end;
  margin-top: 10rem;
  margin-bottom: 2rem;
  .upload-modal-tab-btn{
    .secondary-btn{
      margin-right: 0.8rem;
      margin-left: 2.8rem;
      padding: 1rem 2.4rem;
      border-radius: 1rem;
    }
    .primary-btn{
      padding: 1rem 2.4rem;
      border-radius: 1rem;

    }

  }
 }
}

@media (max-width: 820px) {
  .upload-post-modal {
    padding-right: 0 !important;
    .modal-dialog {
      .modal-body {
        padding: 2rem 2.4rem !important;
      }
    }
    .upload-modal-main {

      margin-top: 2rem;

    .image-uploader{
      // padding: 3.5rem 6.7rem 2.8rem 6.7rem;
      // margin-right: 3.5rem;

      .image-main{
        border : 1px solid $grey-ECE;
      width: 100%;

        .uploaded-image {
          width: 100%;
          height: 300px; /* Adjust this height as needed */
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }






        .plus-text{
          padding: 0 6.7rem;
        }

      }
      .note-text{
        margin-left: 1.2rem;
        margin-top: 0.5rem;
      }
      .small-images {

        margin-bottom: 1.2rem;
        margin-left: 1rem;


      }

      .small-image-wrapper {
        position: relative;
        margin-right: 1.2rem;
        margin-top: 1rem;
         /* Adds spacing between images */
      }

      .small-images .small-image {
        width: 60px;
        height: 60px;

      }
      .plus-small-icon {
        cursor: pointer;

         width: 6rem;
         height: 6rem;

      }






      .delete-icon img {
        width: 16px;
        height: 16px;
      }

    }

  .social-media-edit {
      gap: 1rem;

      .social-icons-details {
        padding-bottom: 0rem;
        padding: 0 0 0rem 0;
        // width: 8rem;
        // border: 1px solid black;

        .social-icons-wrapper {
          border: 0.5px solid $grey-ECE;
          padding: 0.6rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .time-selector {
      // width: 3.5rem;
      font-size: 1rem;
      border: none;
      border-bottom: 1px solid #000;

      /* Border only at the bottom */
      -webkit-appearance: none;
      /* Removes the up/down arrows in WebKit browsers */
      -moz-appearance: textfield;
      /* Removes the up/down arrows in Firefox */
    }

    .time-selector::-webkit-inner-spin-button,
    .time-selector::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .time-selector:focus {
      outline: none;
      /* Remove outline on focus */
      border-bottom: 1px solid #000;
      /* Optional: Change border color on focus */
    }

    .timing-wrapper {
      gap: 0.5rem;

      .timings {

        .period-container {
          display: flex;
          width: 5.6rem;
          /* Adjust as needed */
          height: 2.5rem;
          z-index: 0 !important;

          /* Adjust as needed */
          // background: linear-gradient(to right, #A5F5DC 50%, #ECECEC 50%);
          position: relative;
          align-items: center;
          justify-content: space-between;
          border-radius: 1rem !important;
        }

        .period-container span {
          // border: 1px solid $black;
          // background-color: $green-5DF;
          max-width: 2.8rem;
          max-height: 2.4rem;
          // border-radius: 1rem !important;

          cursor: pointer;
          flex: 1;
          text-align: center;

          z-index: 0 !important;
        }


      }
    }

    .post-details {
      padding: 1.2rem 0 2rem 0;


    }

    .text-area-frame {

      margin-bottom: 1rem;
      margin-top: 1rem;
      // height: 4rem !important;


      .text-area {
        border: none;
        outline: none;
        width: 100%;

        // height: 4rem;
        overflow: hidden;
        text-overflow: ellipsis;

        &::placeholder {
          color: $grey-2;
        }
      }

      .count-text-words {
        position: absolute;
        bottom: 0.6rem;
        right: 1.2rem;
      }
    }
  }
  .upload-bottom{
    display: flex;
    justify-content: end;
    margin-top: 5rem;
    .upload-modal-tab-btn{
      margin: 2rem 0;



    }
   }

  }
}


//styling for Select Position Modal
.position-modal{
  .modal-dialog {
    .modal-body {
      padding: 3rem 4rem;
      border-radius: 1.2rem;
    }
  }
  .co-branding-leabling {
    .select-position{
      margin-bottom: 1.6rem;
    }
    .start-label-frame {
      display: flex;
      gap: 0.8rem;
      margin-top: 1rem;
      border-radius: 0.8rem;
      background: $grey-8;
      padding: 1rem 1.2rem 1.5rem;
      .check-box-text-frame {
        width: 100%;
        .start-logo-frame {
          margin-top: 1.2rem;
          padding: 1.6rem 0 0;
          border-top: 0.1rem solid $grey-2;
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
        }
      }
    }
    .branding-color-language-frame {
      margin-top: 1.6rem;
      .dropwon-component-wrapper {
        margin-top: 0.8rem;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1.2rem;
      }
    }
  }
  .select-modal-tab-btn{
    margin : 1.6rem 0;
    .primary-btn{
      border-radius: 0.8rem;
      padding: 1rem 5rem !important;

    }
    .secondary-btn{
      border-radius: 0.8rem;
      padding: 1rem 4rem !important;
    }
  }
}

@media (max-width: 820px) {
  .position-modal{
    padding-left: 0 !important;
  .modal-dialog{
    .modal-body{
      padding: 2rem 2.4rem !important;
    }
  }
  }
}


//Preview Carousel Css

.planner-slider {
  position: relative;
  width: 800px ;


  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 100px;
    width: 100px;
    // outline: black;
    background-size: 100%, 100%;
    border-radius: 0%;
    // border: 1px solid black;
    background-image: none;

  }

  .carousel-control-next-icon:after
  {
    content: '>';
    font-size: 50px;
    color: black;
  }

  .carousel-control-prev-icon:after {
    content: '<';
    font-size: 50px;
    color: black;
  }
  .carousel-control {
    width: 0%
}
.carousel-control-prev {
  left: -80px; /* Adjust this to move the left arrow outside */
}

.carousel-control-next {
  right: -80px; /* Adjust this to move the right arrow outside */
}


.preview-image {
    height: 400px;

    object-fit: contain; /* Ensures images maintain aspect ratio */
}

}

@media (max-width: 820px) {
  .planner-slider {
  width: auto;

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      height: 100px;
      width: 100px;
      // outline: black;
      background-size: 100%, 100%;
      border-radius: 0%;
      // border: 1px solid black;
      background-image: none;
    }

    .carousel-control-next-icon:after
    {
      content: '>';
      font-size: 25px;
      color: black;
    }

    .carousel-control-prev-icon:after {
      content: '<';
      font-size: 25px;
      color: black;
    }
    .carousel-control {
      width: 0%
  }
  .carousel-control-prev {
    left: -40px; /* Adjust this to move the left arrow outside */
  }

  .carousel-control-next {
    right: -40px; /* Adjust this to move the right arrow outside */
  }


  .preview-image {
      height: 400px !important;


      object-fit: contain; /* Ensures images maintain aspect ratio */
  }

  }
}

