.events-card-wrapper {
  display: flex;
  align-items: center;
  // justify-content: flex-start;
  gap: 3rem;
  flex-wrap: wrap;
  padding: 1rem 0 5rem;
}

.link-text {
  padding: 1rem 0;
  margin: 3rem 0;
  text-align: center;
  background-color: $grey-ECE;

  a {
    max-width: 33rem;
    width: 60%;
  }
}

.event-card {

  width: 38.5vw;
  min-width: 42rem;
  max-width: 56.4rem;
  min-height: 42.5rem;
  height: fit-content;
  border-radius: 2.1722rem;
  border: 1.81px solid $grey-ECE;
  background: $white;
  overflow: hidden;
  cursor: pointer;
}

.event-image-box {
  width: 100%;
  height: 24.2rem;
  overflow: hidden;
  position: relative;

  .thumbnail-imag-framez {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    .bg-event-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .event-actions-frame {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 4.7rem 2.4rem 1.1rem;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: space-between;
    // opacity: 0.5;
    background: linear-gradient(180deg, $black-8 0%, $white 100%);

    .event-attend {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .event-rggister {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      button {
        height: 37.601px;
        padding: 0 2.4 2.4;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .save-frame {
        border-radius: 9.4px;
        border: 1.175px solid $black-4;
        display: flex;
        width: 37.601px;
        height: 37.601px;
        justify-content: center;
        align-items: center;

        .icon {
          font-size: 18px;
        }
      }
    }
  }

  .event-mode {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.8rem 2.4rem;
  }
}

.event-content-frame {
  padding: 1.5rem 2.5rem;

  h5,
  p {
    margin-bottom: 6px;
  }
  .speaker-names{
    display: -webkit-box;
  -webkit-line-clamp: 2; /* limits the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* adds an ellipsis at the end if text overflows */
  white-space: normal;
  }
}

// ?================ event details css start =========================
.event-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .event-register-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .disable-btn,
    .primary-btn {
      height: 4rem;
      padding-top: 0.7rem !important;
      border-radius: 0.8rem;
    }
  }
}

.event-info-wrapper {
  padding: 4rem 0;
}

.light-black {
  color: $black-4;
}

.event-detail-profile-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;

  img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
  }
}
.inperson-icon-chip,.offline-icon-chip{
  display:flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  top: 2rem;
  left: 1.9rem;
  width: fit-content;
  padding: .4rem .7rem;
  border-radius: .4rem;
  background-color:$grey-ECE;
  // margin-bottom: 0.5rem;

}
.offline-icon-chip{
  background-color:$green-ABF;
}
.event-details-poster {
  margin: 19px 0;
  width: 100%;
  border-radius: 8px;
  position: relative;

  .event-details-image {
    width: 100%;
    height: 100%;
    max-height: 30rem !important;
    object-fit: cover;
    border-radius: 8px;
  }

  .offline-icon {

  }

  .attendees-frame {
    position: absolute;
    bottom: 2rem;
    left: 1.9rem;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid $black-4;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    padding: 0.4rem 0.8rem;
    // margin-top: 0.5rem;
  }
}
.new-event-card-body {
  height: 100%;
  width: 100%;
  padding: 1.4rem 0;

  p {
    @include font($black-242, "lato-SemiBold", 1.4rem, 500, normal);
    margin-bottom: 1rem;
    color: $black-242 !important;
    word-wrap: break-word; // This will break the word to the next line if it's too long
    word-break: break-all; // This breaks words at arbitrary points to prevent overflow
    overflow-wrap: break-word;
    span {
      background-color: transparent !important;
      @include font($black-242, "lato-SemiBold", 1.4rem, 500, normal);
    }
  }
}

.event-keys-wrapper {
  margin-bottom: 3rem;
  flex-wrap: wrap;

  .key-words-frame {
    border-radius: 0.8rem;
    border: 1px solid $grey-ECE;
    padding: 0.6rem 0.8rem;
  }
}

.overflow-xauto {
  overflow-x: scroll;
  flex-wrap: nowrap;
  justify-content: start;
  padding: 1rem 0 3rem;
  scrollbar-width: none;
    -ms-overflow-style: none;
  &::-webkit-scrollbar {
    appearance: none;
    display: none;
  }
}

.res-event-card {
  min-width: 50rem;
}

.ellipsis-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.underline {
  text-decoration: underline;
}

.text-start {
  text-align: start;
}

.obj-cover {
  object-fit: cover;
}

.speaker-card-frame {
  padding: 3rem 0;
  display: flex;
  align-items: start;
  justify-content: start;
  overflow: hidden;
  gap: 3rem;
  scrollbar-width: none;
    -ms-overflow-style: none;
  // &::-webkit-scrollbar {
  //   appearance: none;
  //   height: 0.7rem;
  //   cursor: pointer;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 0.4rem;
  //   background-color: $grey-6;
  //   cursor: pointer;
  // }

  .speaker-box {
    width: 370px;
    min-width: 370px;
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid $grey-ECE;
    background: $white;
    text-align: center;

    img {
      width: 152px;
      height: 152px;
      border-radius: 50%;
    }
  }
}

.mw-500 {
  max-width: 50rem;
  width: 100%;
}

.sticky-event-detailse-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 0.7rem rgba(116, 116, 116, 0.3);
}

.sticky-frame-footer {
  display: flex;
  align-items: start;
  justify-content: space-between;

  .stiky-footer-rifht {
    max-width: 50rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;

    .h-40 {
      height: 4rem;
      display: flex;
      align-items: center;
      border-radius: 0.8rem;
      // padding-top: .7rem !important;
    }

    .primary-btn {
      height: 4rem;
      cursor: pointer;
      border-radius: 0.8rem;
      padding-top: 0.7rem !important;
    }
  }
}

// ?================ event details css end =========================
//
//
//
// ?============== EVENT CHECKOUT PAGE CSS START ================
.event-checkout-section {
  background: linear-gradient(to right, $white 44.9%, $grey-ECE 45%);
}
.black-6C7 {
  color: #6c757d;
}
.mb-28-12 {
  margin-bottom: 28px;
}
.event-checkout-frame {
  display: flex;
  align-items: center;
  justify-content: center;

  .eve-check-form {
    width: 100%;
    max-width: 50rem;
    background-color: $white;
    padding: 6rem 3rem 3rem 0;
    h2 {
      margin: 2rem 0 4rem;
    }
  }
  .eve-booking-summary {
    width: 100%;
    max-width: 66.8rem;
    background-color: $grey-ECE;
    padding: 6rem 0 9.1rem clamp(1rem, 9%, 10rem);
    .event-card-box {
      background-color: $white;
      border-radius: 1.6rem;
      max-width: 56.5rem;
      width: 100%;
      .event-img-box {
        width: 100%;
        height: 24rem;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
        }
      }
      .event-card-content {
        padding: 1.7rem 2.2rem;
        list-style: none;
        li {
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}
.input-box-wrapper {
  label {
    margin-bottom: 0.8rem;
  }
  .input-box {
    border-radius: 0.8rem;
    border: 0.1rem solid #79747e;
    // opacity: 0.5;
    background: $white;
    padding: 0.6rem 1.2rem;
    width: 100%;
    max-width: 100%;
    height: 38px;
  }
}
// ?============== EVENT CHECKOUT PAGE CSS END ================
//
//
//
//
//
// ?============== EVENT CHECKOUT PAGE CSS START ================
.event-checkout-main-section {
  padding: 6.5rem 0 0;
}

.event-checkout-section {
  background: linear-gradient(to right, $white 44.9%, $grey-ECE 45%);
}

.black-6C7 {
  color: #6c757d;
}

.mb-28-12 {
  margin-bottom: 28px;
}

.event-checkout-frame {
  display: flex;
  justify-content: center;

  .eve-check-form {
    width: 100%;
    max-width: 50rem;
    background-color: $white;
    padding: 6rem 3rem 3rem 0;

    h2 {
      margin: 2rem 0 4rem;
    }

    .refund-policy-frame {
      margin-top: 30rem;
    }
  }

  .eve-booking-summary {
    width: 100%;
    max-width: 66.8rem;
    background-color: $grey-ECE;
    padding: 6rem 0 9.1rem clamp(1rem, 9%, 10rem);

    .event-card-box {
      background-color: $white;
      border-radius: 1.6rem;
      overflow: hidden;
      max-width: 56.5rem;
      width: 100%;

      .event-img-box {
        width: 100%;
        max-height: 24rem;
        overflow: hidden;

        img {
          object-fit: cover !important;
          width: 100% !important;
        }
      }

      .event-card-content {
        padding: 1.7rem 2.2rem;
        list-style: none;

        li {
          margin-bottom: 0.8rem;
        }
      }
    }

    .aply-coupon-code {
      margin: 32px 0;
      max-width: 320px;
      width: 100%;

      .coupon-frame-box {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .coupon-box {
          border-radius: 0.8rem;
          border: 1px solid $black-242;
          opacity: 0.5;
          background: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.1rem;
          padding: 0.6rem 1rem;

          .coupon-input {
            width: 100%;
            border: none;
            outline: none;
          }
        }

        .primary-btn {
          height: 3.9rem;
          border-radius: 0.8rem;
        }
      }
    }

    .booking-summary-details {
      list-style: none;

      li {
        margin-bottom: 0.6rem;

        &:first-child {
          margin-bottom: 1.6rem;
        }
      }
    }

    .proceed-to-pay-btn {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 20rem;
    }
  }
}

.input-box-wrapper {
  label {
    margin-bottom: 0.8rem;
  }

  .input-box {
    border-radius: 0.8rem;
    border: 0.1rem solid #79747e;
    color: $black-242;
    background: $white;
    padding: 0.6rem 1.2rem;
    width: 100%;
    max-width: 100%;
    height: 38px;

    &::placeholder {
      opacity: 0.6;
    }
  }
}

.event-conform-payment-page {
  padding: 6rem 0;
}

.conformation-paid-wrapper {
  h2 {
    margin: 4rem 0;
  }

  .event-litel-info {
    display: flex;
    gap: clamp(1rem, 13%, 13rem);

    .left-booked-card {
      border-radius: 1.2rem;
      border: 1px solid $black-12;
      overflow: hidden;
      min-height: 24rem;
      width: 100%;
      max-width: 47.2rem;
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
      }

      .booked {
        position: absolute;
        left: 2rem;
        bottom: 2rem;
        padding: 0.8rem 1.3rem;
        border-radius: 0.8rem;
        background-color: $green-A5F;
      }
    }

    .event-content-box {
      h3 {
        margin: 1.2rem 0 3.2rem;
      }

      button {
        width: 100vw;
        max-width: 27rem;
        margin-bottom: 1.2rem;
      }
    }
  }
}

.result-not-found-section {
  padding: 0 0 2rem;
  .not-found-image{
    width : 100%;
    img{
    max-width: 62rem;
    object-fit: contain;

    }
  }
}

.chq-atc {
  position: relative;
  margin-bottom: 1.2rem;
  .chq-atc--button {
    @include font($white, "Lato-Medium", 1.8rem, 500, 24px);
    padding: 1rem 2.4rem;
    border-radius: 0.4rem;
    border: 1.7px solid $white;
    background: $primary;
    text-align: center;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin-bottom: 0 !important;
    svg {
      display: none;
    }
  }

  .chq-atc--dropdown {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    position: absolute;
    right: 0;
    top: 104%;
    width: 100%;
    // min-width: 13rem;
    border-radius: 5px;
    padding: 0.9rem 0;
    border: none;
    background-color: #fff;
    z-index: 6;
    box-shadow:
      0 2px 6px 2px rgba(0, 0, 0, 0.15),
      0 1px 2px 0 rgba(0, 0, 0, 0.3);

    a {
      @include font($black-242, "Lato-Medium", 1.6rem, 500, 24px);
      padding: 0.6rem 1.2rem;
      width: 100%;
      &:hover {
        background: #ececec;
        color: $black-242;
      }
    }
  }
}

// ?============== EVENT CHECKOUT PAGE CSS END ================


.evet-addtocalender-cutom{
  // overflow: hidden;
  // position: relative;
  .calender-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .chq-atc{
    margin-bottom: 0;
    .chq-atc--button{
      // display: none !important;
      // visibility: hidden;
      border-radius: 9.4px;
      border: 1.175px solid $black-4;
      width: 37.601px;
      height: 37.601px;
      padding: 0 !important;
      background: transparent !important ;
    }
    .chq-atc--dropdown {
      position: absolute;
      bottom: -5px;
      right: 43px;
      width: 200px;
      top: unset;
    }
  }
}
.calender-dropDown-bottom{
  .chq-atc{
     .chq-atc--dropdown {
      position: absolute;
      bottom: unset;
      right: 43px;
      width: 200px;
      top: 0;
    }
  }
}
.banner-shimmer{
  width: 100%;
  height: 30rem;
  border-radius: 1rem;
}
.event-shimmer-card{
  width: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1.9rem;
  height: 35rem;
  .image-circle{
   width:152px ;
   height:152px ;
   border-radius: 50%;
   margin: 1rem auto;
  }
}

@media (max-width: 820px) {
  .events-card-wrapper {
    gap: 2rem;
  }
  .mb-28-12 {
    margin-bottom: 12px;
  }

  .mb-28-12 {
    margin-bottom: 12px;
  }
  .inperson-icon-chip,.offline-icon-chip{
    gap: 1.4rem;
    // top: 2rem;
   position: static;
   margin-bottom: 0.4rem;
  }
  .event-card {
    min-width: 10rem;
    max-width: 100%;
    min-height: 27rem;
    height: fit-content;
    width: 100%;
    border-radius: 8px;
  }

  .event-image-box {
    height: 15rem;

    .bg-event-image {
      object-fit: cover;
    }

    .event-actions-frame {
      height: 45%;
      width: 100%;
      padding: 0.6rem 0.4rem;
      background: linear-gradient(180deg, $black-8 0%, #ececec 100%);
    }

    .event-mode {
      padding: 0.4rem;

      .online-event-tag {
        width: 5.8rem;
      }

      .prenium {
        width: 2.4rem;

      }
    }
  }
  .evet-addtocalender-cutom .chq-atc .chq-atc--dropdown{
    padding: .4rem 0;
    a{
      padding: .4rem .6rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }
  .event-content-frame {
    padding: 0.4rem 1rem;

    h5,
    p {
      margin-bottom: 2px;
    }
  }

  // ?================ event details css start =========================
  .event-details-header {
    .event-register-wrapper {
      position: fixed;
      background-color: $white;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 1rem 5%;
      z-index: 3;
    }

  }
  .event-info-wrapper{
    padding: 3rem 0 4rem 0;
  }
  .speaker-card-frame {
    padding: 2rem 0;
    // gap: 3.2rem;


    .speaker-box {
      width: 150px;
      min-width: 150px;
      padding: 0.8rem;
      border-radius: 6px;

      .speaker-card-profile {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 8px;
        margin-bottom: 0.8rem;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
      }
    }
  }

  .ellipsis-5 {
    -webkit-line-clamp: 9;
  }

  .res-event-card {
    min-width: 15rem;
    width: 100%;
  }

  .event-details-poster {
    margin: 19px 0;
    width: 100%;
    border-radius: 8px;
    position: relative;

    .event-details-image {
      width: 100% !important;
      // height: 100vh;
      max-height: 18rem !important;
      object-fit: cover;
      border-radius: 8px;
    }

    .offline-icon {
      position: relative;
       top: 0;
      left: 0.9rem;
      // width: rem;
      // gap: 1.4rem;
    }

    .attendees-frame {
      position: relative;
      bottom: 0rem;
      left: 0.5rem;
      margin-top: 0.8rem;
    }
  }

  // ?======================= EVEN DETAILS CSS START =============================
  .event-checkout-section {
    background: linear-gradient(to bottom, $white 44.9%, $white 45%);
  }

  .event-checkout-frame {
    flex-wrap: wrap;

    .eve-check-form {
      width: 100%;
      max-width: 100%;
      padding: 2rem 0 0 0;
      h2{
        margin : 2rem 0;
      }

      .refund-policy-frame {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }

    .eve-booking-summary {
      max-width: 100%;
      padding-left: 0;
      background-color: $white;
      padding: 0 0 3rem 0 !important;

    }
    .event-img-box{
      max-height: 24rem !important;
      width: 100% !important;
      img {
        object-fit: cover !important;
        width: 100% !important;
      }

    }
    .event-card-content {
      padding: 1.7rem 0 0 0 !important;
    }
    .proceed-to-pay-btn {
      margin-top: 4rem !important;
    }
  }

  .conformation-paid-wrapper {
    .event-litel-info {
      flex-wrap: wrap;
    }
  }
  .calender-dropDown-bottom{
    .chq-atc{
       .chq-atc--dropdown {
        bottom: 4rem;
        right: -2rem;
        top: unset;
      }
    }
  }

}
@media (min-width: 401px) and (max-width: 500px) {

  .speaker-card-frame {
    padding: 2rem 0;
    gap: 3.7rem;
    overflow: hidden !important;

    .speaker-box {
      width: 164px;
      min-width: 164px;
      padding: 0.8rem;
      border-radius: 6px;

      .speaker-card-profile {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 8px;
        margin-bottom: 0.8rem;

        img {
          width: 40px;
          height: 35px;
          border-radius: 50%;
        }
      }
    }}
}

@media(min-width : 1900px){
  .result-not-found-section {
    padding: 0 0 2rem;
    .not-found-image{
      width : 100%;
      img{
      max-width: 75rem !important;
      object-fit: contain;

      }
    }
  }

}