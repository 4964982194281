//
// ========================= SERCH DROPDOWN COMMON COMPONENT CSS START ==========================

.search-dropdown {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 1.6rem 3.4rem;
    background-color: $white;
    border-radius: 0.8rem;
    width: 100%;
    max-width: 970px;
    // height: 60px;
    position: relative;
    .dropdown-wrapper {
        width: 20%;
        .dropdown {
            .dropdown-title {
                background-color: $white;
                // font-size: 2.2rem;
                // line-height: 28px;
                font-size: 1.8rem;
                line-height: 24px;
                border: none;
                outline: none;
                box-shadow: none;
                color: $black-242;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                overflow: hidden;
                $white-space: nowrap;
                text-overflow: ellipsis;
                padding: 0;
                -webkit-user-select: none;
                &:active,
                &:focus {
                    border: none;
                    outline: none;
                    box-shadow: none;
                    background-color: transparent;
                    color: $black-242;
                }
            }
            .dropdown-menu.show {
                width: 122%;
                inset: 60% auto auto -15% !important;
                // top: 60%;
                // left: -19%;
                border-radius: 8px;
                z-index: 9;
                background: $white;
                padding: 0.8rem 0;
                transform: translate(0px, 29px) !important;
                box-shadow: 0 2px 6px 2px $black-6, 0 1px 2px 0 $black-7;

            }
        }
    }

    .search-wrapper {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: start;
        border-left: 1px solid $black-242;
        padding: 0 0rem 0 1.6rem;
        margin-left: 1.6rem;
        .search-input {
            background-color: $white;
            font-size: 2.2rem;
            line-height: 28px;
            border: none;
            outline: none;
            box-shadow: none;
            color: $black-242;
            width: 100%;
        }
    }
    .left-b-none{
        border-left: none;
        margin-left: 0;
        width: 100%;
        padding: 0;
     }
}
.dropdown-item {
    padding: 0.9rem 3.2rem;
    border: none;
    outline: none;
    box-sizing: none;
    overflow: hidden;
    text-overflow: ellipsis;
    $white-space: nowrap;
    cursor: pointer;
    &:active {
        background:$green-A5F;
        color: $black-242;
    }
    &:hover {
        background: $grey-ECE;
        color: $black-242;
    }
}
.trending-one {
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 0.8rem;
    height: 3.2rem;
    padding: 0.6rem 0.8rem;
    white-space: nowrap;
    font-family: 'Lato-Regular';
            font-weight: 400;
}
// ========================= SERCH DROPDOWN COMMON COMPONENT CSS END ==========================
//


@media (max-width:1100px) {

}

@media (max-width:820px) {

    // ========================= SERCH DROPDOWN COMMON COMPONENT CSS START ==========================
    .search-dropdown {
        // z-index: 1000 !important;
        border-radius: .4rem;
        padding: 1.1rem 0.9rem 1.1rem 1.3rem;
        .dropdown-wrapper{
            width: 14%;
            .dropdown{
                .dropdown-menu.show {
                    width: auto;
                    border-radius: .4rem;
                    min-width: 20rem;
                    inset: 34% auto auto -28% !important;
                }
            }
        }
        .search-wrapper {
            padding-right: 0.4rem;
            margin-left: 1rem;
            .input-box-keywords{
                padding: 0 7rem 0 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .search-input {
                font-size: 1.8rem;
                line-height: 2.4rem;
            }
        }
    }
    .dropdown-item {
        padding: 0.4rem .8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: .8rem;
    }

    // ========================= SERCH DROPDOWN COMMON COMPONENT CSS END ==========================

}