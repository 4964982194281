.alert-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .modal-content {
      padding: 1.6rem 2rem;
      border-radius: 1.5rem;
      max-width: 81.8rem;
      .modal-header {
        padding: 0 0;
       margin-bottom: 1.6rem;
        align-items: center;
        .close-icon {
          cursor: pointer;
          position: absolute;
          // top: 3rem;
          right: 3rem;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
     .modal-body {
       padding: 0 0;
       max-width: 71.6rem;


      }
      .alert-footer{
       margin-top: 4rem;
        .secondary-btn{
          border-radius: 0.8rem;
          padding: 1rem 2.4rem;
          margin-right: 0.8rem;
        }
        .primary-btn{
          border-radius: 0.8rem;
          padding: 1rem 2.4rem;
        }
      }
    }
}

.alerts-modal{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .modal-content{
    padding: 0;
    max-width: 55.6rem;
    border-radius: 0.6rem !important;
    padding: 2rem 2.3rem 2.3rem 2.3rem;

    .modal-header{
      background-color: $white !important;
      .close-icon{
        background-color: $white !important;
      }
    }
    .modal-body{
      padding: 1.4rem 0;
      .custom-alert a {
        color: $primary;
        text-decoration: underline;
        font-weight: 600;
      }



      }
      .alert-footer{
        .primary-btn{
          font-size: 1.5rem !important;
          border-radius: 0.8rem;
          padding: 1rem 2.4rem !important;

        }
        .secondary-btn{

          margin-right: 0.8rem !important;
          border-radius: 0.8rem !important;
          padding: 1rem 2.4rem !important;

        }

      }
  }


}
@media (max-width:820px) {
  .alert-modal{
    padding-left: 0!important ;
    left : -5px;
    .modal-content{
      width: 95%;
      .modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;

        .close-icon {
          cursor: pointer;
          position: absolute;
          // top: 3rem;
          right: 1.5rem;
          width: 1.3rem;
          height: 1.2rem;
        margin-left: 0.5rem;

        }
      }
    .modal-body{
      padding: 0 0 !important;

    }
    .alert-footer{
      margin-top: 1.5rem;


      .secondary-btn{
         font-size: 1.2rem !important;
         line-height: 1rem;
         border-radius: 0.8rem;
         padding: 0.8rem 1rem;
         margin-right: 0.5rem;
        //  margin-bottom: 1rem;
       }
       .primary-btn{
        font-size: 1.2rem !important;
        line-height: 1rem;

         border-radius: 0.8rem;
         padding: 0.8rem 1rem;
       }
     }
  }
  }
}
@media (max-width:820px) {

.alerts-modal{
  padding-right: 0 !important;
  padding-left: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
   width: 100% !important;
   margin-left: 0 !important;

  .modal-content{
    width : 100%;
    max-width: 34rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem !important;
    .modal-body{
    padding: 1.4rem 0 !important;

  }
  .alert-footer{


    .secondary-btn{
       font-size: 1.2rem !important;
       line-height: 0.8rem;
       border-radius: 0.8rem;
       padding: 0.8rem 1rem !important;
       margin-right: 0.3rem;
      //  margin-bottom: 1rem;
     }
     .primary-btn{
      font-size: 1.2rem !important;
      line-height: 0.8rem;

       border-radius: 0.8rem;
       padding: 0.8rem 1rem !important;
     }
   }
}



}
}